import * as React from "react";
import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Confirm } from "components";
import { Button } from "@/components/ui/button";
import useToggle from "hooks/use-toggle";
import { useMemo } from "react";
import { Restriction, RestrictionType } from "types";
import { TimeSpanItem } from "components/time-span/time-span-item";
import { RemoveRestrictionParam } from "services/web/api-restriction.type";
import { distanceUnit } from "constants/mapbox.const";
import { numberFormat } from "helper/format-data";

type DisabledPartCurbZoneDetailProps = {
  id: string;
  disabledPart: Restriction;
  deleteDisabledPart: (input: RemoveRestrictionParam) => void;
  openParkingModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DisabledPartCurbZoneDetail = ({
  id,
  deleteDisabledPart,
  disabledPart,
  openParkingModal,
}: DisabledPartCurbZoneDetailProps) => {
  const [openConfirm, setOpenConfirm] = useToggle();

  const decreeFileNames = useMemo(() => {
    if (disabledPart?.decrees?.length) {
      return disabledPart?.decrees?.map((item) => item.name);
    }
    return [];
  }, [disabledPart]);

  const removeParking = () => {
    deleteDisabledPart({
      id,
      curbZoneId: disabledPart?.curbZoneId,
    });
  };

  const confirmText = disabledPart.curbZoneId ? "disabled of curb zone" : "disabled of street";

  return (
    <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80 max-w-128">
      <DataTable className="p-0 shadow-none rounded-none">
        <TableBody>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">TimeSpan</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              <div className="flex flex-col">
                {disabledPart.timeSpans?.map((timeSpan, timeSpanIndex) => (
                  <div className="flex items-center" key={`time-span-${timeSpanIndex}`}>
                    <TimeSpanItem
                      label=""
                      timeSpan={timeSpan}
                      name={`timeSpans[${timeSpanIndex}]`}
                      options={{
                        showSelectDate: true,
                      }}
                      readonly
                    />
                  </div>
                ))}
              </div>
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Description</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              {disabledPart?.description}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Decree</TableCell>
            <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
              <div className="flex flex-col gap-2">
                {decreeFileNames.map((item, idx) => (
                  <span key={`detail-decree-${idx}`}>{item}</span>
                ))}
              </div>
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Type</TableCell>
            {disabledPart.type && (
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {RestrictionType[disabledPart.type]}
              </TableCell>
            )}
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary pb-1">Longueur</TableCell>
            {disabledPart.length && (
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{`${numberFormat(disabledPart.length)} ${distanceUnit}`}</TableCell>
            )}
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Places calculées</TableCell>
            {disabledPart.calculatedSpaces && (
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {disabledPart.calculatedSpaces}
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </DataTable>
      <div className="flex justify-between mt-4">
        <Button onClick={setOpenConfirm} variant="error">
          Delete
        </Button>
        <Button onClick={() => openParkingModal(true)} variant="secondary">
          Edit
        </Button>
      </div>
      {openConfirm && (
        <Confirm
          title={`Delete ${confirmText}`}
          content={`Are you sure that you want to delete this ${confirmText}?`}
          onCancel={setOpenConfirm}
          onOk={removeParking}
          butOkName="Delete"
          type="error"
        />
      )}
    </div>
  );
};
