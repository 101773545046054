import React from "react";

import MacaronLogo from "assets/logo.svg";
import { NavLink } from "react-router-dom";
import { SidebarMenuItem } from "./sidebar-menu-item";
import { menuItems } from "./menu-items";
import { BackIcon } from "components/icons";
import { useUser } from "hooks/use-user";

export type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const { userRoles } = useUser();

  return (
    <div className="lg:w-20">
      <div
        className={`fixed inset-0 bg-gray-900/30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      />
      <div
        id="sidebar"
        className={`w-20 bg-menu flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen lg:overflow-y-auto no-scrollbar shrink-0 transition-transform duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-20"
        }`}
      >
        <div className="relative grow flex items-center justify-center">
          <div className="flex justify-center absolute top-7">
            <button
              className="lg:hidden text-gray-500 hover:text-gray-400"
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
            >
              <span className="sr-only">Close sidebar</span>
              <BackIcon className="w-6 h-6 fill-current" />
            </button>
            <NavLink to="/" className="block">
              <img className="h-6" src={MacaronLogo} alt="macaron logo" />
            </NavLink>
          </div>
          <ul className="left-menu grow flex flex-col gap-75px">
            {menuItems.map((menuItem, index) => (
              <SidebarMenuItem item={menuItem} roles={userRoles} key={`sidemenuitem-${index}`} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
