import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronDownIcon } from "assets";
import { FieldContainer } from "components/form-control";
import { OptionType, DropdownAutoCompleteSelect } from "components/form-control/dropdown-autocomplete";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OsmSearchJsonV2 } from "services/common/osm.type";
import { CityInput } from "types";
import { Input } from "components/ui/input";
import { Button } from "@/components/ui/button";
interface GeneralProps {
  handleSearch: (value: string) => void;
  isLoading: boolean;
  result: OptionType[];
  cityName: string;
  handleSelectCity: (value: string, label: string) => void;
  selectedCity: OsmSearchJsonV2 | undefined;
}

export const GeneralCityDetails = ({ isLoading, result, cityName, handleSelectCity, handleSearch }: GeneralProps) => {
  const [isExpand, setIsExpand] = useState(true);
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CityInput>();

  const generalErrors = errors.name || errors.zipCode;

  useEffect(() => {
    setValue("name", cityName);
  }, [cityName, setValue]);

  return (
    <div className="w-full flex flex-col">
      <Collapsible open={isExpand} onOpenChange={setIsExpand}>
        <CollapsibleTrigger asChild>
          <Button className="flex w-full justify-between items-center rounded-md shadow-lg bg-white  px-4 py-2 text-left text-sm font-medium">
            <h1 className={`font-bold text-md ${generalErrors ? "text-red-700" : ""}`}>General</h1>
            <div className="flex flex-row gap-x items-center">
              {generalErrors ? <span className="text-red-700 text-lg">&#9888;</span> : null}
              <ChevronDownIcon className={`${isExpand ? "rotate-180 transform" : ""} h-5 w-5`} />
            </div>
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent className="px-4 pt-4 pb-2 text-sm">
          <div className="flex flex-row w-full gap-x-4 justify-around">
            <FieldContainer
              className="basis-1/2 items-center justify-start"
              helpText={errors.name ? "This is required" : undefined}
              status={errors.name ? "error" : "default"}
            >
              <DropdownAutoCompleteSelect
                {...register("name", { required: true })}
                onChangeValue={handleSelectCity}
                value={cityName}
                handleSearch={handleSearch}
                displayValue={cityName}
                searchString={cityName}
                options={result}
                isLoading={isLoading}
                name="name"
                panelClassName="w-[300px]"
                status={errors.name ? "error" : "primary"}
                placeholder="Search city..."
              />
            </FieldContainer>
            <FieldContainer
              className="basis-1/2 items-center justify-start"
              helpText={errors.zipCode ? "This is required" : undefined}
              status={errors.zipCode ? "error" : "default"}
            >
              <Input
                label="Zip code"
                {...register("zipCode", { required: true })}
                isRequired
                status={errors.zipCode ? "error" : "primary"}
                defaultValue={getValues("zipCode")}
              />
            </FieldContainer>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};
