import clsx from "clsx";
import { hasClassName } from "helper/check-class";
import { Calendar } from "react-date-range";
import { fr as languageFr } from "date-fns/locale";
import { formatDate, parseISOString, timeToString } from "helper/date-time";
import { Label } from "../label";
import { CalendarIcon } from "components/icons";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { useMemo } from "react";
import { TimeInput } from "./time-input";
import { formatISO, format } from "date-fns";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

export interface DateRangeInputProps extends VariantProps<typeof buttonVariants> {
  label?: string;
  isRequired?: boolean;
  selectedDate?: Date | string | null;
  onChangeValue: (newDate: Date) => void;
  placeholder?: string;
  className?: string;
  minDate?: Date | string | undefined;
  maxDate?: Date | string | undefined;
  showTime?: boolean;
  name?: string;
  disabled?: boolean;
}

export const CalendarSelector = ({
  selectedDate,
  minDate,
  maxDate,
  label,
  onChangeValue,
  placeholder = "Select date",
  className,
  showTime = false,
  isRequired,
  name,
  variant,
  size,
  disabled = false,
}: DateRangeInputProps) => {
  const handleChangeDate = (input: Date) => {
    const dateInput = formatISO(input, { representation: "date" });
    const timeInput = selectedDate ? format(new Date(selectedDate), "HH:mm") : "00:00";
    const newDateTime = new Date(`${dateInput}T${timeInput}`);
    onChangeValue(newDateTime);
  };

  const convertDate = (input: Date | string | undefined) => {
    if (!input) {
      return undefined;
    }
    if (typeof input === "string") {
      return new Date(input);
    }
    return input;
  };

  const newDate = useMemo((): Date => {
    if (selectedDate && typeof selectedDate === "string") {
      return parseISOString(selectedDate);
    }
    if (typeof selectedDate === "object") {
      return selectedDate as Date;
    }

    return new Date();
  }, [selectedDate]);

  const times = useMemo(() => {
    if (!newDate) {
      return "";
    }
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    return timeToString(hours, minutes);
  }, [newDate]);

  const changeTime = (value: string) => {
    if (!selectedDate) {
      return;
    }
    const newDate = new Date(selectedDate);
    if (value === "null") {
      newDate.setHours(0, 0, 0, 0);
      onChangeValue(newDate);
    } else {
      const dateInput = formatISO(new Date(selectedDate), { representation: "date" });
      if (!isNaN(newDate.getTime())) {
        onChangeValue(new Date(`${dateInput}T${value}:00`));
      }
    }
  };

  return (
    <div className="flex items-end relative border-primary border-b border-solid">
      <Popover>
        <PopoverTrigger asChild>
          <button className={cn(buttonVariants({ variant, size, className }))}>
            <Label htmlFor={name ?? ""} className="form-label-focus">
              {label}
              {isRequired && <span className="text-red-500">*</span>}
            </Label>
            <div
              className={clsx(
                "flex items-center min-w-0",
                !hasClassName(className, "w-") && "w-32",
                disabled ? "bg-gray-200 cursor-default" : !hasClassName("active:") && "active:shadow-outline",
                className,
              )}
            >
              <CalendarIcon className="w-4 h-4 mr-3" />
              {selectedDate ? (
                <span className="flex-1 truncate text-left text-medium capitalize">{formatDate(selectedDate)}</span>
              ) : (
                <span className="text-lightgrey">{placeholder}</span>
              )}
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-96">
          <div>
            <Calendar
              onChange={(item: Date) => {
                handleChangeDate(item);
              }}
              locale={languageFr}
              date={newDate}
              minDate={convertDate(minDate)}
              maxDate={convertDate(maxDate)}
            />
          </div>
        </PopoverContent>
      </Popover>
      {showTime && <TimeInput timeInputClassName="p-2" times={times} onChangeValue={changeTime} disabled={!newDate} />}
    </div>
  );
};

const buttonVariants = cva(
  "border-none inline-flex items-center disabled:bg-gray-200 disabled:border-transparent disabled:text-white gap-2 whitespace-nowrap text-sm disabled:opacity-50 transition ease-in-out duration-150",
  {
    variants: {
      variant: {
        primary: "bg-primary text-white",
        outline:
          "text-black bg-default/0 hover:bg-default/25 active:bg-default/50 disabled:border disabled:border-black/10 disabled:bg-default disabled:text-disabled disabled:cursor-not-allowed",
        secondary: "bg-default text-primary",
        error: "bg-red-500 text-white hover:bg-red-800",
        default: "text-darkgrey hover:bg-gray-100",
      },
      size: {
        normal: "h-9 py-2 text-xs leading-8",
        small: "h-8 gap-1.5 px-3 text-xs leading-3",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "normal",
    },
  },
);
