import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import * as React from "react";
import { Confirm } from "components";
import { IOffStreetParking } from "types";
import { Button } from "@/components/ui/button";
import useToggle from "hooks/use-toggle";
import { moneyFormat, numberFormat } from "helper/format-data";
import { OffStreetParkingModalState } from "../off-street-parking.const";

export type OffStreetParkingDetailProps = {
  parking: IOffStreetParking;
  setParkingModalState: React.Dispatch<React.SetStateAction<keyof typeof OffStreetParkingModalState>>;
  removeParking: () => void;
  onClose: () => void;
};
export const OffStreetParkingDetail = ({
  parking,
  setParkingModalState,
  removeParking,
  onClose,
}: OffStreetParkingDetailProps) => {
  const [openConfirm, setOpenConfirm] = useToggle(false);

  const handleOpenConfirm = () => {
    setOpenConfirm();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="transition-opacity absolute h-auto top-20 bg-primary p-3 right-80 mt-4 mb-3 rounded-md shadow-lg z-5 min-w-80">
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Name</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{parking.name}</TableCell>
            </TableRow>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Address</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">{parking.address}</TableCell>
            </TableRow>
            {!!parking.totalPlaces && (
              <TableRow className="hover:none">
                <TableCell className="text-table-data text-secondary">Parking capacity</TableCell>
                <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                  {numberFormat(parking.totalPlaces)}
                </TableCell>
              </TableRow>
            )}
            {!!parking.pricePerHour && (
              <TableRow className="hover:none">
                <TableCell className="text-table-data text-secondary">Price per hour</TableCell>
                <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                  {moneyFormat(parking.pricePerHour)}
                </TableCell>
              </TableRow>
            )}
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">External Id</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {parking.externalId}
              </TableCell>
            </TableRow>
          </TableBody>
        </DataTable>
        <div className="flex justify-between mt-4">
          <Button onClick={handleOpenConfirm} variant="error">
            Delete
          </Button>
          <Button onClick={() => setParkingModalState(OffStreetParkingModalState.SHOW)} variant="secondary">
            Edit
          </Button>
          <Button onClick={handleClose} variant="secondary">
            Close
          </Button>
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete private parking"
          content="Are you sure that you want to delete this parking?"
          onCancel={handleOpenConfirm}
          onOk={removeParking}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
