import { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { IOffStreetParking } from "types";
import { IconButton, Label } from "components";
import { Button } from "@/components/ui/button";
import { ModalContent, ModalFooter } from "components/modal";
import { Checkbox, FieldContainer, DropdownAutoCompleteSelect } from "components/form-control";
import { InputNumber } from "components/form-control/input-number";
import { useDebounce } from "hooks/lib-ui";
import { usePlacesQuery } from "services/web/map.queries";
import { addressPosition } from "services/web/mapbox.service";
import useToggle from "hooks/use-toggle";
import { AddIcon, ArrowIcon, DeleteIcon, EditIcon } from "components/icons";
import clsx from "clsx";
import { noop } from "helper/utils";
import { OffStreetParkingModalState } from "@/modules/offstreet-parking/off-street-parking.const";
import { TimeSpanListing } from "components/time-span";
import { Input } from "@/components/ui/input";
import { Dialog } from "components/ui/dialog";

interface ICoordinates {
  latitude: number;
  longitude: number;
}

interface OffstreetParkingPromptProps {
  coordinates?: ICoordinates;
  offStreetParking: IOffStreetParking;
  onSave: (input: IOffStreetParking) => Promise<void>;
  parkingModalState: keyof typeof OffStreetParkingModalState;
  setParkingModalState: React.Dispatch<React.SetStateAction<keyof typeof OffStreetParkingModalState>>;
  onClose: () => void;
  setOffStreetParkingDetail: (value: IOffStreetParking) => void;
}

export const OffStreetParkingModal = ({
  coordinates,
  offStreetParking,
  parkingModalState,
  setParkingModalState,
  onClose,
  onSave,
  setOffStreetParkingDetail,
}: OffstreetParkingPromptProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IOffStreetParking>({
    defaultValues: offStreetParking,
    mode: "all",
  });
  const [toggleMoreOptions, setToggleMoreOptions] = useToggle(false);
  const [value, setValue] = useState<string>(offStreetParking?.address);
  const [otherAddresses, setOtherAddresses] = useState<string[]>(offStreetParking?.otherAdresses || []);
  const [openingHours, setOpeningHours] = useState(offStreetParking.openingHours || []);
  const [selectedIndexOtherAddress, setSelectedIndexOtherAddress] = useState<number | undefined>();

  const currentAddress = useMemo(() => {
    if (typeof selectedIndexOtherAddress === "number") {
      return otherAddresses[selectedIndexOtherAddress];
    }
    return value;
  }, [value, selectedIndexOtherAddress, otherAddresses]);

  const searchString = useDebounce(currentAddress);

  const handleSearch = (value: string) => {
    setValue(value);
  };
  const { data, isLoading } = usePlacesQuery({
    keyWord: searchString,
    coordinates: coordinates,
  });

  const listPlace = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((item: addressPosition) => {
        return {
          value: `${item.latitude}:${item.longitude}`,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  const handleSelectPlace = (value: string, label: string) => {
    setValue(label);
    const addressPoint = value.split(":");
    setOffStreetParkingDetail({
      ...offStreetParking,
      location: { type: "Point", coordinates: [Number(addressPoint[0]), Number(addressPoint[1])] },
    });
  };

  const handleAddNewAddress = () => {
    setOtherAddresses((prev) => [...prev, ""]);
  };

  const handleRemoveOtherAddress = (idx: number) => {
    const addresses = otherAddresses.filter((_, index) => index !== idx);
    setOtherAddresses(addresses);
  };

  const handleClose = () => {
    setOtherAddresses([]);
    onClose();
  };

  const handleMinimizeModal = () => {
    setParkingModalState(OffStreetParkingModalState.MINIMIZE);
  };

  const handleShowModal = () => {
    setParkingModalState(OffStreetParkingModalState.SHOW);
  };

  const saveParking = async (input: IOffStreetParking) => {
    const formattedInput = {
      ...input,
      maxHeight: Number(input.maxHeight),
      levels: Number(input.levels),
      otherAdresses: otherAddresses.filter((item) => !!item).map((item) => item),
      location: offStreetParking.location,
      openingHours: openingHours,
      options: {
        ...input.options,
        evChargers: Number(input.options?.evChargers),
        disablesPlaces: Number(input?.options?.disablesPlaces),
        motorcyclePlaces: Number(input?.options?.motorcyclePlaces),
        bicyclePlaces: Number(input.options?.bicyclePlaces),
      },
    };
    await onSave({ ...formattedInput });
    setOtherAddresses([]);
  };
  const modalMinimize = parkingModalState === OffStreetParkingModalState.MINIMIZE;

  return (
    <>
      <Dialog open={!modalMinimize}>
        <ModalContent
          className="w-128 h-[calc(100vh-5em)] rounded-2xl overflow-auto px-3 pb-0 lg:px-5"
          title={`${offStreetParking.id ? "Edit " : "Add "}a off street parking`}
          description="Off street Parking information"
        >
          <form onSubmit={handleSubmit(saveParking)}>
            <FieldContainer
              helpText={errors.name ? "This is required" : undefined}
              status={errors.name ? "error" : "primary"}
            >
              <Input
                {...register("name", { required: true })}
                isRequired
                status={errors.name ? "error" : "primary"}
                label="Parking Name"
                defaultValue={offStreetParking.name}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.address ? "This is required" : undefined}
              status={errors.address ? "error" : "primary"}
              className="mb-2"
            >
              <DropdownAutoCompleteSelect
                panelClassName="shadow-md absolute z-50"
                label="Parking Address"
                {...register("address", { required: true })}
                isRequired
                onChangeValue={handleSelectPlace}
                value={value}
                handleSearch={handleSearch}
                options={listPlace}
                isLoading={isLoading}
                status={errors.address ? "error" : "primary"}
                onClick={() => setSelectedIndexOtherAddress(undefined)}
              />
            </FieldContainer>
            <FieldContainer className="flex gap-2">
              <Label className="text-base font-semibold">
                Location:{" "}
                {offStreetParking?.location && (
                  <span
                    role="button"
                    tabIndex={0}
                    className="text-blue-500 font-medium cursor-pointer"
                    onKeyDown={noop}
                    onClick={handleMinimizeModal}
                  >
                    {offStreetParking?.location.coordinates.toString()}
                  </span>
                )}
              </Label>
              <EditIcon className="w-5 h-5 cursor-pointer fill-black" onClick={handleMinimizeModal} />
            </FieldContainer>
            <FieldContainer className="flex flex-col mb-8">
              <Label className="text-base">Other Addresses</Label>
              {otherAddresses.map((item, index) => (
                <div className="flex" key={`otherAddresses_${index}`}>
                  <div className="flex-1">
                    <DropdownAutoCompleteSelect
                      panelClassName="shadow-md absolute z-50"
                      className="mb-3"
                      placeholder={`Address ${index + 1}`}
                      onChangeValue={(value, label) => {
                        const addresses = [...otherAddresses];
                        addresses[index] = label;
                        setOtherAddresses(addresses);
                      }}
                      handleSearch={(value) => {
                        const addresses = [...otherAddresses];
                        addresses[index] = value;
                        setOtherAddresses(addresses);
                      }}
                      options={listPlace}
                      isLoading={isLoading}
                      value={item}
                      onClick={() => setSelectedIndexOtherAddress(index)}
                    />
                  </div>
                  <IconButton className="w-6 h-6">
                    <DeleteIcon className="w-4 h-4" onClick={() => handleRemoveOtherAddress(index)} />
                  </IconButton>
                </div>
              ))}
              <IconButton className="w-8 h-8">
                <AddIcon className="h-6 w-6" fill="black" onClick={handleAddNewAddress} />
              </IconButton>
            </FieldContainer>
            <TimeSpanListing
              label="Opening hours"
              labelClassName="block leading-5 mb-1 text-sm text-base"
              timeSpans={openingHours}
              setTimeSpans={setOpeningHours}
              suffixClassName="w-8 h-8"
              suffixIcon={<AddIcon fill="black" className="h-6 w-6 cursor-pointer" />}
              options={{
                showDesignatedPeriod: true,
                showDesignatedPeriodExcept: true,
                showSelectDate: true,
                showTimeOfDay: true,
              }}
            />
            <FieldContainer
              helpText={errors?.totalPlaces ? "This must be a number" : undefined}
              status={errors.totalPlaces ? "error" : "primary"}
            >
              <InputNumber
                {...register("totalPlaces")}
                status={errors.totalPlaces ? "error" : "primary"}
                label="Parking capacity"
                min={0}
                defaultValue={offStreetParking.totalPlaces}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors?.pricePerHour ? "This must be a number" : undefined}
              status={errors?.pricePerHour ? "error" : "primary"}
            >
              <InputNumber
                currency="€"
                min={0}
                label="Price per hour"
                {...register("pricePerHour")}
                status={errors?.pricePerHour ? "error" : "primary"}
                defaultValue={offStreetParking.pricePerHour}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.externalId ? "This is required" : undefined}
              status={errors.externalId ? "error" : "primary"}
            >
              <Input
                {...register("externalId")}
                status={errors.externalId ? "error" : "primary"}
                label="External ID"
                defaultValue={offStreetParking.externalId}
              />
            </FieldContainer>
            <FieldContainer>
              <InputNumber
                {...register("maxHeight")}
                label="Max Height"
                min={0}
                defaultValue={offStreetParking.maxHeight}
              />
            </FieldContainer>
            <FieldContainer>
              <InputNumber {...register("levels")} label="Levels" min={0} defaultValue={offStreetParking.levels} />
            </FieldContainer>
            <FieldContainer>
              <Input {...register("structure")} label="Structure" defaultValue={offStreetParking.structure} />
            </FieldContainer>
            {toggleMoreOptions && (
              <div className="transition-all duration-500 overflow-hidden">
                <FieldContainer className="mt-8">
                  <InputNumber
                    {...register("options.evChargers")}
                    label="Number of EV Chargers"
                    min={0}
                    defaultValue={offStreetParking.options?.evChargers}
                  />
                </FieldContainer>
                <FieldContainer>
                  <InputNumber
                    {...register("options.disablesPlaces")}
                    label="Number of disabled spots"
                    min={0}
                    defaultValue={offStreetParking.options?.disablesPlaces}
                  />
                </FieldContainer>
                <FieldContainer>
                  <InputNumber
                    {...register("options.motorcyclePlaces")}
                    label="Number of places for motorcycles"
                    min={0}
                    defaultValue={offStreetParking.options?.motorcyclePlaces}
                  />
                </FieldContainer>
                <FieldContainer>
                  <InputNumber
                    {...register("options.bicyclePlaces")}
                    label="Number of places for Bicycle"
                    min={0}
                    defaultValue={offStreetParking.options?.bicyclePlaces}
                  />
                </FieldContainer>
                <FieldContainer className="mb-2">
                  <Checkbox
                    {...register("options.toilets")}
                    defaultChecked={offStreetParking.options?.toilets}
                    label="Has toilets"
                  />
                  <Checkbox
                    {...register("options.disabledAccess")}
                    label="Disabled persons access"
                    defaultChecked={offStreetParking.options?.disabledAccess}
                  />
                  <Checkbox
                    {...register("options.motorcycleAccess")}
                    label="Motorcycle Access"
                    defaultChecked={offStreetParking.options?.motorcycleAccess}
                  />
                  <Checkbox
                    {...register("options.bicycleAccess")}
                    label="Bicycle Access"
                    defaultChecked={offStreetParking.options?.bicycleAccess}
                  />
                </FieldContainer>
              </div>
            )}

            <div
              className="flex gap-1 items-center"
              role="button"
              tabIndex={0}
              onKeyDown={noop}
              onClick={setToggleMoreOptions}
            >
              <span className="text-sm text-gray-900 cursor-pointer">
                {toggleMoreOptions ? "Hide" : "More options"}
              </span>
              <ArrowIcon
                className={clsx("transform", toggleMoreOptions ? "rotate-90" : "-rotate-90")}
                width={10}
                height={10}
              />
            </div>
            <ModalFooter>
              <Button disabled={isSubmitting} type="submit" variant="primary">
                Save
              </Button>
              <Button onClick={handleClose} variant="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Dialog>
      <div
        role="button"
        tabIndex={0}
        className={clsx(
          "my-10 inset-auto absolute bottom-12 right-4 w-fit px-2 py-1 rounded-md shadow-md bg-white animate-bounce",
          "flex gap-2 items-center text-sm font-medium cursor-pointer",
          modalMinimize ? "block" : "hidden",
        )}
        onKeyDown={noop}
        onClick={handleShowModal}
      >
        <span>Back to edit off street parking</span>
        <ArrowIcon className="w-3 h-3 rotate-90" />
      </div>
    </>
  );
};
