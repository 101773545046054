import { BitmapLayer, GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import DeckGL from "@deck.gl/react";
import { Map as StaticMap, ViewState } from "react-map-gl";
import { TileLayer } from "deck.gl";
import { MAPBOX_ACCESS_TOKEN } from "constants/mapbox.const";
import { MapType, ViewportPlaceType, mapUrlByType } from "types/map.type";
import { CityContext } from "contexts/city-context-provider";
import { useQueryParamByViewPort } from "hooks/use-set-query-params";
import { createMarkerLayer } from "helper/map-utils";
import { isNil } from "helper/utils";
import { SearchPlace, SelectMapType, SwitchModeView } from "modules/curb-zone/components";
import { MapToolBox } from "./components/map-tool-box";
import { ParkingMeterModal } from "./components/parking-meter-modal";
import { ParkingMeterDetail } from "./components/parking-meter-detail";
import { ParkingMeter } from "services/web/api-parking-meter.type";
import { Position } from "geojson";
import { mapboxStyle, ModeViewType } from "constants/map.const";
import { useQueryParkingMeter } from "./hooks/use-query-parking-meter";
import { initParkingMeter, ParkingMeterModalState } from "./parking-payment.const";

export const ParkingPaymentMap = () => {
  const { currentCity, viewport, setViewport } = useContext(CityContext);
  const [mapType, setMapType] = useState<MapType>(MapType.MAPBOX);
  const [modeView, setModeView] = useState<ModeViewType>("normal");
  const [parkingMeterDetail, setParkingMeterDetail] = useState<ParkingMeter | null>();
  const [parkingModalState, setParkingModalState] = useState<keyof typeof ParkingMeterModalState>(
    ParkingMeterModalState.DEACTIVATE,
  );

  const currentPosition = useRef<Position>([]);
  const setQueryParamByViewport = useQueryParamByViewPort();

  const { parkingMeters, createParkingMeter, updateParkingMeter, removeParkingMeter } = useQueryParkingMeter();

  const [hoverInfo, setHoverInfo] = useState<any>({});

  const mapStyle = useMemo(() => mapboxStyle[modeView], [modeView]);

  useEffect(() => {
    if (isNil(currentCity)) return;
    if (currentCity?.position) {
      setParkingMeterDetail(initParkingMeter);
      currentPosition.current = currentCity?.position;
    }
  }, [currentCity]);

  const updateParkingMetersViewport = (viewState: ViewState) => {
    if (!currentCity || !viewState) return;
    setQueryParamByViewport({ viewState, viewport, cityID: currentCity.id });
  };

  const parkingMeterPoint = useMemo(() => {
    return parkingMeters.map(({ location, ...rest }: ParkingMeter) => ({
      ...rest,
      coordinates: location.coordinates,
    }));
  }, [parkingMeters]);

  const parkingMeterLayer = new IconLayer({
    id: "parking-meter-icon-layer",
    data: parkingMeterPoint,
    pickable: true,
    iconAtlas: "images/parking_meter_icon.png",
    iconMapping: {
      marker: { x: 0, y: 0, width: 40, height: 63, mask: true },
    },
    getIcon: () => "marker",
    sizeScale: 15,
    getPosition: (d: any) => d.coordinates,
    getSize: () => 3,
    onHover: (info: any) => {
      if (info.object) {
        if (!hoverInfo.parkingMeter || (hoverInfo?.parkingMeter && info.object.id !== hoverInfo?.parkingMeter.id)) {
          setHoverInfo({
            left: info.x,
            top: info.y,
            parkingMeter: info.object,
          });
        }
      } else if (!info.object) {
        setHoverInfo({});
      }
    },
  });

  const markerLayer = useMemo(() => {
    if (!parkingMeterDetail?.location?.coordinates?.length) {
      return null;
    }
    return createMarkerLayer({
      coordinates: [parkingMeterDetail.location.coordinates[0], parkingMeterDetail.location.coordinates[1]],
    });
  }, [parkingMeterDetail]);

  const currentCityLayer = new GeoJsonLayer({
    id: "current-city",
    data: currentCity?.boundaries,
    filled: false,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 4,
  });

  const tileLayer = new TileLayer({
    id: "sat-other-map",
    data: mapUrlByType[mapType],

    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,

    renderSubLayers: ({ tile, data, ...props }: any) => {
      const {
        bbox: { west, south, east, north },
      } = tile;

      return new BitmapLayer(props, {
        image: data,
        bounds: [west, south, east, north],
      });
    },
    visible: mapType !== MapType.MAPBOX,
  });

  const parkingPaymentMapLayers = [tileLayer, parkingMeterLayer, currentCityLayer, markerLayer];

  const getCursor = () => {
    return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
  };

  const handleClickAdd = () => {
    setParkingModalState(ParkingMeterModalState.SHOW);
  };

  const handleDragEnd = (event: any) => {
    const { viewport } = event;
    const newPosition = [viewport?.longitude, viewport?.latitude];
    currentPosition.current = [...newPosition];
    updateParkingMetersViewport(viewport);
  };

  const selectAddress = (viewport: ViewportPlaceType | null) => {
    if (viewport) {
      setParkingMeterDetail(
        (prevState) =>
          ({
            ...prevState,
            address: viewport.name,
            location: { type: "Point", coordinates: [Number(viewport.longitude), Number(viewport.latitude)] },
          }) as ParkingMeter,
      );

      setViewport((prevState) => ({ ...prevState, ...viewport, zoom: 16 }));
    }
  };

  const handleSelectParkingMeter = (info: any) => {
    if (parkingModalState === ParkingMeterModalState.MINIMIZE) {
      setParkingMeterDetail({
        ...parkingMeterDetail,
        location: { type: "Point", coordinates: info.coordinate },
      } as ParkingMeter);
      return;
    }
    if (info.object && info.object.id) {
      const { coordinates, ...rest } = info.object;
      rest["location"] = { type: "Point", coordinates };
      setParkingMeterDetail(rest);
      return;
    }
  };

  const handleClose = () => {
    setParkingModalState(ParkingMeterModalState.DEACTIVATE);
    setParkingMeterDetail(initParkingMeter);
  };

  const saveParkingMeter = async (input: ParkingMeter) => {
    const newParkingMeter = { ...input, cityId: currentCity?.id };
    if (input.id) {
      await updateParkingMeter({ variables: { updateParkingMeterInput: newParkingMeter } });
    } else {
      await createParkingMeter({ variables: { createParkingMeterInput: newParkingMeter } });
    }
    handleClose();
  };

  const handleRemoveParkingMeter = async () => {
    await removeParkingMeter({ variables: { meterId: parkingMeterDetail?.id } });
    setParkingMeterDetail(initParkingMeter);
  };

  return (
    <div className="relative h-full">
      <DeckGL
        initialViewState={{ ...viewport }}
        controller={{
          touchZoom: true,
          touchRotate: true,
        }}
        getCursor={getCursor()}
        layers={parkingPaymentMapLayers}
        pickingRadius={12}
        onDragEnd={handleDragEnd}
        onClick={handleSelectParkingMeter}
        onViewStateChange={({ viewState }: any) => updateParkingMetersViewport(viewState)}
      >
        <StaticMap mapboxAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={mapStyle} />
      </DeckGL>
      <div className="absolute top-0 left-6 z-6 flex gap-2">
        <SearchPlace onSelect={selectAddress} coordinates={viewport} />
        <SelectMapType mapType={mapType} onSelectMapType={(value) => setMapType(value)} />
      </div>
      <SwitchModeView modeView={modeView} selectModeView={(mode: ModeViewType) => setModeView(mode)} />
      <div className="absolute top-60 left-8 z-6">
        <MapToolBox disabled={parkingModalState !== ParkingMeterModalState.DEACTIVATE} onClickAdd={handleClickAdd} />
      </div>
      {parkingModalState !== ParkingMeterModalState.DEACTIVATE && parkingMeterDetail && (
        <ParkingMeterModal
          coordinates={viewport}
          parkingModalState={parkingModalState}
          setParkingModalState={setParkingModalState}
          parkingMeter={parkingMeterDetail}
          saveParkingMeter={saveParkingMeter}
          setParkingMeterDetail={setParkingMeterDetail}
          cancel={handleClose}
        />
      )}
      {parkingModalState !== ParkingMeterModalState.MINIMIZE && parkingMeterDetail && parkingMeterDetail.id && (
        <ParkingMeterDetail
          parkingMeter={parkingMeterDetail}
          setParkingModalState={setParkingModalState}
          handleRemoveParkingMeter={handleRemoveParkingMeter}
        />
      )}
    </div>
  );
};
