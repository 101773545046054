import clsx from "clsx";
import { hasClassName } from "helper/check-class";

type DataTableHtmlProps = React.ComponentPropsWithoutRef<"div"> & React.ComponentPropsWithoutRef<"table">;
interface IDataTableProps extends DataTableHtmlProps {
  children: React.ReactNode;
  className?: string;
}
export const DataTable = ({ children, className }: IDataTableProps) => {
  return (
    <div
      className={clsx(
        "align-middle inline-block min-w-full overflow-hidden bg-primary shadow-dashboard",
        !hasClassName(className, "rounded-") && "rounded-bl-lg rounded-br-lg",
        !hasClassName(className, "p-") && "px-8 pt-3",
        !hasClassName(className, "shadow-") && "shadow-sm",
        className,
      )}
    >
      <table className="min-w-full">{children}</table>
    </div>
  );
};
