import { ParkingMeter } from "services/web/api-parking-meter.type";

export const initParkingMeter: ParkingMeter = {
  name: "",
  address: "",
  status: "",
  externalId: "",
  location: {
    type: "Point",
    coordinates: [],
  },
  operator: "",
  brand: "",
  model: "",
  zone: "",
  options: {
    cash: false,
    creditCard: false,
    contactless: false,
  },
};

export enum ParkingMeterModalState {
  SHOW = "SHOW",
  DEACTIVATE = "DEACTIVATE",
  MINIMIZE = "MINIMIZE",
}
