import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox, FieldContainer } from "components/form-control";
import { HintText } from "components/hint-text";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { FeatureCollection, Polygon } from "geojson";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { AppMode, AppModeType } from "types";
import { WorkArea, WorkAreaProperties } from "types/work-area.type";

interface ModifyWorkAreaPromptProps {
  appMode: AppMode;
  setOpenWorkAreaModal: () => void;
  setWorkAreaEditionData: Dispatch<SetStateAction<FeatureCollection<Polygon>>>;
  workAreaEditionData: FeatureCollection<Polygon>;
  workAreaDetail?: WorkArea | null;
  saveWorkAreaProperties: (promptedProperties: WorkAreaProperties) => Promise<void>;
  cancelWorkAreaChange: () => void;
}

export const ModifyWorkAreaPrompt = ({
  appMode,
  workAreaDetail,
  setOpenWorkAreaModal,
  workAreaEditionData,
  setWorkAreaEditionData,
  cancelWorkAreaChange,
  saveWorkAreaProperties,
}: ModifyWorkAreaPromptProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<WorkAreaProperties>({
    mode: "onChange",
    defaultValues: {
      name: workAreaDetail?.name ?? "",
      done: workAreaDetail?.done ?? false,
      cityId: workAreaDetail?.cityId ?? "",
    },
  });
  const submit = async (formValues: WorkAreaProperties) => {
    await saveWorkAreaProperties({ ...formValues });
    reset();
  };
  const handleClose = () => {
    if (appMode === AppModeType.AddWorkArea) {
      setWorkAreaEditionData({ ...workAreaEditionData, features: workAreaEditionData.features.slice(0, -1) });
    }
    cancelWorkAreaChange();
    setOpenWorkAreaModal();
    reset();
  };

  return (
    <Modal isOpen>
      <ModalContent title={`${appMode === AppModeType.AddWorkArea ? "Add new" : "Edit"} a work area`}>
        <form onSubmit={handleSubmit(submit)}>
          <FieldContainer className="mt-8">
            <Input
              {...register("name", { required: "This field is required" })}
              defaultValue={workAreaDetail?.name}
              label="Name"
              isRequired
              disabled={isSubmitting}
            />
            {errors.name && <HintText status="error">{errors.name.message}</HintText>}
          </FieldContainer>
          {appMode === AppModeType.EditWorkArea && (
            <FieldContainer className="mt-8">
              <Checkbox disabled={isSubmitting} {...register("done")} label="Done" />
            </FieldContainer>
          )}
          <ModalFooter>
            <Button disabled={isSubmitting} type="submit" variant="primary">
              Save
            </Button>
            <Button disabled={isSubmitting} variant="primary" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
