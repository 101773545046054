import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CityContext } from "contexts/city-context-provider";
import { FeatureCollection } from "geojson";

import { curbAreaMode, editableGeoJsonLayerModes } from "constants/map.const";
import { filterFeatureIndex } from "helper/map-utils";
import { MapViewProps } from "../curb-zone.type";
import { EditableGeoJsonLayer } from "@deck.gl-community/editable-layers";
import { CurbArea } from "types/curb-area.type";
import { useQueryCurbArea } from "./use-query-curb-area";
import useToggle from "hooks/use-toggle";
import { GeoJsonLayer } from "@deck.gl/layers";
import { CreateCurbAreaInput, UpdateCurbAreaInput } from "services/web/api-curb-area.type";
import { CurbAreaProperties } from "../components/curb-area/modify-curb-area-prompt";
import { Feature } from "@deck.gl-community/editable-layers/dist/utils/geojson-types";
import { AppModeType } from "types";

type UseCityParams = Pick<MapViewProps, "appMode">;
export const useCurbArea = ({ appMode }: UseCityParams) => {
  const { currentCity } = useContext(CityContext);
  const [openCurbAreaModal, setOpenCurbAreaModal] = useToggle(false);
  const [curbAreaEditionData, setCurbAreaEditionData] = useState<FeatureCollection>({
    type: "FeatureCollection",
    features: [],
  });
  const [curbAreaDetail, setCurbAreaDetail] = useState<CurbArea>();
  const { curbAreas, loading: curbAreaLoading, handleUpdateCurbArea, handleAddCurbArea } = useQueryCurbArea();
  const currentEditCurbAreaLayer = useRef<CurbArea>();
  const curbAreaLayerIndexes = useMemo(
    () => filterFeatureIndex(curbAreaEditionData, curbAreaDetail),
    [curbAreaEditionData, curbAreaDetail],
  );

  const showCurbAreaDetail = useMemo(
    () => appMode === AppModeType.EditCurbArea && curbAreaDetail && Object.keys(curbAreaDetail).length > 0,
    [appMode, curbAreaDetail],
  );

  const editableGeoJsonLayerMode = useMemo(() => editableGeoJsonLayerModes[appMode], [appMode]);

  const resetDataOfEditState = () => {
    setCurbAreaDetail(undefined);
    currentEditCurbAreaLayer.current = Object.assign({});
  };

  /**
   * If user click outside a work-area, deselect a work-area
   */
  const handleSelectCurbArea = (info: any) => {
    if (info.layer?.id !== "curb-areas-layer") {
      resetDataOfEditState();
      return;
    }
    currentEditCurbAreaLayer.current = info.object;
  };

  const cancelCurbAreaChange = () => {
    const curbAreaPolygonDataNotChange = curbAreaEditionData.features.filter(
      (polygonData) => polygonData.id !== currentEditCurbAreaLayer.current?.id,
    );
    const resetCurbAreaPolygonData = [...curbAreaPolygonDataNotChange, { ...currentEditCurbAreaLayer.current }];
    setCurbAreaEditionData({ type: "FeatureCollection", features: resetCurbAreaPolygonData } as FeatureCollection);
    resetDataOfEditState();
  };
  const openAreaModal = async () => {
    setOpenCurbAreaModal();
  };
  const onEditCurbAreaLayer = async ({ updatedData, editType }: any) => {
    setCurbAreaEditionData(updatedData);
    if (editType === "finishMovePosition" || editType === "addFeature") {
      if (appMode === AppModeType.AddCurbArea) {
        setOpenCurbAreaModal();
      } else {
        saveCurbAreaProperties();
      }
    }
  };

  const onClickCurbAreaLayer = (info: any) => {
    if (info.object && appMode === AppModeType.EditCurbArea) {
      if (info.object && (info.object.id === curbAreaDetail?.id || !info.object.id)) {
        //Click an existed curb-area or remove a point of polygon
        return;
      }
      //Select  a curb-area
      setCurbAreaDetail(info.object);
    } else {
      setCurbAreaDetail(undefined);
    }
  };

  const saveCurbAreaProperties = async (promptedProperties?: CurbAreaProperties) => {
    if (appMode === AppModeType.AddCurbArea) {
      const lastCurbAreaFeature = curbAreaEditionData.features.at(-1);
      if (!lastCurbAreaFeature) return;
      const createCurbAreaParam = {
        curbAreaInput: {
          ...promptedProperties,
          cityId: currentCity?.id,
          geometry: lastCurbAreaFeature.geometry,
        },
      } as CreateCurbAreaInput;
      await handleAddCurbArea(createCurbAreaParam);
    } else {
      if (!curbAreaDetail) return;
      const updateCurbAreaParam = {
        curbAreaInput: {
          ...(promptedProperties || curbAreaEditionData.features[curbAreaLayerIndexes[0]]),
          id: curbAreaDetail?.id,
          cityId: currentCity?.id,
          geometry: curbAreaEditionData.features[curbAreaLayerIndexes[0]].geometry,
        },
      } as UpdateCurbAreaInput;
      await handleUpdateCurbArea(updateCurbAreaParam);
      if (promptedProperties) {
        resetDataOfEditState();
      }
    }
    if (openCurbAreaModal) {
      setOpenCurbAreaModal();
    }
  };

  const isVisibleCurbAreaLayer = useMemo(() => {
    return appMode === AppModeType.AddCurbArea || appMode === AppModeType.EditCurbArea;
  }, [appMode]);
  const editableCurbAreaLayer = new EditableGeoJsonLayer({
    id: "curb-areas-layer",
    data: { type: curbAreaEditionData.type, features: curbAreaEditionData.features as Feature[] },
    mode: editableGeoJsonLayerMode,
    selectedFeatureIndexes: curbAreaLayerIndexes,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    getFillColor: [0, 0, 0, 0],
    autoHighlight: true,
    getLineColor: [54, 240, 171, 255],
    onEdit: onEditCurbAreaLayer,
    onClick: onClickCurbAreaLayer,
    visible: isVisibleCurbAreaLayer,
  });

  const curbAreaLayer = new GeoJsonLayer({
    id: "curb-area-layer",
    data: curbAreaEditionData,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    getLineColor: [54, 240, 171],
    getFillColor: [0, 0, 0, 0],
    visible: !isVisibleCurbAreaLayer,
  });

  const getCurbAreaLayers = () => {
    if (curbAreaMode.includes(appMode)) {
      return [editableCurbAreaLayer, curbAreaLayer];
    }
    return [curbAreaLayer];
  };

  useEffect(() => {
    if (!currentCity || curbAreaLoading) {
      return;
    }
    setCurbAreaEditionData({
      type: "FeatureCollection",
      features: curbAreas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curbAreas, curbAreaLoading]);

  return {
    openCurbAreaModal,
    setOpenCurbAreaModal,
    curbAreaEditionData,
    setCurbAreaEditionData,
    curbAreaDetail,
    setCurbAreaDetail,
    showCurbAreaDetail,
    curbAreaLayerIndexes,
    openAreaModal,
    cancelCurbAreaChange,
    handleSelectCurbArea,
    getCurbAreaLayers,
    saveCurbAreaProperties,
    resetDataOfEditState,
  };
};
