import { Badge, BadgeProps } from "../badge";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { isDefined, isPrimitive } from "helper/utils";
import { Label } from "../label";
import { useEffect, useState, ComponentProps, useMemo } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ChevronDownIcon } from "@/assets";
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command";
import { Input } from "@/components/ui/input";

export type OptionData<T> = {
  value: T;
  label: string;
};

export type DropdownMultiSelectProps<T = string> = {
  values: T[];
  onChangeValues: (values: T[], label?: string) => void;
  badgeColor?: BadgeProps["variant"];
  options?: OptionData<T>[];
  onInputValueChange?: (inputValue: string) => void;
  label?: string;
  isRequired?: boolean;
  classNamePopover?: string;
  size?: BadgeProps["size"];
} & Omit<ComponentProps<"input">, "onChange" | "onKeyDown" | "value" | "size">;

export const DropdownMultiSelect = <T extends string>({
  label,
  size = "large",
  values,
  onChangeValues,
  badgeColor = "grey",
  className,
  options,
  onInputValueChange,
  isRequired,
  classNamePopover,
  ...inputProps
}: DropdownMultiSelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { name } = inputProps;
  const [listOptions, setListOptions] = useState<OptionData<T>[]>([]);

  const onInputChange = (value: string) => {
    setInputValue(value);
    if (onInputValueChange) {
      onInputValueChange(value.trim());
    }
  };

  const optionData = useMemo(
    () =>
      (options as any[]).map(
        (opt) =>
          (isPrimitive(opt)
            ? {
                value: opt,
                label: String(opt),
              }
            : opt) as OptionData<T>,
      ),
    [options],
  );

  const selectedItems = useMemo(
    () => (optionData ? values.map((v) => optionData.find((opt) => opt.value === v)).filter(isDefined) : []),
    [values, optionData],
  );

  useEffect(() => {
    const requestId = window.setTimeout(() => {
      const newOptions = optionData.filter(
        (p) => selectedItems.indexOf(p) === -1 && p.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
      );
      setListOptions(newOptions);
    }, 500);

    return () => window.clearTimeout(requestId);
  }, [inputValue, selectedItems, optionData]);

  // Remove a single value
  const removeSelectedItem = (value: string) => {
    const newValues = values.filter((val) => val !== value);
    onChangeValues(newValues);
  };

  // select an item
  const selectItem = (value: T) => {
    onChangeValues([...values, value]);
  };
  // Clear all selected values
  const clearValues = () => {
    onChangeValues([]);
  };

  return (
    <div>
      <Label htmlFor={name ?? ""} className="form-label-focus">
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </Label>
      <div className="w-full max-w-sm">
        {values.length > 0 && (
          <div className="flex flex-wrap gap-1 overflow-x-auto max-w-full">
            {selectedItems.map((item, index) => {
              return (
                <Badge
                  key={`selectedItem-${item.value}-${index}`}
                  size={size}
                  variant={badgeColor}
                  onRemove={(e) => {
                    e.stopPropagation();
                    removeSelectedItem(item.value);
                  }}
                  className="mr-1"
                >
                  {item.label}
                </Badge>
              );
            })}
          </div>
        )}
        <Popover open={open} onOpenChange={setOpen} modal={true}>
          <PopoverTrigger asChild>
            <Button variant="outline" aria-expanded={open} className={cn("w-full justify-between", className)}>
              {inputProps.placeholder}
              <ChevronDownIcon
                className={`${open && "rotate-180 transform"} transition duration-150 ease-in-out w-5`}
                aria-hidden="true"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent className={cn("w-full", classNamePopover)}>
            <Command>
              <Input
                placeholder={inputProps.placeholder}
                onChange={(e) => onInputChange(e.target.value)}
                value={inputValue}
                className="px-2"
              />
              <CommandEmpty>No record found.</CommandEmpty>
              <CommandGroup className="max-h-52 overflow-y-auto">
                {listOptions?.map((item, idx) => (
                  <CommandItem
                    key={`suggestion-${item.value}-${idx}`}
                    onSelect={() => selectItem(item.value)}
                    className="cursor-pointer"
                  >
                    <span>{item.label}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
              {values.length > 0 && (
                <div className="border-t p-2">
                  <Button
                    variant="outline"
                    size="small"
                    className="w-full"
                    onClick={() => {
                      clearValues();
                      setOpen(false);
                    }}
                  >
                    Clear selections
                  </Button>
                </div>
              )}
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
