import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { FieldContainer, Textarea } from "components/form-control";
import { useForm } from "react-hook-form";
import { City } from "types";
import { Dispatch, SetStateAction } from "react";
import { ParkingLot } from "services/web/api-parking-lot.type";
import { Input } from "@/components/ui/input";

interface ModifyParkingLotPromptProps {
  setOpenParkingLotModal: Dispatch<SetStateAction<boolean>>;
  parkingLotDetail?: ParkingLot;
  currentCity: City | null;
  resetEditState: VoidFunction;
  saveParkingLotProperties: (promptedProperties?: ParkingLotProperties) => void;
}

export type ParkingLotProperties = {
  name: string;
  note: string;
  address: string;
};
export const ModifyParkingLotPrompt = ({
  parkingLotDetail,
  saveParkingLotProperties,
  resetEditState,
}: ModifyParkingLotPromptProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<ParkingLotProperties>({
    mode: "onChange",
  });

  const handleClose = () => {
    resetEditState();
    reset();
  };

  const submit = async (formValues: ParkingLotProperties) => {
    saveParkingLotProperties(formValues);
    handleClose();
  };

  return (
    <Modal isOpen>
      <ModalContent title={`${parkingLotDetail?.id ? "Edit" : "Add new"} a parking lot`}>
        <form onSubmit={handleSubmit(submit)}>
          <FieldContainer className="mt-8">
            <Input {...register("name")} disabled={isSubmitting} defaultValue={parkingLotDetail?.name} label="Name" />
          </FieldContainer>
          <FieldContainer className="mt-8">
            <Input
              {...register("address")}
              disabled={isSubmitting}
              defaultValue={parkingLotDetail?.address}
              label="Address"
            />
          </FieldContainer>
          <FieldContainer className="mt-8">
            <Textarea
              {...register("note")}
              label="Note"
              disabled={isSubmitting}
              defaultValue={parkingLotDetail?.note}
            />
          </FieldContainer>
          <ModalFooter>
            <Button disabled={isSubmitting} type="submit" variant="primary">
              Save
            </Button>
            <Button disabled={isSubmitting} variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
