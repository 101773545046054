import { Wrapper } from "components/layout";
import { ParkingPaymentMap } from "modules/parking-payment";

export function ParkingPaymentPage() {
  return (
    <Wrapper title="Parking Payment Page" className="mask-background">
      <div className="relative -mt-16 map-height">
        <ParkingPaymentMap />
      </div>
    </Wrapper>
  );
}
