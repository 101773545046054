import { FieldContainer } from "components/form-control";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "hooks/lib-ui";
import { CityContext } from "contexts/city-context-provider";
import { filterArrayByString, sortAsc } from "helper/array";
import { DropdownAutoCompleteSelect, OptionType } from "../form-control/dropdown-autocomplete";
import { useSetQueryParams } from "hooks/use-set-query-params";

export const CityListing = () => {
  const [value, setValue] = useState<string>("");
  const searchString = useDebounce(value, 500);
  const { currentCity, handleSelectCity, cityList, isCityListLoading } = useContext(CityContext);
  const [selectedCity, setSelectedCity] = useState<string>();
  const setQueryParams = useSetQueryParams();
  const listCity = useMemo((): OptionType[] => {
    if (isCityListLoading || !cityList) {
      return [];
    }
    const newList = cityList.map((city) => ({
      value: city.id,
      label: city.name,
    }));
    return sortAsc(newList, "label");
  }, [cityList, isCityListLoading]);

  useEffect(() => {
    if (selectedCity === currentCity?.id) {
      return;
    }
    if (currentCity) {
      setSelectedCity(currentCity?.id);
      setQueryParams({ cityID: currentCity.id });
    }
  }, [currentCity, selectedCity, setQueryParams]);

  const handleSearch = (value: string) => {
    setValue(value);
  };

  const filteredCity = filterArrayByString(listCity, "label", searchString);

  return (
    <div className="w-48">
      <FieldContainer className="mb-0">
        {isCityListLoading ? (
          <CitySkeleton />
        ) : (
          <DropdownAutoCompleteSelect
            value={selectedCity}
            options={filteredCity}
            onChangeValue={(value) => handleSelectCity(value as string)}
            status="default"
            handleSearch={handleSearch}
            searchString={value}
            placeholder="Sélectionner une ville"
            size="small"
            className="w-48 bg-transparent"
          />
        )}
      </FieldContainer>
    </div>
  );
};

export const CitySkeleton = () => {
  return (
    <div className="rounded-md p-2.5 max-w-sm w-full mx-auto bg-secondary">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-300 rounded-sm" />
        </div>
      </div>
    </div>
  );
};
