import { useContext, useEffect, useMemo, useState } from "react";
import { CityContext } from "contexts/city-context-provider";
import { FeatureCollection, Polygon } from "geojson";
import { workAreaMode, editableGeoJsonLayerModes } from "constants/map.const";
import { filterFeatureIndex } from "helper/map-utils";
import { MapViewProps } from "../curb-zone.type";
import { EditableGeoJsonLayer } from "@deck.gl-community/editable-layers";
import { WorkArea, WorkAreaProperties } from "types/work-area.type";
import { useQueryWorkArea } from "./use-query-work-area";
import useToggle from "hooks/use-toggle";
import { GeoJsonLayer } from "@deck.gl/layers";
import { CreateWorkAreaInput, UpdateWorkAreaInput } from "services/web/api-work-area.type";
import { Feature } from "@deck.gl-community/editable-layers/dist/utils/geojson-types";
import { AppModeType } from "types";

type UseCityParams = Pick<MapViewProps, "appMode">;
export const useWorkArea = ({ appMode }: UseCityParams) => {
  const { currentCity } = useContext(CityContext);
  const [openWorkAreaModal, setOpenWorkAreaModal] = useToggle(false);
  const [workAreaEditionData, setWorkAreaEditionData] = useState<FeatureCollection<Polygon>>({
    type: "FeatureCollection",
    features: [],
  });
  const [workAreaDetail, setWorkAreaDetail] = useState<WorkArea>();
  const { workAreas, loading: workAreaLoading, handleUpdateWorkArea, handleAddWorkArea } = useQueryWorkArea();
  const workAreaLayerIndexes = useMemo(
    () => filterFeatureIndex(workAreaEditionData, workAreaDetail),
    [workAreaEditionData, workAreaDetail],
  );

  const showWorkAreaDetail = useMemo(
    () => appMode === AppModeType.EditWorkArea && workAreaDetail,
    [appMode, workAreaDetail],
  );

  const editableGeoJsonLayerMode = useMemo(() => editableGeoJsonLayerModes[appMode], [appMode]);

  const resetDataOfEditState = () => {
    setWorkAreaDetail(undefined);
  };

  /**
   * If user click outside a work-area, deselect a work-area
   */
  const handleSelectWorkArea = (info: any) => {
    if (info.layer?.id !== "work-areas-layer") {
      resetDataOfEditState();
    }
  };

  const onEditWorkAreaLayer = async ({ updatedData, editType }: any) => {
    setWorkAreaEditionData(updatedData);
    if (editType === "finishMovePosition" || editType === "addFeature") {
      if (appMode === AppModeType.AddWorkArea) {
        setOpenWorkAreaModal();
      } else {
        await saveWorkAreaProperties();
      }
    }
  };

  const onClickWorkAreaLayer = (info: any) => {
    if (info.object && appMode === AppModeType.EditWorkArea) {
      if (info.object.id === workAreaDetail?.id || !info.object.id) {
        //Click an existed curb-area or remove a point of polygon
        return;
      }
      //Select a work-area
      setWorkAreaDetail(info.object);
    } else {
      setWorkAreaDetail(undefined);
    }
  };

  const saveWorkAreaProperties = async (promptedProperties?: WorkAreaProperties) => {
    if (appMode === AppModeType.AddWorkArea) {
      const lastWorkAreaFeature = workAreaEditionData.features.at(-1);
      if (!lastWorkAreaFeature) return;
      const createWorkAreaParam = {
        workAreaInput: {
          name: promptedProperties?.name,
          cityId: currentCity?.id,
          geometry: lastWorkAreaFeature.geometry,
        },
      } as CreateWorkAreaInput;
      await handleAddWorkArea(createWorkAreaParam);
    } else {
      if (!workAreaDetail) return;
      const updateWorkAreaParam = {
        workAreaInput: {
          ...(promptedProperties || workAreaEditionData.features[workAreaLayerIndexes[0]]),
          id: workAreaDetail?.id,
          cityId: currentCity?.id,
          geometry: workAreaEditionData.features[workAreaLayerIndexes[0]].geometry,
        },
      } as UpdateWorkAreaInput;
      const response = await handleUpdateWorkArea(updateWorkAreaParam);
      if (response?.updateWorkArea) {
        setWorkAreaDetail(response.updateWorkArea);
      }
      if (promptedProperties) {
        resetDataOfEditState();
      }
    }
    if (openWorkAreaModal) {
      setOpenWorkAreaModal();
    }
  };

  const isVisibleWorkAreaLayer = useMemo(() => {
    return appMode === AppModeType.AddWorkArea || appMode === AppModeType.EditWorkArea;
  }, [appMode]);
  const editableWorkAreaLayer = new EditableGeoJsonLayer({
    id: "work-areas-layer",
    data: { type: workAreaEditionData.type, features: workAreaEditionData.features as Feature[] },
    mode: editableGeoJsonLayerMode,
    selectedFeatureIndexes: workAreaLayerIndexes,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    getFillColor: [0, 0, 0, 0],
    autoHighlight: true,
    getLineColor: [54, 0, 171, 255],
    onEdit: onEditWorkAreaLayer,
    onClick: onClickWorkAreaLayer,
    visible: isVisibleWorkAreaLayer,
  });

  const workAreaLayer = new GeoJsonLayer({
    id: "work-area-layer",
    data: workAreaEditionData,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 1,
    getLineColor: [54, 120, 200],
    getFillColor: [0, 0, 0, 0],
    visible: !isVisibleWorkAreaLayer,
  });

  const getWorkAreaLayers = () => {
    if (workAreaMode.includes(appMode)) {
      return [editableWorkAreaLayer, workAreaLayer];
    }
    return [workAreaLayer];
  };

  useEffect(() => {
    if (!currentCity || workAreaLoading) {
      return;
    }
    setWorkAreaEditionData({
      type: "FeatureCollection",
      features: workAreas,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workAreaLoading, workAreas]);

  return {
    openWorkAreaModal,
    setOpenWorkAreaModal,
    workAreaEditionData,
    setWorkAreaEditionData,
    workAreaDetail,
    setWorkAreaDetail,
    showWorkAreaDetail,
    workAreaLayerIndexes,
    handleSelectWorkArea,
    getWorkAreaLayers,
    saveWorkAreaProperties,
    resetDataOfEditState,
  };
};
