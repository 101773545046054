import React, { useRef, useEffect, useMemo } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_MAPS_API_KEYS, StreetViewProps } from "./street-view.const";

export const StreetView = ({ position, onPositionChanged }: StreetViewProps) => {
  const streetViewRef = useRef<HTMLDivElement>(null);
  const panoramaRef = useRef<google.maps.StreetViewPanorama | null>(null);

  const viewState = useMemo<google.maps.StreetViewPanoramaOptions>(
    () => ({
      position: { lat: 48.8967, lng: 2.2567 },
      pov: { heading: 270, pitch: 0 },
      visible: true,
      zoom: 1,
      motionTracking: false,
      motionTrackingControl: true,
      fullscreenControl: false,
      addressControl: true,
    }),
    [],
  );

  const loader = useMemo(
    () =>
      new Loader({
        apiKey: GOOGLE_MAPS_API_KEYS,
        version: "weekly",
      }),
    [],
  );

  // Load the street view and create a panorama
  useEffect(() => {
    let isMounted = true;

    const loadStreetView = async () => {
      try {
        const { StreetViewPanorama } = await loader.importLibrary("streetView");
        if (isMounted && streetViewRef.current) {
          if (!panoramaRef.current) {
            panoramaRef.current = new StreetViewPanorama(streetViewRef.current, viewState);
          } else {
            panoramaRef.current.setOptions(viewState);
          }
        }
      } catch (err) {
        return err;
      }
    };

    loadStreetView();

    return () => {
      isMounted = false;
      panoramaRef.current?.setVisible(false);
      panoramaRef.current = null;
    };
  }, [loader, viewState]);

  useEffect(() => {
    // update the streetView when clicking on the map
    if (panoramaRef.current) {
      if (position) {
        panoramaRef.current.setPosition(position);
      }
    }
  }, [position]);

  useEffect(() => {
    if (panoramaRef.current && onPositionChanged) {
      const listener = panoramaRef.current.addListener("position_changed", () => {
        const newPosition = panoramaRef.current?.getPosition();
        if (newPosition) {
          onPositionChanged({ lat: newPosition.lat(), lng: newPosition.lng() });
        }
      });

      // Clean up the listener on unmount
      return () => google.maps.event.removeListener(listener);
    }
  }, [onPositionChanged]);

  return <div ref={streetViewRef} className="w-full h-full" />;
};
