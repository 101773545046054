import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_ADD_WORK_AREA,
  QUERY_UPDATE_WORK_AREA,
  QUERY_REMOVE_WORK_AREA,
  QUERY_WORK_AREAS,
} from "services/web/api-work-area.const";
import {
  CreateWorkAreaInput,
  CreateWorkAreaResponse,
  RemoveWorkAreaInput,
  RemoveWorkAreaResponse,
  UpdateWorkAreaInput,
  UpdateWorkAreaResponse,
  WorkAreasResponse,
} from "services/web/api-work-area.type";
import { useContext, useEffect, useMemo, useState } from "react";
import { CityContext } from "contexts/city-context-provider";
import { hideGlobalLoading, showGlobalLoading } from "hooks/use-global-loading";

export const useQueryWorkArea = () => {
  const [isResetData, setIsResetData] = useState<boolean>(false);
  const { currentCity } = useContext(CityContext);

  const variables = useMemo(() => {
    return {
      cityId: currentCity?.id,
    };
  }, [currentCity]);

  const {
    data,
    loading,
    refetch: reFetchWorkAreaData,
  } = useQuery<WorkAreasResponse>(QUERY_WORK_AREAS, {
    variables,
    skip: !currentCity?.id,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const resetState = () => {
    setIsResetData(true);
  };

  const [createWorkArea] = useMutation<CreateWorkAreaResponse>(QUERY_ADD_WORK_AREA, {
    onCompleted: async () => {
      await reFetchWorkAreaData();
    },
    onError: resetState,
  });

  const [updateWorkArea] = useMutation<UpdateWorkAreaResponse>(QUERY_UPDATE_WORK_AREA, {
    onCompleted: async () => {
      await reFetchWorkAreaData();
    },
    onError: resetState,
  });

  const [removeWorkArea] = useMutation<RemoveWorkAreaResponse>(QUERY_REMOVE_WORK_AREA, {
    onCompleted: async () => {
      await reFetchWorkAreaData();
    },
    onError: resetState,
  });

  const handleAddWorkArea = async (input: CreateWorkAreaInput) => {
    const response = await createWorkArea({ variables: input });
    return response.data;
  };

  const handleUpdateWorkArea = async (input: UpdateWorkAreaInput) => {
    const response = await updateWorkArea({ variables: input });
    return response.data;
  };

  const handleRemoveWorkArea = async (input: RemoveWorkAreaInput) => {
    const response = await removeWorkArea({ variables: input });
    return response.data;
  };
  return {
    workAreas: data?.workAreas ?? [],
    loading,
    currentCity,
    handleAddWorkArea,
    handleUpdateWorkArea,
    handleRemoveWorkArea,
    isResetData,
    setIsResetData,
  };
};
