import {
  EditLocationIcon,
  OffstreetParkingIcon,
  ParkingAreaIcon,
  CityIcon,
  PoliciesIcon,
  ParkingPaymentIcon,
} from "components/icons";
import { routes } from "routes/constants";
import { ERole } from "store/auth/auth.type";
import React from "react";

export const menuItems: MenuItem[] = [
  {
    path: routes.root.path,
    displayName: routes.root.display,
    menuIcon: ParkingAreaIcon,
    roles: [ERole.Admin, ERole.Contributor],
  },
  {
    path: routes.offstreetParking.path,
    displayName: routes.offstreetParking.display,
    menuIcon: OffstreetParkingIcon,
    roles: [ERole.Admin, ERole.Contributor],
  },
  {
    path: routes.disabledParts.path,
    displayName: routes.offstreetParking.display,
    menuIcon: EditLocationIcon,
    roles: [ERole.Admin, ERole.Contributor],
  },
  {
    path: routes.policies.path,
    displayName: routes.policies.display,
    menuIcon: PoliciesIcon,
    roles: [ERole.Admin, ERole.Contributor],
  },
  {
    path: routes.parkingPayment.path,
    displayName: routes.parkingPayment.display,
    menuIcon: ParkingPaymentIcon,
    roles: [ERole.Admin, ERole.Contributor],
  },
  {
    path: routes.cities.path,
    displayName: routes.cities.display,
    menuIcon: CityIcon,
    roles: [ERole.Admin],
  },
];

export type MenuItem = {
  path: string;
  displayName: string;
  menuIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  roles: ERole[];
};
