import { gql } from "@apollo/client";

export const QUERY_WORK_AREAS = gql`
  query findWorkAreas($cityId: String!) {
    workAreas(cityId: $cityId) {
      id
      name
      done
      geometry
      cityId
    }
  }
`;

export const QUERY_ADD_WORK_AREA = gql`
  mutation createWorkArea($workAreaInput: CreateWorkAreaInput!) {
    createWorkArea(workAreaInput: $workAreaInput) {
      id
      name
      done
      geometry
      cityId
    }
  }
`;

export const QUERY_UPDATE_WORK_AREA = gql`
  mutation updateWorkArea($workAreaInput: UpdateWorkAreaInput!) {
    updateWorkArea(workAreaInput: $workAreaInput) {
      id
      name
      done
      geometry
      cityId
    }
  }
`;

export const QUERY_REMOVE_WORK_AREA = gql`
  mutation deleteWorkArea($id: String!) {
    deleteWorkArea(id: $id) {
      id
    }
  }
`;
