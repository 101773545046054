import { ChangeEvent, useState } from "react";
import { RgbaColor } from "react-colorful";

import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { ColorInput } from "types";
import { Checkbox, FieldContainer } from "components/form-control";
import { ColorPicker } from "components";
import { defaultFillColor, defaultLineColor, presetPolicyColors } from "../policies.const";
import { formatArrayToRgbaColor } from "helper/utils";

export interface ColorInputModalProps {
  currentColorInput: ColorInput;
  onDismiss: () => void;
  onApply: (value: ColorInput) => void;
}

export const ColorInputModal = ({ currentColorInput, onApply, onDismiss }: ColorInputModalProps) => {
  const [policyColor, setPolicyColor] = useState<ColorInput>({
    fillColor: currentColorInput.fillColor || defaultFillColor,
    lineColor: currentColorInput.lineColor || defaultLineColor,
    dashed: currentColorInput.dashed,
  });

  const handleChangeColor = (value: RgbaColor, type: "fillColor" | "lineColor") => {
    const formatColor = [value.r, value.g, value.b, value.a !== undefined ? Math.ceil(value.a * 255) : 1];
    setPolicyColor((prevState) => ({ ...prevState, [type]: formatColor }));
  };
  const handleChangeDashed = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setPolicyColor((prevState) => ({ ...prevState, dashed: checked }));
  };
  const handleSubmit = () => {
    onApply(policyColor);
    onDismiss();
  };

  return (
    <Modal isOpen>
      <ModalContent title="Color input">
        <FieldContainer label="Fill Color">
          <ColorPicker
            popoverClassName="-top-full left-full ml-3"
            color={formatArrayToRgbaColor(policyColor.fillColor)}
            presetColors={presetPolicyColors.fillColors}
            onChange={(value) => handleChangeColor(value, "fillColor")}
          />
        </FieldContainer>
        <FieldContainer label="Border Color">
          <ColorPicker
            popoverClassName="-top-full left-full ml-3"
            color={formatArrayToRgbaColor(policyColor.lineColor)}
            presetColors={presetPolicyColors.lineColors}
            onChange={(value) => handleChangeColor(value, "lineColor")}
          />
        </FieldContainer>
        <FieldContainer label="Dashed border">
          <Checkbox checked={policyColor.dashed} onChange={handleChangeDashed} />
        </FieldContainer>
        <ModalFooter className="text-right">
          <Button variant="primary" onClick={handleSubmit}>
            Apply
          </Button>
          <Button onClick={onDismiss} className="mr-2" variant="secondary">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
