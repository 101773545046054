import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { TimeSpanInput } from "types";
import { Checkbox } from "components/form-control/check-box";
import { FieldContainer } from "components/form-control/field-container";
import { Label } from "components/label";
import { DaysOfWeek } from "types/policty.enum";
import { CalendarSelector, DaysOfMonth, TimeInput } from "components/form-control";
import { MonthsOfYear } from "components/form-control/month-of-year";
import { DaysOfWeekInput, arrDay } from "./time-span.const";
import { TimeSpanComponentProps } from "./time-span.type";
import { Input } from "components/ui/input";
export interface DaysOfWeekModalProps extends Pick<TimeSpanComponentProps, "options" | "label"> {
  timeSpanData: TimeSpanInput;
  isOpen: boolean;
  onDismiss: () => void;
  onApply: (input: TimeSpanInput) => void;
}

export const TimeSpanModal = ({ timeSpanData, onDismiss, onApply, label, options }: DaysOfWeekModalProps) => {
  const [startDate, setStartDate] = useState<Date | string | null>(timeSpanData?.startDate ?? null);
  const [endDate, setEndDate] = useState<Date | string | null>(timeSpanData?.endDate ?? null);
  const [designatedPeriod, setDesignatedPeriod] = useState<string>(timeSpanData?.designatedPeriod ?? "");
  const [designatedPeriodExcept, setDesignatedPeriodExcept] = useState<boolean>(
    timeSpanData?.designatedPeriodExcept ?? false,
  );
  const [daysOfWeek, setDaysOfWeek] = useState(() => syncDaysOfWeek(timeSpanData.daysOfWeek ?? []));
  const [timeOfDay, setTimeOfDay] = useState({
    timeOfDayStart: timeSpanData.timeOfDayStart ?? "",
    timeOfDayEnd: timeSpanData.timeOfDayEnd ?? "",
  });
  const [daysOfMonth, setDaysOfMonth] = useState<number[]>(timeSpanData.daysOfMonth ?? []);
  const [months, setMonths] = useState<number[]>(timeSpanData.months ?? []);

  const onChangeDaysOfWeek = (index: number) => {
    const newDaysOfWeek = daysOfWeek;
    newDaysOfWeek[index].isCheck = !newDaysOfWeek[index].isCheck;
    setDaysOfWeek([...newDaysOfWeek]);
  };

  const enableApply = useMemo((): boolean => {
    //If the startDate and endDate, required the startDate, endDate
    //Only for Restriction modal
    if (options?.showSelectDate) {
      return !!startDate && !!endDate;
    }
    return true;
  }, [startDate, endDate, options?.showSelectDate]);

  const handleChangeTimes = ({ input, name }: { input: string; name: string }) => {
    const newTimes = input === "null" ? "" : input;
    if (name === "start") {
      setTimeOfDay((prevState) => ({ ...prevState, timeOfDayStart: newTimes }));
    } else {
      setTimeOfDay((prevState) => ({ ...prevState, timeOfDayEnd: newTimes }));
    }
  };

  const handleSave = () => {
    const newDaysOfWeek = getSelectedDaysOfWeek(daysOfWeek);
    const newTimeSpanData = {
      ...timeSpanData,
      daysOfWeek: newDaysOfWeek,
      daysOfMonth,
      ...timeOfDay,
      months,
      startDate,
      endDate,
      designatedPeriod,
      designatedPeriodExcept,
    };
    onApply(newTimeSpanData);
  };

  return (
    <Modal isOpen className="w-full mx-auto bg-default rounded-lg z-20 max-w-none">
      <ModalContent title={`Edit a ${label}`}>
        <form>
          {(options?.showSelectDate || options?.showTimeOfDay) && (
            <FieldContainer className="mt-8 flex gap-10 items-end">
              <div>
                <Label>{options?.showSelectDate ? "Start date" : "Time of day"}</Label>
                <div className="flex flex-row items-end gap-2">
                  {options?.showSelectDate && (
                    <CalendarSelector
                      selectedDate={startDate}
                      onChangeValue={(newDate) => setStartDate(newDate)}
                      maxDate={endDate as Date}
                    />
                  )}
                  {options?.showTimeOfDay && (
                    <TimeInput
                      times={timeOfDay.timeOfDayStart}
                      onChangeValue={(value: string) => handleChangeTimes({ input: value, name: "start" })}
                    />
                  )}
                </div>
              </div>
              <div>
                <Label>{options?.showSelectDate ? "End date" : ""}</Label>
                <div className="flex flex-row justify-end items-end gap-2">
                  {options?.showSelectDate && (
                    <CalendarSelector
                      selectedDate={endDate}
                      onChangeValue={(newDate) => setEndDate(newDate)}
                      minDate={startDate as Date}
                    />
                  )}
                  {options?.showTimeOfDay && (
                    <TimeInput
                      times={timeOfDay.timeOfDayEnd}
                      onChangeValue={(value: string) => handleChangeTimes({ input: value, name: "end" })}
                    />
                  )}
                </div>
              </div>
            </FieldContainer>
          )}

          <FieldContainer className="mt-8">
            <Label>Days</Label>
            <div className="flex">
              {daysOfWeek.map((day, index) => (
                <Checkbox
                  label={day.display}
                  onChangeValue={() => onChangeDaysOfWeek(index)}
                  checked={day.isCheck}
                  key={day.name}
                  wrapperClass="mr-2"
                />
              ))}
            </div>
          </FieldContainer>
          <FieldContainer className="mt-8">
            <Label>Days of month</Label>
            <DaysOfMonth days={daysOfMonth} onChange={setDaysOfMonth} />
          </FieldContainer>
          <FieldContainer className="mt-8">
            <Label>Months</Label>
            <MonthsOfYear months={months} onChange={setMonths} />
          </FieldContainer>
          {options?.showDesignatedPeriod && (
            <FieldContainer className="mt-8">
              <Input
                label="Designated Period"
                value={designatedPeriod}
                onChangeValue={(value) => setDesignatedPeriod(value)}
              />
            </FieldContainer>
          )}
          {options?.showDesignatedPeriodExcept && (
            <FieldContainer className="mt-8">
              <Checkbox
                label="Designated Period Except"
                checked={designatedPeriodExcept}
                onChangeValue={(value) => setDesignatedPeriodExcept(value)}
              />
            </FieldContainer>
          )}
          <ModalFooter className="text-right">
            <Button
              variant="primary"
              disabled={!enableApply}
              onClick={(ev) => {
                ev.preventDefault();
                if (enableApply) {
                  handleSave();
                }
              }}
            >
              Apply
            </Button>
            <Button onClick={onDismiss} className="mr-2" variant="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export const syncDaysOfWeek = (list: DaysOfWeek[]): DaysOfWeekInput[] => {
  const arrayDay = [];
  for (const item of arrDay) {
    arrayDay.push({ ...item, isCheck: list.includes(item.name) });
  }
  return arrayDay;
};

export const getSelectedDaysOfWeek = (list: DaysOfWeekInput[]): DaysOfWeek[] => {
  return list.reduce((previousValue: DaysOfWeek[], item) => {
    if (item.isCheck) {
      return [...previousValue, item.name];
    }
    return previousValue;
  }, []);
};
