import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { BoldSearchIcon, ChevronDownIcon } from "components/icons";
import { useState } from "react";
import { Checkbox } from "components/form-control/check-box";
import {
  arrDecreeAnalysedValues,
  arrDecreeStatusValues,
  DecreeAnalyzed,
  DecreeFilter,
  DecreeStatus,
} from "./decree.const";
import { useDebouncedCallback } from "hooks/use-debounce-callback";
import { Input } from "@/components/ui/input";
import useToggle from "@/hooks/use-toggle";
import { Button } from "@/components/ui/button";

export type variant = "primary" | "secondary" | "outline";

export type FilterDecreeProps = {
  filter: DecreeFilter;
  onChange: (input: DecreeFilter) => void;
};

export const FilterDecree = ({ onChange, filter }: FilterDecreeProps) => {
  const [open, setOpen] = useToggle();
  const [value, setValue] = useState<string>("");

  const changeFilter = (val: DecreeStatus | DecreeAnalyzed, type: keyof Omit<DecreeFilter, "searchString">) => {
    const statusExists = filter[type].findIndex((item) => item === val);
    const newStatus = [...filter[type]];
    if (statusExists > -1) {
      newStatus.splice(statusExists, 1);
      onChange({ ...filter, [type]: newStatus });
    } else {
      onChange({ ...filter, [type]: [...newStatus, val] });
    }
  };

  const handleSearch = useDebouncedCallback((value: string) => {
    onChange({ ...filter, searchString: value });
  });

  const handleSearchChange = (value: string) => {
    setValue(value);
    handleSearch(value);
  };

  return (
    <div className="px-4 flex">
      <div className="px-3 flex items-end">
        <Input
          onChangeValue={handleSearchChange}
          value={value}
          placeholder="Recherche de décrets"
          iconStart={<BoldSearchIcon />}
          className="w-44"
        />
      </div>
      <div className="flex">
        <Popover>
          <PopoverTrigger asChild>
            <Button onClick={setOpen} className="bg-none" variant="outline">
              <span className="text-medium font-medium text-primary">Filter decree</span>
              <ChevronDownIcon
                className={`${open && "rotate-180 transform"}
                  ml-2 transition duration-150 ease-in-out`}
                aria-hidden="true"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className="flex">
              <div className="relative bg-white py-sm pl-5 pr-6 pb-2 overflow-y-auto">
                {arrDecreeStatusValues.map((item, itemIdx) => (
                  <Checkbox
                    checked={filter.status.includes(item)}
                    onChangeValue={() => changeFilter(item, "status")}
                    label={item}
                    value={item}
                    key={`multi-select-${itemIdx}`}
                  />
                ))}
              </div>
              <div className="relative bg-white py-sm pl-5 pr-6 pb-2 overflow-y-auto">
                {arrDecreeAnalysedValues.map((item, itemIdx) => (
                  <Checkbox
                    checked={filter.analyzed.includes(item)}
                    onChangeValue={() => changeFilter(item, "analyzed")}
                    label={item}
                    value={item}
                    key={`multi-select-${itemIdx}`}
                  />
                ))}
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
