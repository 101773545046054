import { Switch, SwitchProps } from "@/components/ui/switch";
import { cn } from "@/lib/utils";

export const McaSwitch = ({ className, ...props }: SwitchProps) => {
  return (
    <Switch
      {...props}
      className={cn(
        "group inline-flex h-4 w-7 mt-2 items-center rounded-full bg-gray-400 transition data-checked:bg-title",
        className,
      )}
    ></Switch>
  );
};
