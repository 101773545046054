import { AppMode, AppModeType, DisabledModeType } from "types";

export const cursorCell: AppMode[] = [
  AppModeType.Add,
  DisabledModeType.AddDisabledCurbZone,
  AppModeType.AddCurbArea,
  AppModeType.AddParkingLot,
  DisabledModeType.AddDisabledStreet,
  AppModeType.AddWorkArea,
];
