import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  QUERY_ADD_PARKING_LOT,
  QUERY_DELETE_PARKING_LOT,
  QUERY_PARKING_LOT,
  QUERY_UPDATE_PARKING_LOT,
} from "services/web/api-parking-lot.const";
import {
  CreateParkingLotInput,
  CreateParkingLotResponse,
  ParkingLot,
  RemoveParkingLotInput,
  RemoveParkingLotResponse,
  UpdateParkingLotInput,
  UpdateParkingLotResponse,
  ParkingLotResponse,
} from "services/web/api-parking-lot.type";
import { findObject } from "helper/array";
import { showGlobalLoading, hideGlobalLoading } from "hooks/use-global-loading";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { CityContext } from "@/contexts/city-context-provider";

interface ParkingLotsCache {
  parkingLots: ParkingLot[] | [];
}

export const useQueryParkingLot = () => {
  const client = useApolloClient();
  const { currentCity } = useContext(CityContext);
  const [isResetData, setIsResetData] = useState<boolean>(false);

  const variables = useMemo(() => {
    return {
      cityId: currentCity?.id,
    };
  }, [currentCity]);

  const { data, loading } = useQuery<ParkingLotResponse>(QUERY_PARKING_LOT, {
    variables,
    skip: !currentCity?.id,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const parkingLots = useMemo(() => {
    return data?.parkingLots || [];
  }, [data]);

  const getCachedParkingLot = (): ParkingLot[] => {
    const cachedParkingLot = client.cache.readQuery({ query: QUERY_PARKING_LOT, variables }) as ParkingLotsCache;
    return cachedParkingLot.parkingLots;
  };

  const resetState = () => {
    setIsResetData(true);
  };

  const [createParkingLot] = useMutation<CreateParkingLotResponse>(QUERY_ADD_PARKING_LOT, {
    onCompleted: (response) => {
      const preState = getCachedParkingLot();
      const newState = [...preState, { ...response.createParkingLot }];
      client.cache.writeQuery({ query: QUERY_PARKING_LOT, variables, data: { parkingLots: newState } });
    },
    onError: resetState,
  });

  const [updateParkingLot] = useMutation<UpdateParkingLotResponse>(QUERY_UPDATE_PARKING_LOT, {
    onCompleted: (response) => {
      const preState = getCachedParkingLot();
      const findIndex = findObject(preState, "id", response.updateParkingLot?.id);
      if (findIndex > -1) {
        const newState = [...preState];
        newState[findIndex] = response.updateParkingLot;
        client.cache.writeQuery({ query: QUERY_PARKING_LOT, variables, data: { parkingLots: newState } });
      }
    },
    onError: resetState,
  });

  const [removeParkingLot] = useMutation<RemoveParkingLotResponse>(QUERY_DELETE_PARKING_LOT, {
    onCompleted: (response) => {
      const preState = getCachedParkingLot();
      const newState = preState.filter((item) => item.id !== response.deleteParkingLot?.id);
      client.cache.writeQuery({ query: QUERY_PARKING_LOT, variables, data: { parkingLots: newState } });
    },
    onError: resetState,
  });

  const handleAddParkingLot = async (input: CreateParkingLotInput) => {
    const response = await createParkingLot({ variables: input });
    return response.data;
  };

  const handleUpdateParkingLot = async (input: UpdateParkingLotInput) => {
    const response = await updateParkingLot({ variables: input });
    return response.data;
  };

  const handleDeleteParkingLot = async (input: RemoveParkingLotInput) => {
    const response = await removeParkingLot({ variables: input });
    return response.data;
  };

  return {
    parkingLots,
    loading,
    currentCity,
    isResetData,
    setIsResetData,
    handleAddParkingLot,
    handleUpdateParkingLot,
    handleDeleteParkingLot,
  };
};
