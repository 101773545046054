export const GOOGLE_MAPS_API_KEYS = import.meta.env.VITE_GOOGLE_MAPS_API_KEYS;

export type StreetViewPanoramaOptions = {
  position: { lat: number; lng: number };
  pov: { heading: number; pitch: number };
  visible: boolean;
};

export type StreetViewProps = {
  position: { lat: number; lng: number } | null;
  onPositionChanged: (postion: { lat: number; lng: number }) => void;
};
