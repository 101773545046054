import {
  Polygon,
  Position,
  MultiPolygon,
  Point,
  MultiPoint,
  LineString,
  MultiLineString,
  GeometryCollection,
} from "geojson";
import { OsmSearchJsonV2 } from "../services/common/osm.type";
import { IOffStreetParking } from "./private-parking";
import { CurbZone } from "./curb-zone.type";

export type Geometry = Point | MultiPoint | LineString | MultiLineString | Polygon | MultiPolygon | GeometryCollection;

/**
 * A city is a geographical subdivision.
 * It's a common ground for different parking spots.
 */
export type TimezoneEntity = {
  iana?: string;
  tzdb?: string;
};
export interface City {
  id: string;
  name: string;
  zipCode?: string;
  details: null | {
    departement: string; // TODO: is this a duplicate of 'state'?
    state: string;
    country: string;
    countryCode: string;
    surfaceArea: number; // m2
    osmId: number;
    dataProvider?: DataProvider;
    options?: CityOptions;
    currency?: Currency;
    timezone?: TimezoneEntity;
  };
  position: Position;
  image?: string;
  boundaries?: Geometry;
}

export interface DataProvider {
  name: string;
  deviceIds?: string[];
}

export interface CityOptions {
  prediction: boolean;
  offstreet: boolean;
  live: boolean;
  payment: boolean;
  paymentStats: boolean;
  mapIntegration: boolean;
  decreeAnalysis: boolean;
  decreeAnalysisCount?: DecreeAnalysisCount[];
}

export interface DecreeAnalysisCount {
  count: number;
  startDate: Date;
  endDate: Date;
}

export interface Currency {
  code: string;
  symbol?: string;
}

/** City as fetched for listing. */
export type SimplifiedCity = Pick<City, "name" | "id" | "zipCode" | "position">;

/** The part of the city to be filled by hand. */
export type PromptCity = Pick<City, "name" | "zipCode">;

/** The data we have to send to the backend */
export type CreationCity = Omit<City, "id" | "parkingAreas">;

export type CitiesFilter = {
  name: string;
  wikidata?: string;
};

export interface CreateCityParam extends OsmSearchJsonV2, PromptCity {}

export type AllCityResponse = {
  cities: City[];
};

export type CityResponse = {
  city: City;
};

export type ParkingAreasResponse = {
  parkingByBounding: CurbZone[];
};

export type OffStreetParkingsResponse = {
  privateParkingsByBounding: IOffStreetParking[];
};

export type CityInput = {
  name: string;
  zipCode: string;
  departement: string;
  state: string;
  country: string;
  countryCode: string;
  surfaceArea: string;
  dataProviderName: string;
  deviceIds: { device: string }[];
  latitude: string;
  longitude: string;
  boundaries: string;
  currency: Currency;
  options?: CityOptions;
  timezone?: string;
};

export type PagedResult<T> = {
  results: T[];
  totalPages: number;
};

export enum ESortOrder {
  ASC = "ASC",
  DESC = "DESC",
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
  ONE = "ONE",
  MINUSONE = "MINUSONE",
}

export interface PaginationParams {
  pageIndex: number;
  pageSize: number;
}

export interface FilterParams extends PaginationParams {
  sortField?: string;
  sortOrder?: ESortOrder;
}

export interface CitiesFilterParams extends FilterParams {
  cityName?: string;
}

export const initPage: PaginationParams = {
  pageIndex: 1,
  pageSize: 25,
};
