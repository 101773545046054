import { gql } from "@apollo/client";

export const QUERY_CITY = gql`
  query GetCity($cityId: String!) {
    city(id: $cityId) {
      id
      name
      zipCode
      details {
        departement
        surfaceArea
        state
        country
        countryCode
        osmId
        dataProvider {
          name
          deviceIds
        }
        currency {
          code
          symbol
        }
        timezone {
          iana
        }
      }
      position
      image
      boundaries
    }
  }
`;
export const QUERY_PAGINATED_CITIES = gql`
  query PaginatedCities(
    $filter: [FilterInput!]
    $pageIndex: Int!
    $pageSize: Int!
    $sortOrder: SortOrder
    $sortField: String
  ) {
    paginatedCities(
      filter: $filter
      pageIndex: $pageIndex
      pageSize: $pageSize
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      results {
        id
        name
        zipCode
        details {
          departement
          surfaceArea
          state
          country
          countryCode
          osmId
          dataProvider {
            name
            deviceIds
          }
          options {
            live
            prediction
            offstreet
            payment
            paymentStats
            mapIntegration
            decreeAnalysis
            decreeAnalysisCount {
              count
              startDate
              endDate
            }
          }
          currency {
            code
            symbol
          }
          timezone {
            iana
          }
        }
        position
        image
        boundaries
      }
      totalPages
    }
  }
`;

export const QUERY_CITY_LIST = gql`
  query GetSimplifiedCities {
    cities {
      id
      name
      zipCode
      details {
        departement
        surfaceArea
        state
        country
        countryCode
        osmId
        dataProvider {
          name
          deviceIds
        }
        options {
          live
          prediction
          offstreet
          payment
          paymentStats
          mapIntegration
          decreeAnalysis
        }
        currency {
          code
          symbol
        }
        timezone {
          iana
        }
      }
      position
      image
      boundaries
    }
  }
`;

export const QUERY_CREATE_CITY = gql`
  mutation addCity($city: CreateCityInput!) {
    createCity(createCityInput: $city) {
      id
      name
      zipCode
      details {
        departement
        surfaceArea
        state
        country
        countryCode
        osmId
        currency {
          code
          symbol
        }
        dataProvider {
          name
          deviceIds
        }
        options {
          live
          prediction
          offstreet
          payment
          paymentStats
          mapIntegration
          decreeAnalysis
        }
        timezone {
          iana
        }
      }
      position
      image
      boundaries
    }
  }
`;

export const QUERY_UPDATE_CITY = gql`
  mutation updateCity($city: UpdateCityInput!) {
    updateCity(updateCityInput: $city) {
      id
      name
      zipCode
      details {
        departement
        surfaceArea
        state
        country
        countryCode
        osmId
        dataProvider {
          name
          deviceIds
        }
        options {
          live
          prediction
          offstreet
          payment
          paymentStats
          mapIntegration
          decreeAnalysis
        }
        currency {
          code
          symbol
        }
        timezone {
          iana
        }
      }
      position
      image
      boundaries
    }
  }
`;

export const QUERY_REMOVE_CITY = gql`
  mutation removeCity($cityId: String!) {
    removeCity(id: $cityId) {
      id
    }
  }
`;

export const QUERY_OFF_STREET_PARKING = gql`
  query PrivateParkingsByBounding($cityId: String!, $boundingbox: [[Float!]!]) {
    privateParkingsByBounding(cityId: $cityId, boundingbox: $boundingbox) {
      id
      name
      address
      externalId
      cityId
      location
      totalPlaces
      pricePerHour
      otherAdresses
      maxHeight
      levels
      structure
      options {
        evChargers
        toilets
        disabledAccess
        disablesPlaces
        motorcycleAccess
        motorcyclePlaces
        bicycleAccess
        bicyclePlaces
      }
      openingHours {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
        designatedPeriod
        designatedPeriodExcept
      }
    }
  }
`;

export const QUERY_ADD_OFF_STREET_PARKING = gql`
  mutation addPrivateParkingToCity($createPrivateParkingInput: CreateOffStreetParkingInput!) {
    addPrivateParkingToCity(createPrivateParkingInput: $createPrivateParkingInput) {
      id
      name
      address
      externalId
      cityId
      location
      totalPlaces
      pricePerHour
      otherAdresses
      maxHeight
      levels
      structure
      options {
        evChargers
        toilets
        disabledAccess
        disablesPlaces
        motorcycleAccess
        motorcyclePlaces
        bicycleAccess
        bicyclePlaces
      }
      openingHours {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
        designatedPeriod
        designatedPeriodExcept
      }
    }
  }
`;

export const QUERY_UPDATE_OFF_STREET_PARKING = gql`
  mutation updatePrivateParking($updatePrivateParkingInput: UpdateOffStreetParkingInput!) {
    updatePrivateParking(updatePrivateParkingInput: $updatePrivateParkingInput) {
      id
      name
      address
      externalId
      cityId
      location
      totalPlaces
      pricePerHour
      otherAdresses
      maxHeight
      levels
      structure
      options {
        evChargers
        toilets
        disabledAccess
        disablesPlaces
        motorcycleAccess
        motorcyclePlaces
        bicycleAccess
        bicyclePlaces
      }
      openingHours {
        startDate
        endDate
        daysOfWeek
        daysOfMonth
        months
        timeOfDayStart
        timeOfDayEnd
        designatedPeriod
        designatedPeriodExcept
      }
    }
  }
`;

export const QUERY_REMOVE_OFF_STREET_PARKING = gql`
  mutation removePrivateParking($parkingId: String!) {
    removePrivateParking(parkingId: $parkingId) {
      id
    }
  }
`;

export const QUERY_PUBLISH_MAP = gql`
  mutation PublishCitiesTiles {
    publishCitiesTiles {
      citiesBoundariesTilesetId
      citiesCurbZoneParkingTilesetId
    }
  }
`;
