import { gql } from "@apollo/client";

export const QUERY_PAYMENT_METERS = gql`
  query GetParkingMeters($cityId: String!) {
    getParkingMeters(cityId: $cityId) {
      id
      name
      address
      status
      externalId
      location
      cityId
      batteryLevel
      lastMaintenanceDate
      operator
      brand
      model
      zone
      options {
        cash
        creditCard
        contactless
      }
    }
  }
`;

export const QUERY_CREATE_PARKING_METER = gql`
  mutation createParkingMeter($createParkingMeterInput: CreateParkingMeterInput!) {
    createParkingMeter(createParkingMeterInput: $createParkingMeterInput) {
      id
      name
      address
      status
      externalId
      location
      cityId
      batteryLevel
      lastMaintenanceDate
      operator
      brand
      model
      zone
      options {
        cash
        creditCard
        contactless
      }
    }
  }
`;

export const QUERY_UPDATE_PARKING_METER = gql`
  mutation updateParkingMeter($updateParkingMeterInput: UpdateParkingMeterInput!) {
    updateParkingMeter(updateParkingMeterInput: $updateParkingMeterInput) {
      id
      name
      address
      status
      externalId
      location
      cityId
      batteryLevel
      lastMaintenanceDate
      operator
      brand
      model
      zone
      options {
        cash
        creditCard
        contactless
      }
    }
  }
`;

export const QUERY_REMOVE_PARKING_METER = gql`
  mutation removeParkingMeter($meterId: String!) {
    removeParkingMeter(meterId: $meterId) {
      id
    }
  }
`;
