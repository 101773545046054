import { Controller, useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { FieldContainer, DropdownMultiSelect, DropdownSelect } from "components/form-control";
import { getQueryParams, isNil } from "helper/utils";
import { useEffect, useMemo, useState } from "react";
import { Restriction } from "types";
import { InputNumber } from "components/form-control/input-number";
import { TimeSpanListing } from "components/time-span";
import { getPlace } from "helper/format-data";
import { useQueryDecreesListByCity } from "modules/disabled-curb-zone/hooks/use-query-all-decree";
import { sortDesc } from "helper/array";
import { DisabledCurbZoneParams, DisabledPartProps } from "../modes/disable-parts/disabled-part.type";
import { restrictionType } from "../restriction";
import { Input } from "@/components/ui/input";

export const RestrictionMultiplePrompt = ({ saveDisabledPart, prefill, cancel }: DisabledPartProps) => {
  const [selectedDecree, setSelectedDecree] = useState<string[]>(prefill?.decreeIds ?? []);
  const defaultWidth = prefill?.width ?? 2;
  const params = getQueryParams<DisabledCurbZoneParams>();

  const { decreeList } = useQueryDecreesListByCity(params?.cityID);

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Restriction>({
    defaultValues: { ...prefill, width: defaultWidth },
    mode: "all",
  });

  useEffect(() => {
    (async () => {
      if (!prefill?.streetName) {
        const newLineParking = await getPlace(prefill.editLine);
        setValue("streetName", newLineParking.properties.streetName);
        setValue("streetNumberStart", newLineParking.properties.streetNumberStart);
        setValue("streetNumberEnd", newLineParking.properties.streetNumberEnd);
      }
    })();
  }, [prefill, setValue]);

  const isEdit = !isNil(prefill.id);

  const listDecisionFile = useMemo((): any[] => {
    if (decreeList.length === 0) {
      return [];
    }
    return sortDesc([...decreeList], "name")
      .reverse()
      .map((item) => ({ value: item.id, label: item.name || "" }));
  }, [decreeList]);

  const dismiss = () => cancel(isEdit);
  const save = (promptedProperties: Restriction) => {
    saveDisabledPart({ ...promptedProperties });
  };

  const onChangeFile = (fileName: string[]) => {
    setValue("decreeIds", fileName);
    setSelectedDecree(fileName);
  };

  const headerText = useMemo(() => {
    return isEdit ? "Edit multiple restriction" : "Create new multiple restriction";
  }, [isEdit]);

  return (
    <Modal isOpen className="my-10 max-w-lg flex items-center content-center overflow-y-visible">
      <ModalContent className="w-128" title={headerText} description="Restriction information">
        <form onSubmit={handleSubmit(save)}>
          <Controller
            name="timeSpans"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Timespan is required",
              },

              validate: (values) => {
                if (values) {
                  for (const item of values) {
                    if (!item.startDate || !item.endDate) {
                      return "Start date and end date must be filled";
                    }
                  }
                }
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <TimeSpanListing
                  errors={errors}
                  name="timeSpans"
                  timeSpans={value}
                  setTimeSpans={onChange}
                  options={{ showSelectDate: true, showTimeOfDay: true }}
                />
              );
            }}
          />
          <FieldContainer
            helpText={errors.streetName ? "This is required" : undefined}
            status={errors.streetName ? "error" : "primary"}
          >
            <Controller
              name="streetName"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  label="Nom voie"
                  value={value}
                  onChange={onChange}
                  status={errors.streetName ? "error" : "primary"}
                />
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <DropdownMultiSelect
              options={listDecisionFile}
              values={selectedDecree}
              onChangeValues={onChangeFile}
              className="w-36"
              placeholder="Decrees"
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.description ? "This is required" : undefined}
            status={errors?.description ? "error" : "primary"}
          >
            <Input
              label="Description"
              {...register("description", { required: true })}
              isRequired
              status={errors.description ? "error" : "primary"}
              defaultValue={prefill?.description}
            />
          </FieldContainer>
          <FieldContainer>
            <DropdownSelect value={prefill?.type} options={restrictionType} {...register("type")} />
          </FieldContainer>
          <FieldContainer
            helpText={errors.width ? "This is required" : undefined}
            status={errors?.width ? "error" : "primary"}
          >
            <InputNumber
              label="Largeur"
              {...register("width", { valueAsNumber: true, required: true, min: 1 })}
              status={errors.width ? "error" : "primary"}
              defaultValue={defaultWidth}
            />
          </FieldContainer>
          <ModalFooter>
            <Button type="submit" variant="primary">
              Save
            </Button>
            <Button onClick={dismiss} variant="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
