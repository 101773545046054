import * as React from "react";
import { useMemo } from "react";
import { ViewportPlaceType } from "types/map.type";
import { useDebounce } from "hooks/lib-ui";
import { usePlacesQuery } from "services/web/map.queries";
import { addressPosition } from "services/web/mapbox.service";
import { isEmpty } from "helper/utils";
import { DropdownAutoCompleteSelect } from "@/components/form-control/dropdown-autocomplete";

interface ICoordinates {
  latitude: number;
  longitude: number;
}

type searchPlaceProps = {
  onSelect: (input: ViewportPlaceType | null) => void;
  coordinates?: ICoordinates;
};
export const SearchPlace = ({ onSelect, coordinates }: searchPlaceProps) => {
  const [value, setValue] = React.useState<string>("");
  const [position, setPosition] = React.useState<string>("");
  const searchString = useDebounce(value, 500);

  const handleSearch = (value: string) => {
    setValue(value);
    if (isEmpty(value)) {
      onSelect(null);
    }
  };
  const { data, isLoading } = usePlacesQuery({
    keyWord: searchString,
    coordinates: coordinates,
  });
  const listPlace = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((item: addressPosition) => {
        return {
          value: `${item.latitude}:${item.longitude}`,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  const handleSelectPlace = (value: string, label: string) => {
    const addressPoint = value.split(":");
    setPosition(value);
    onSelect({ longitude: Number(addressPoint[0]), latitude: Number(addressPoint[1]), name: label });
  };

  return (
    <div className="w-80 h-11 transition-opacity bg-default p-1 mt-16 rounded-md shadow-lg">
      <DropdownAutoCompleteSelect
        value={position}
        options={listPlace}
        onChangeValue={handleSelectPlace}
        status="default"
        handleSearch={handleSearch}
        searchString={value}
        placeholder="Search street..."
        size="small"
        className="w-full"
        isLoading={isLoading}
      />
    </div>
  );
};
