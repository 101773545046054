import { Dispatch, ReactNode, SetStateAction } from "react";
import { editorModeList } from "./editor-mode-list.const";
import { IconButton, Label, Tooltip } from "components";
import clsx from "clsx";
import { AlterModes, EditorModeType } from "./editor-mode-list.type";

type EditorModeListProps = {
  appMode: string;
  className?: string;
  alterMode: AlterModes | null;
  setAlterMode: Dispatch<SetStateAction<AlterModes | null>>;
};

export const EditorModeList = ({ appMode, className, setAlterMode, alterMode }: EditorModeListProps) => {
  const modes = editorModeList[appMode];

  const selectEditorMode = (input: EditorModeType) => {
    if (input.label === alterMode) {
      //If user click to the selected mode, it will remove that mode.
      setAlterMode(null);
    } else {
      setAlterMode(input.label);
    }
  };

  if (modes?.length) {
    return (
      <div
        className="origin-top-right p-1 w-13 rounded-md z-10"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div className={clsx("flex flex-col", className)}>
          <Label className="text-lg font-semibold font-sans first-letter:uppercase">Alter mode</Label>
          {modes?.map((item, idx) => (
            <EditorMode
              isSelected={item.label === alterMode}
              mode={item.label}
              icon={item.icon}
              label={item.label}
              onSelect={() => selectEditorMode(item)}
              key={`editor-mode-${idx}`}
            />
          ))}
        </div>
      </div>
    );
  }

  return null;
};

type EditorModeProps = {
  label: string;
  icon: ReactNode;
  onSelect: (mode: string) => void;
  className?: string;
  mode: string;
  isSelected: boolean;
};

const EditorMode = ({ mode, onSelect, className, label, icon, isSelected }: EditorModeProps) => {
  return (
    <div className="py-1" key={"toolbox-" + label}>
      <div className="rounded-md bg-primary w-10 h-10">
        <Tooltip label={label} pos="right" arrow>
          <IconButton
            onClick={() => onSelect(mode)}
            className={clsx(
              className,
              "border-transparent text-secondary rounded-md w-10 h-10 p-3 hover:bg-primary-active",
              isSelected ? "bg-primary-active" : "bg-primary",
            )}
          >
            {icon}
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
