import { Button } from "@/components/ui/button";
import { Confirm } from "components";
import { DataTable, TableBody, TableRow, TableCell } from "components/data-table";
import { formatDate } from "helper/date-time";
import { useQueryCurbArea } from "modules/curb-zone/hooks/use-query-curb-area";
import { Dispatch, SetStateAction, useState } from "react";
import { CurbArea } from "types/curb-area.type";

interface CurbAreaDetailProps {
  curbAreaDetail?: CurbArea;
  setOpenCurbAreaModal: Dispatch<SetStateAction<boolean>>;
  setCurbAreaDetail: (params: CurbArea | undefined) => void;
}
export const CurbAreaDetail = ({ curbAreaDetail, setOpenCurbAreaModal, setCurbAreaDetail }: CurbAreaDetailProps) => {
  const { handleRemoveCurbArea } = useQueryCurbArea();
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleEdit = () => setOpenCurbAreaModal(true);
  const handleDelete = async () => {
    if (curbAreaDetail?.id) {
      handleRemoveCurbArea({ id: curbAreaDetail.id });
      setOpenConfirm(false);
      setCurbAreaDetail(undefined);
    }
  };
  return (
    <>
      <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80">
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Name</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {curbAreaDetail?.name}
              </TableCell>
            </TableRow>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Note</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {curbAreaDetail?.note}
              </TableCell>
            </TableRow>
            {curbAreaDetail?.publishedDate && (
              <TableRow className="hover:none">
                <TableCell className="text-table-data text-secondary">Published Date</TableCell>
                <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                  {formatDate(curbAreaDetail.publishedDate, { format: "d MMM yyyy HH:mm" })}
                </TableCell>
              </TableRow>
            )}
            {curbAreaDetail?.lastUpdatedDate && (
              <TableRow className="hover:none">
                <TableCell className="text-table-data text-secondary">Published Date</TableCell>
                <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                  {formatDate(curbAreaDetail.lastUpdatedDate, { format: "d MMM yyyy HH:mm" })}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </DataTable>
        <div className="flex justify-between mt-4">
          <Button variant="error" onClick={() => setOpenConfirm(true)}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleEdit}>
            Edit
          </Button>
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete curb area"
          content="Are you sure that you want to delete this curb area?"
          onCancel={() => setOpenConfirm(false)}
          onOk={handleDelete}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
