import * as React from "react";
import { PolicyRuleInput } from "types";
import clsx from "clsx";
import { callAll, isNil } from "helper/utils";
import { FieldStatus } from "components/form-control/field.type";
import { InputButton } from "components/form-control";
import useToggle from "hooks/use-toggle";
import { AddRuleModal } from "./AddRuleModal";
import { InputButtonProps } from "components/form-control/input-button";

export interface RuleItemProps extends InputButtonProps {
  status?: FieldStatus;
  rule: PolicyRuleInput;
  onChangeValue: (timeSpan: PolicyRuleInput) => void;
  className?: string;
  name?: string;
  placeholder?: string;
}

export const RuleItem = ({ onChangeValue, rule, className, ...buttonProps }: RuleItemProps) => {
  const [showPopup, setShowPopup] = useToggle();

  const onApply = (input: PolicyRuleInput) => {
    onChangeValue(input);
    setShowPopup();
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <InputButton
          className={clsx(
            "border-solid border-primary focus-visible:outline-hidden focus:outline-hidden form-input flex items-center min-w-0 bg-transparent w-full h-full",
            className,
          )}
          {...buttonProps}
          onClick={callAll(buttonProps.onClick, setShowPopup)}
        >
          <div className="flex flex-col  text-sm font-medium">
            <span className="h-5 flex-1 truncate text-left">Activity: {rule.activity}</span>
            <span className="h-5 flex-1 truncate text-left">
              Stay: {displayValueUnit(rule.maxStay, rule.maxStayUnit)}
            </span>
            <span className="h-5 flex-1 truncate text-left">
              No return: {displayValueUnit(rule.noReturn, rule.noReturnUnit)}
            </span>
            <span className="h-5 flex-1 truncate text-left">User classes: {rule.userClasses?.join(", ")}</span>
            <span className="h-5 flex-1 truncate text-left">Rates:</span>
            {rule.rate?.map((item, idx) => (
              <div key={`rule-rate-${idx}`} className="flex flex-col items-start ml-2 text-sm font-medium">
                <div>rate: {item.rate + " " + item.rateUnit + " - " + (item.rateUnitPeriod || 0)}</div>
                <div>Increment duration: {item.incrementDuration}</div>
                <div>Increment amount: {item.incrementAmount}</div>
                <div>Duration: {(item.startDuration || 0) + " - " + (item.endDuration || 0)}</div>
              </div>
            ))}
          </div>
        </InputButton>
      </div>

      {showPopup && <AddRuleModal isOpen={showPopup} ruleData={rule} onApply={onApply} onDismiss={setShowPopup} />}
    </>
  );
};

const displayValueUnit = (value?: number, unit?: string) => {
  if (isNil(value)) {
    return "-";
  }
  if (value > 1) {
    return `${value} ${unit}s`;
  }
  return `${value} ${unit}`;
};
