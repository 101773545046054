import React, { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Confirm } from "components";
import {
  MapStyle,
  CurbZoneDetail,
  CurbZoneMultiDetail,
  SearchPlace,
  SelectMapType,
  SwitchModeView,
  UserReportListing,
  StreetView,
} from "./components";
import MapToolBox from "./map-tool-box";
import { ModeViewType, curbAreaMode, parkingLotMode, workAreaMode } from "constants/map.const";
import { CurbZoneMultiplePrompt } from "./modes/add-curb-zone/curb-zone-multiple-prompt";
import { CurbZonePropertiesPrompt } from "./modes/add-curb-zone/curb-zone-properties-prompt";
import { MapViewProps } from "./curb-zone.type";
import { useCurbZone } from "./hooks/use-curb-zone";
import { SearchFilterCurbZone } from "components/search-and-filter";
import { ModifyCurbAreaPrompt } from "./components/curb-area/modify-curb-area-prompt";
import { CurbAreaDetail } from "./components/curb-area/curb-area-detail";
import { useCurbArea } from "./hooks/use-curb-area";
import { useParkingLot } from "./hooks/use-parking-lot";
import { ModifyParkingLotPrompt } from "./components/parking-lot/modify-parking-lot-prompt";
import { ParkingLotDetail } from "./components/parking-lot/parking-lot-detail";
import { useUserReport } from "./hooks/use-user-report";
import Split from "react-split";
import { AppModeType, GetCursor, ViewportPlaceType } from "types";
import { cursorCell } from "./components/map-style/map-style.const";
import { EditorModeList } from "./components/editor-mode-list/editor-mode-list";
import { useWorkArea } from "./hooks/use-work-area";
import { ModifyWorkAreaPrompt } from "./components/work-area/modify-work-area-prompt";
import { WorkAreaDetail } from "./components/work-area/work-area-detail";

export const CurbZoneMap = ({ appMode, setAppMode }: MapViewProps) => {
  const isCurbZone = useMemo(() => {
    return !curbAreaMode.includes(appMode) && !parkingLotMode.includes(appMode);
  }, [appMode]);
  const {
    markerPosition,
    viewport,
    modeView,
    setModeView,
    selectAddress,
    mapType,
    setMapType,
    handleDragEnd,
    handleSelectCurbZone,
    handleZooming,
    hoverInfo,
    mapRef,
    onViewStateChange,
    openCurbZoneModal,
    addPropertiesAndSave,
    currentLineParkingList,
    curbZoneDetail,
    removeSelectedFeatures,
    currentCity,
    showCurbZoneDetail,
    openParkingModal,
    handleDeleteCurbZone,
    showCurbZoneMultiDetail,
    cancelEditMultiple,
    setOpenCurbZoneMultipleModal,
    openCurbZoneMultipleModal,
    handleEditMultipleCurbZone,
    preZooming,
    handleBulkDeleteCurbZones,
    isSpaceBarPressed,
    filter,
    changeFilter,
    curbZoneLayers,
    handleCopyCurbZone,
    isCopyMode,
    editableLayer,
    alterMode,
    setAlterMode,
    currentIndexes,
  } = useCurbZone({ appMode, isCurbZone });

  const {
    handleSelectUserReport,
    selectedUserReport,
    userReportList,
    isLoadingUserReports,
    wrapMapRef,
    userReportLayer,
    setUserReportParams,
    userReportParams,
    isOpenUserReport,
    setIsOpenUserReport,
  } = useUserReport();

  const {
    curbAreaEditionData,
    setCurbAreaEditionData,
    curbAreaDetail,
    setCurbAreaDetail,
    showCurbAreaDetail,
    openCurbAreaModal,
    setOpenCurbAreaModal,
    getCurbAreaLayers,
    cancelCurbAreaChange,
    handleSelectCurbArea,
    saveCurbAreaProperties,
  } = useCurbArea({ appMode });

  const {
    getParkingLotLayers,
    openParkingLotModal,
    parkingLotDetail,
    showParkingLotDetail,
    setParkingLotDetail,
    setOpenParkingLotModal,
    showWarning,
    resetEditParkingLotState,
    handleSelectParkingLot,
    saveParkingLotProperties,
    checkCurbZoneExistBeforeUpdate,
  } = useParkingLot({
    appMode,
  });

  const {
    workAreaEditionData,
    setWorkAreaEditionData,
    workAreaDetail,
    setWorkAreaDetail,
    showWorkAreaDetail,
    openWorkAreaModal,
    setOpenWorkAreaModal,
    getWorkAreaLayers,
    handleSelectWorkArea,
    saveWorkAreaProperties,
    resetDataOfEditState,
  } = useWorkArea({ appMode });

  const mergedLayers = [
    ...getCurbAreaLayers(),
    ...getParkingLotLayers(),
    ...getWorkAreaLayers(),
    ...curbZoneLayers,
    userReportLayer,
  ];

  const handleConfirmOk = () => resetEditParkingLotState();

  const [streetViewPosition, setStreetViewPosition] = useState<{ lat: number; lng: number }>({
    lat: viewport.latitude,
    lng: viewport.longitude,
  });
  const [newMarkerPosition, setNewMarkerPosition] = useState<ViewportPlaceType | null>(
    markerPosition ?? { latitude: viewport.latitude, longitude: viewport.longitude },
  );

  const isUserInitiatedUpdate = useRef(false); // Flag to track manual updates

  const handleSelectLayer = (info: any) => {
    if (curbAreaMode.includes(appMode)) {
      handleSelectCurbArea(info);
    }
    if (parkingLotMode.includes(appMode)) {
      handleSelectParkingLot(info);
    }
    if (workAreaMode.includes(appMode)) {
      handleSelectWorkArea(info);
    }
    handleSelectCurbZone(info);

    if (info.coordinate) {
      const position = { lat: info.coordinate[1], lng: info.coordinate[0] };

      isUserInitiatedUpdate.current = true;
      setNewMarkerPosition({ latitude: position.lat, longitude: position.lng });
      setStreetViewPosition(position);
    }
  };

  const getCursor = useCallback(() => {
    if (cursorCell.includes(appMode)) {
      return () => "cell";
    }
    if (["multiEdit", "edit"].includes(appMode)) {
      if (isSpaceBarPressed) {
        return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
      }
      return editableLayer.getCursor.bind(editableLayer) as GetCursor;
    }
    return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
  }, [isSpaceBarPressed, editableLayer, appMode]);

  //  update the marker when the street view position changes
  const onPositionChanged = (position: { lat: number; lng: number }) => {
    if (isUserInitiatedUpdate.current) {
      /*
      to prevent the marker from being updated a second time
      when the first marker update triggers a render that recalls
      this function to update the marker a second time
      */
      isUserInitiatedUpdate.current = false;
      return;
    }
    setNewMarkerPosition({ latitude: position.lat, longitude: position.lng });
  };

  useEffect(() => {
    if (currentCity?.position?.length === 2) {
      const [longitude, latitude] = currentCity.position;

      setNewMarkerPosition((prev) => {
        if (prev && prev.latitude === latitude && prev.longitude === longitude) return prev;
        return { latitude, longitude };
      });

      setStreetViewPosition((prev) => {
        if (prev.lat === latitude && prev.lng === longitude) return prev;
        return { lat: latitude, lng: longitude };
      });
    }
  }, [currentCity]);

  return (
    <div className="h-full top-0 w-full absolute">
      <Split className="split z-50 h-full" direction="vertical" minSize={0} sizes={[50, 50]}>
        <div className="relative overflow-hidden">
          <div className="relative h-full" role="none" ref={wrapMapRef}>
            <div className="absolute top-0 left-6 z-10 flex">
              <SearchPlace onSelect={selectAddress} coordinates={viewport} />
              <SelectMapType mapType={mapType} onSelectMapType={(value) => setMapType(value)} className="ml-2" />
              {currentCity && <SearchFilterCurbZone filter={filter} onChangeFilter={changeFilter} />}
            </div>
            <div>
              <MapStyle
                markerPosition={markerPosition ?? newMarkerPosition}
                mapType={mapType}
                modeView={modeView}
                layers={mergedLayers}
                handleDragEnd={handleDragEnd}
                handleSelectLayer={handleSelectLayer}
                handleZooming={(event) => handleZooming(event, preZooming.current)}
                hoverInfo={hoverInfo}
                mapRef={mapRef}
                onViewStateChange={onViewStateChange}
                viewport={viewport}
                appMode={appMode}
                getCursor={getCursor}
              />
            </div>

            {mapType === "Map box" && (
              <SwitchModeView modeView={modeView} selectModeView={(mode: ModeViewType) => setModeView(mode)} />
            )}
            {appMode === AppModeType.MultiEdit && (
              <div className="absolute p-4 bottom-2 left-24 ml-6 bg-default rounded-md shadow-lg">
                <p>Press SPACE bar to move the map.</p>
                <p>Press SHIFT button to select one curb-zone.</p>
                <p>Press ALT button to select multiple curb-zones.</p>
              </div>
            )}

            <div className="absolute top-36 left-8 z-5 gap-8">
              <MapToolBox appMode={appMode} setAppMode={setAppMode} />
            </div>
            <div className="absolute top-36 left-36 z-5 gap-8">
              {currentIndexes.length > 0 && (
                <EditorModeList appMode={appMode} alterMode={alterMode} setAlterMode={setAlterMode} />
              )}
            </div>

            <div className="absolute bottom-10 right-8 z-5">
              <Button onClick={() => setIsOpenUserReport()}>{isOpenUserReport ? "Hide" : "Show"} User Reports</Button>
            </div>

            {openCurbZoneModal && (
              <CurbZonePropertiesPrompt
                registerProperties={addPropertiesAndSave}
                prefill={(currentLineParkingList[0] || curbZoneDetail[0]) ?? undefined}
                id={currentLineParkingList[0]?.id as string}
                cancel={removeSelectedFeatures}
              />
            )}
            {currentCity
              ? showCurbZoneDetail && (
                  <CurbZoneDetail
                    cityId={currentCity.id}
                    curbZone={curbZoneDetail[0]?.properties}
                    resetState={removeSelectedFeatures}
                    openCurbZoneModal={openParkingModal}
                    handleRemoveCurbZone={handleDeleteCurbZone}
                    copyCurbZone={handleCopyCurbZone}
                    title={isCopyMode.current ? "Duplicated a Curb Zone" : null}
                  />
                )
              : null}
            {currentCity
              ? showCurbZoneMultiDetail && (
                  <CurbZoneMultiDetail
                    curbZones={curbZoneDetail}
                    resetState={cancelEditMultiple}
                    openCurbZoneMultiModal={setOpenCurbZoneMultipleModal}
                    handleRemoveMultiCurbZone={handleBulkDeleteCurbZones}
                  />
                )
              : null}

            {openCurbZoneMultipleModal && (
              <CurbZoneMultiplePrompt
                numberOfParking={curbZoneDetail?.length}
                cancel={cancelEditMultiple}
                handleEditMultipleCurbZone={handleEditMultipleCurbZone}
              />
            )}
            {openCurbAreaModal && (
              <ModifyCurbAreaPrompt
                appMode={appMode}
                saveCurbAreaProperties={saveCurbAreaProperties}
                setOpenCurbAreaModal={setOpenCurbAreaModal}
                curbAreaEditionData={curbAreaEditionData}
                setCurbAreaEditionData={setCurbAreaEditionData}
                curbAreaDetail={curbAreaDetail}
                cancelCurbAreaChange={cancelCurbAreaChange}
              />
            )}
            {openWorkAreaModal && (
              <ModifyWorkAreaPrompt
                appMode={appMode}
                saveWorkAreaProperties={saveWorkAreaProperties}
                setOpenWorkAreaModal={setOpenWorkAreaModal}
                workAreaEditionData={workAreaEditionData}
                setWorkAreaEditionData={setWorkAreaEditionData}
                workAreaDetail={workAreaDetail}
                cancelWorkAreaChange={resetDataOfEditState}
              />
            )}
            {workAreaDetail && showWorkAreaDetail && (
              <WorkAreaDetail
                workAreaDetail={workAreaDetail}
                setWorkAreaDetail={setWorkAreaDetail}
                setOpenWorkAreaModal={setOpenWorkAreaModal}
              />
            )}
            {curbAreaDetail && showCurbAreaDetail && (
              <CurbAreaDetail
                curbAreaDetail={curbAreaDetail}
                setCurbAreaDetail={setCurbAreaDetail}
                setOpenCurbAreaModal={setOpenCurbAreaModal}
              />
            )}
            {openParkingLotModal && (
              <ModifyParkingLotPrompt
                saveParkingLotProperties={saveParkingLotProperties}
                setOpenParkingLotModal={setOpenParkingLotModal}
                currentCity={currentCity}
                resetEditState={resetEditParkingLotState}
                parkingLotDetail={parkingLotDetail}
              />
            )}
            {parkingLotDetail && showParkingLotDetail && (
              <ParkingLotDetail
                parkingLotDetail={parkingLotDetail}
                setParkingLotDetail={setParkingLotDetail}
                checkCurbZoneExistBeforeUpdate={checkCurbZoneExistBeforeUpdate}
              />
            )}
            {showWarning && <Confirm title="Warning" content="Have not any curb zone" onOk={handleConfirmOk} />}
          </div>
        </div>
        <div className="overflow-y-auto z-40">
          {!isOpenUserReport ? (
            <StreetView position={streetViewPosition} onPositionChanged={onPositionChanged} />
          ) : (
            <UserReportListing
              isLoading={isLoadingUserReports}
              userReportList={userReportList}
              onSelectUserReport={handleSelectUserReport}
              selectedUserReport={selectedUserReport}
              userReportParams={userReportParams}
              setUserReportParams={setUserReportParams}
            />
          )}
        </div>
      </Split>
    </div>
  );
};
