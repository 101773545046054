import { useDebounce } from "@/hooks/lib-ui";
import { CityContext } from "@/contexts/city-context-provider";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import {
  QUERY_BULK_UPDATE_CURB_ZONE,
  QUERY_CREATE_CURB_ZONE,
  QUERY_CURB_ZONES,
  QUERY_REMOVE_CURB_ZONE,
  QUERY_UPDATE_CURB_ZONE,
  QUERY_REMOVE_CURB_ZONES,
} from "services/web/api-curb-zone.const";
import {
  BulkDeleteCurbZonesResponse,
  CreateCurbZoneResponse,
  MultiCurbZoneResponse,
  RemoveCurbZoneResponse,
  UpdateCurbZoneResponse,
  CurbZonesResponse,
} from "services/web/api-curb-zone.type";
import { useSearchFilterCurbZone } from "@/hooks/use-search-filter-curb-zone";
import { hideGlobalLoading, showGlobalLoading } from "@/hooks/use-global-loading";

export const useQueryCurbZone = () => {
  const { currentCity } = useContext(CityContext);
  const [isResetData, setIsResetData] = useState<boolean>(false);
  const [bounding, setBounding] = useState<number[][]>();
  const nearBy = useDebounce(bounding, 500);
  const { filter, changeFilter } = useSearchFilterCurbZone();

  const variables = useMemo(() => {
    return {
      filterInput: {
        cityId: currentCity?.id || "",
        boundingbox: nearBy,
        policies: filter.selectedPolicies.length > 0 ? filter.selectedPolicies : undefined,
      },
    };
    /***
     * Nearby will change by city
     * **/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearBy, filter.selectedPolicies]);

  const { data, loading } = useQuery<CurbZonesResponse>(QUERY_CURB_ZONES, {
    variables,
    skip: !currentCity?.id || !nearBy,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const curbZones = useMemo(() => {
    return data?.curbZones || [];
  }, [data]);

  const resetState = () => {
    setIsResetData(true);
  };

  const [createCurbZone] = useMutation<CreateCurbZoneResponse>(QUERY_CREATE_CURB_ZONE, {
    onError: resetState,
  });

  const [updateCurbZone] = useMutation<UpdateCurbZoneResponse>(QUERY_UPDATE_CURB_ZONE, {
    onError: resetState,
  });

  const [removeCurbZone] = useMutation<RemoveCurbZoneResponse>(QUERY_REMOVE_CURB_ZONE, {
    onError: resetState,
  });
  const [bulkDeleteCurbZones] = useMutation<BulkDeleteCurbZonesResponse>(QUERY_REMOVE_CURB_ZONES, {
    onError: resetState,
  });

  const [updateMultiCurbZones] = useMutation<MultiCurbZoneResponse>(QUERY_BULK_UPDATE_CURB_ZONE, {
    onError: resetState,
  });

  return {
    nearBy,
    setBounding,
    curbZones,
    loading,
    createCurbZone,
    updateCurbZone,
    removeCurbZone,
    updateMultiCurbZones,
    isResetData,
    setIsResetData,
    bulkDeleteCurbZones,
    filter,
    changeFilter,
  };
};
