import { ReactNode, useState, forwardRef, ComponentProps, useEffect } from "react";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { Label } from "components/label";
import { callAll, isEmpty } from "helper/utils";
import { textStatus } from "components/form-control/field.type";
import { hasClassName } from "@/helper/check-class";

export type InputProps = ComponentProps<"input"> & {
  className?: string;
  type?: string;
  label?: string;
  isRequired?: boolean;
  classLabel?: string;
  iconStart?: ReactNode;
  onChangeValue?: (value: string) => void;
} & VariantProps<typeof inputStatus>;

const inputStatus = cva("block py-2.5 text-sm focus-visible:outline-hidden z-1 bg-transparent border-0", {
  variants: {
    status: {
      error: "text-red-500 border-red-500",
      warning: "text-yellow-500 border-yellow-500",
      success: "text-green-500 border-green-500",
      default: "text-gray-600",
      primary: "border-primary",
    },
  },
  defaultVariants: {
    status: "primary",
  },
});

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      status = "primary",
      label,
      isRequired,
      readOnly,
      iconStart,
      onChangeValue,
      classLabel,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(Boolean(props.value ?? props.defaultValue));
    const getLabelClass = cn(
      classLabel,
      "text-sm",
      isFocused ? "form-label-focus" : "form-label-nonfocus",
      textStatus[status ?? "primary"],
      readOnly && "text-disabled",
    );
    const handleFocus = () => {
      setIsFocused(true);
    };
    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!e.target.value) {
        setIsFocused(false);
      } else if (!isFocused) {
        setIsFocused(true);
      }
    };

    useEffect(() => {
      if (!isFocused && !isEmpty(props.defaultValue)) {
        setIsFocused(true);
      }
    }, [props.defaultValue, isFocused]);

    return (
      <div
        className={cn(
          "relative w-full border-primary",
          !hasClassName(className, "border-none") && "border-b",
          props.disabled ? "border-dotted text-disabled" : "border-solid",
          className,
        )}
      >
        {label && (
          <Label htmlFor={props.name ?? ""} className={getLabelClass}>
            {label}
            {isRequired && <span className="text-red-500">*</span>}
          </Label>
        )}
        <div className="flex items-center">
          {iconStart && <div className="mr-3">{iconStart}</div>}
          <input
            ref={ref}
            type={type}
            {...props}
            className={cn(className, inputStatus({ status }), "w-full")}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              if (onChangeValue) {
                onChangeValue(e.target.value);
              }
            }}
            onFocus={callAll(props.onFocus, handleFocus)}
            onBlur={callAll(props.onBlur, handleBlur)}
          />
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
