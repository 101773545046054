import clsx from "clsx";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { hasClassName } from "helper/check-class";
import { twMerge } from "tailwind-merge";
import { Dialog } from "components/ui/dialog";

export interface IDialogProps extends ComponentPropsWithoutRef<"div"> {
  className?: string;
  overlayClassName?: string;
  wrapperContentClassName?: string;
  children: ReactNode;
  isOpen: boolean;
}

export const Modal = ({ isOpen = false, className, wrapperContentClassName, children }: IDialogProps) => {
  return (
    <Dialog open={isOpen}>
      <div className={twMerge("fixed inset-0", wrapperContentClassName)}>
        <div className={clsx("flex justify-center mx-auto", hasClassName(className, "my-") ? className : "my-10vh")}>
          {children}
        </div>
      </div>
    </Dialog>
  );
};
