import { AddIcon, CurbAreaIcon, EditModeIcon, EditMultiModeIcon, ViewIcon } from "components/icons";
import { EditCurbAreaIcon } from "components/icons/edit-curb-area";

/**
 * The icons to be displayed in the toolbox, depending on mode.
 */
export const toolboxIcons = [
  {
    name: "Curb Zone",
    groups: [
      {
        text: "View",
        mode: "view",
        icon: <ViewIcon className="h-5 w-5" />,
      },
      {
        text: "Add",
        mode: "add",
        icon: <AddIcon className="h-5 w-5" />,
      },
      {
        text: "Edit",
        mode: "edit",
        icon: <EditModeIcon className="h-5 w-5" />,
      },
      {
        text: "Multiple Edit",
        mode: "multiEdit",
        icon: <EditMultiModeIcon className="h-5 w-5" />,
      },
    ],
  },
  {
    name: "Curb Area",
    groups: [
      {
        text: "Add curb area",
        mode: "addCurbArea",
        icon: <CurbAreaIcon className="h-5 w-5" />,
      },
      {
        text: "Edit curb area",
        mode: "editCurbArea",
        icon: <EditCurbAreaIcon className="h-5 w-5" />,
      },
    ],
  },
  {
    name: "Parking Lot",
    groups: [
      {
        text: "Add parking lot",
        mode: "addParkingLot",
        icon: <CurbAreaIcon className="h-5 w-5" />,
      },
      {
        text: "Edit parking lot",
        mode: "editParkingLot",
        icon: <EditCurbAreaIcon className="h-5 w-5" />,
      },
    ],
  },
  {
    name: "Work Area",
    groups: [
      {
        text: "Add work area",
        mode: "addWorkArea",
        icon: <CurbAreaIcon className="h-5 w-5" />,
      },
      {
        text: "Edit work area",
        mode: "editWorkArea",
        icon: <EditCurbAreaIcon className="h-5 w-5" />,
      },
    ],
  },
];
