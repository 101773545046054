import { useCallback, useContext, useState } from "react";

import { PenIcon } from "components/icons";
import { Confirm } from "components";
import { IconButton, Tooltip } from "components";
import { CityContext } from "contexts/city-context-provider";

interface MapToolBoxProps {
  onClickAdd: () => void;
  disabled: boolean;
}

export const MapToolBox = ({ onClickAdd, disabled }: MapToolBoxProps) => {
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const { currentCity } = useContext(CityContext);
  const handleClickAdd = () => {
    if (!currentCity) {
      setOpenWarning(true);
      return;
    }
    onClickAdd();
  };
  const handleCloseConfirm = useCallback(() => {
    setOpenWarning(false);
  }, []);

  return (
    <div
      className="origin-top-right p-1 rounded-md z-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="py-1">
        <div className="rounded-md bg-primary">
          <Tooltip label="Add" pos="right" arrow>
            <IconButton
              disabled={disabled}
              className="border-transparent text-secondary rounded-md w-10 h-10 hover:bg-primary-active disabled:bg-primary-active bg-primary"
              onClick={handleClickAdd}
            >
              <PenIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {openWarning && (
        <Confirm title="Warning" content="Please select a city" onOk={handleCloseConfirm} butOkName="Close" />
      )}
    </div>
  );
};
