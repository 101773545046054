import { useMutation, useQuery } from "@apollo/client";
import { CityContext } from "contexts/city-context-provider";
import { hideGlobalLoading, showGlobalLoading } from "hooks/use-global-loading";
import { useContext, useEffect } from "react";
import {
  QUERY_PAYMENT_METERS,
  QUERY_CREATE_PARKING_METER,
  QUERY_UPDATE_PARKING_METER,
  QUERY_REMOVE_PARKING_METER,
} from "services/web/api-parking-meter.const";
import {
  CreateParkingMeterResponse,
  UpdateParkingMeterResponse,
  RemoveParkingMeterResponse,
  ParkingMetersResponse,
} from "services/web/api-parking-meter.type";

export const useQueryParkingMeter = () => {
  const { currentCity } = useContext(CityContext);

  const variables = {
    cityId: currentCity?.id,
  };

  const { data, loading, refetch } = useQuery<ParkingMetersResponse>(QUERY_PAYMENT_METERS, {
    variables,
    skip: !variables.cityId,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const [createParkingMeter] = useMutation<CreateParkingMeterResponse>(QUERY_CREATE_PARKING_METER, {
    onCompleted: refetch,
    onError: refetch,
  });

  const [updateParkingMeter] = useMutation<UpdateParkingMeterResponse>(QUERY_UPDATE_PARKING_METER, {
    onCompleted: refetch,
    onError: refetch,
  });

  const [removeParkingMeter] = useMutation<RemoveParkingMeterResponse>(QUERY_REMOVE_PARKING_METER, {
    onCompleted: refetch,
    onError: refetch,
  });

  return {
    parkingMeters: data?.getParkingMeters ?? [],
    loading,
    createParkingMeter,
    updateParkingMeter,
    removeParkingMeter,
    refetch,
  };
};
