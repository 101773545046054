import { Button } from "@/components/ui/button";
import { ModalFooter } from "components/modal";

interface AddCityActionsProps {
  onDismiss: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting?: boolean;
}
export const AddCityActions = ({ onDismiss, isSubmitting }: AddCityActionsProps) => {
  return (
    <ModalFooter align="right" className="bg-white px-0 md:px-0 lg:px-0 w-full">
      <Button className="bg-default border-none text-primary " type="submit" onClick={() => onDismiss(false)}>
        Cancel
      </Button>
      <Button disabled={isSubmitting} variant="primary" type="submit">
        Save
      </Button>
    </ModalFooter>
  );
};
