import { useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Label } from "components/label";
import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { FieldContainer, Checkbox, CalendarSelector, DropdownAutoCompleteSelect } from "components/form-control";
import { InputNumber } from "components/form-control/input-number";
import { useDebounce } from "hooks/lib-ui";
import { usePlacesQuery } from "services/web/map.queries";
import useToggle from "hooks/use-toggle";
import { ArrowIcon, EditIcon } from "components/icons";
import clsx from "clsx";
import { noop, isNil } from "helper/utils";
import { ParkingMeter } from "services/web/api-parking-meter.type";
import { Point } from "geojson";
import { ParkingMeterModalState } from "../parking-payment.const";
import { Input } from "components/ui/input";
interface ICoordinates {
  latitude: number;
  longitude: number;
}

interface ParkingMeterModalProps {
  coordinates?: ICoordinates;
  saveParkingMeter: (data: ParkingMeter) => Promise<void>;
  parkingMeter: ParkingMeter;
  cancel: (isEdit: boolean) => void;
  parkingModalState: keyof typeof ParkingMeterModalState;
  setParkingModalState: React.Dispatch<React.SetStateAction<keyof typeof ParkingMeterModalState>>;
  setParkingMeterDetail: (value: ParkingMeter | null) => void;
}

export const ParkingMeterModal = ({
  saveParkingMeter,
  parkingMeter,
  cancel,
  setParkingModalState,
  parkingModalState,
  setParkingMeterDetail,
  coordinates,
}: ParkingMeterModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ParkingMeter>({
    defaultValues: parkingMeter,
    mode: "all",
  });

  const [toggleMoreOptions, setToggleMoreOptions] = useToggle(false);
  const [queryAddress, setQueryAddress] = useState<string>(parkingMeter?.address || "");
  const [otherAddresses, setOtherAddresses] = useState<string[]>([]);
  const [selectedIndexOtherAddress, setSelectedIndexOtherAddress] = useState<number | undefined>();

  const currentAddress = useMemo(() => {
    if (typeof selectedIndexOtherAddress === "number") {
      return otherAddresses[selectedIndexOtherAddress];
    }
    return queryAddress;
  }, [queryAddress, selectedIndexOtherAddress, otherAddresses]);

  const searchString = useDebounce(currentAddress);

  const handleSearch = (value: string) => {
    setQueryAddress(value);
  };

  const handleMinimizeModal = () => {
    const rootDocument = document.getElementById("root");
    rootDocument?.removeAttribute("inert");
    setParkingModalState(ParkingMeterModalState.MINIMIZE);
  };

  const { data, isLoading } = usePlacesQuery({
    keyWord: searchString,
    coordinates: coordinates,
  });

  const listPlace = useMemo(() => {
    if (data && data.length > 0) {
      return data.map((item: any) => {
        return {
          value: `${item.latitude}:${item.longitude}`,
          label: item.name,
        };
      });
    }
    return [];
  }, [data]);

  const handleSelectPlace = (value: string, label: string) => {
    setQueryAddress(label);
    const addressPoint = value.split(":");
    const location: Point = { type: "Point", coordinates: [Number(addressPoint[0]), Number(addressPoint[1])] };
    setParkingMeterDetail({
      ...parkingMeter,
      location: location,
    });
    setValue("location", location);
  };

  const isEdit = !isNil(parkingMeter?.id);

  const dismiss = () => cancel(isEdit);
  const save = async (data: ParkingMeter) => {
    const formattedData = {
      ...data,
      location: parkingMeter?.location,
      batteryLevel: Number(data.batteryLevel),
    };
    await saveParkingMeter(formattedData);
    setOtherAddresses([]);
  };

  const handleShowModal = () => {
    setParkingModalState(ParkingMeterModalState.SHOW);
  };

  const modalMinimize = parkingModalState === ParkingMeterModalState.MINIMIZE;

  return (
    <Modal
      isOpen
      className={modalMinimize ? "my-0" : "my-10 overflow-hidden"}
      overlayClassName={modalMinimize ? "hidden" : "block"}
      wrapperContentClassName={
        modalMinimize
          ? "inset-auto absolute bottom-12 right-4 w-fit px-2 py-1 rounded-md shadow-md bg-white animate-bounce"
          : "fixed inset-0"
      }
    >
      <form onSubmit={handleSubmit(save)}>
        <div
          role="button"
          tabIndex={0}
          className={clsx(
            "flex gap-2 items-center text-sm font-medium cursor-pointer",
            modalMinimize ? "block" : "hidden",
          )}
          onKeyDown={noop}
          onClick={handleShowModal}
        >
          <span>Back to edit parking meter</span>
          <ArrowIcon className="w-3 h-3 rotate-90" />
        </div>
        <div className={modalMinimize ? "hidden" : "block"}>
          <ModalContent
            className="w-128 h-[calc(100vh-5em)] rounded-2xl overflow-auto px-3 pb-0 lg:px-5"
            title={`${parkingMeter?.id ? "Edit " : "Add "}a parking meter`}
            description="Parking Meter information"
          >
            <FieldContainer
              helpText={errors.name ? "This is required" : undefined}
              status={errors.name ? "error" : "primary"}
            >
              <Input
                {...register("name", { required: true })}
                isRequired
                label="Name"
                status={errors.name ? "error" : "primary"}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.address ? "This is required" : undefined}
              status={errors.address ? "error" : "primary"}
              className="mb-2"
            >
              <DropdownAutoCompleteSelect
                panelClassName="shadow-md absolute z-50"
                label="Address"
                {...register("address", { required: true })}
                isRequired
                onChangeValue={handleSelectPlace}
                value={queryAddress}
                handleSearch={handleSearch}
                options={listPlace}
                isLoading={isLoading}
                status={errors.address ? "error" : "primary"}
                onClick={() => setSelectedIndexOtherAddress(undefined)}
              />
            </FieldContainer>
            <FieldContainer className="flex gap-2">
              <Label className="text-base font-semibold">
                Location:{" "}
                {parkingMeter?.location && (
                  <span
                    role="button"
                    tabIndex={0}
                    className="text-blue-500 font-medium cursor-pointer"
                    onKeyDown={noop}
                    onClick={handleMinimizeModal}
                  >
                    {parkingMeter?.location.coordinates.toString()}
                  </span>
                )}
              </Label>
              <EditIcon className="w-5 h-5 cursor-pointer fill-black" onClick={handleMinimizeModal} />
            </FieldContainer>

            <FieldContainer>
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange } }) => <Input label="Status" value={value} onChange={onChange} />}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                name="externalId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input label="External ID" value={value} onChange={onChange} />
                )}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.operator ? "This is required" : undefined}
              status={errors.operator ? "error" : "primary"}
            >
              <Input
                {...register("operator", { required: true })}
                label="Operator"
                isRequired
                status={errors.operator ? "error" : "primary"}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.brand ? "This is required" : undefined}
              status={errors.brand ? "error" : "primary"}
            >
              <Input
                {...register("brand", { required: true })}
                isRequired
                label="Brand"
                status={errors.brand ? "error" : "primary"}
              />
            </FieldContainer>
            <FieldContainer
              helpText={errors.model ? "This is required" : undefined}
              status={errors.model ? "error" : "primary"}
            >
              <Input
                {...register("model", { required: true })}
                isRequired
                label="Model"
                status={errors.model ? "error" : "primary"}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                name="zone"
                control={control}
                render={({ field: { value, onChange } }) => <Input label="Zone" value={value} onChange={onChange} />}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                name="batteryLevel"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <InputNumber label="Battery Level" value={value} onChange={onChange} min={0} />
                )}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                name="lastMaintenanceDate"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CalendarSelector label="Last Maintenance Date" selectedDate={value} onChangeValue={onChange} />
                )}
              />
            </FieldContainer>
            {toggleMoreOptions && (
              <div className="transition-all duration-500 overflow-hidden">
                <FieldContainer className="mb-2">
                  <Checkbox {...register("options.cash")} defaultChecked={parkingMeter?.options?.cash} label="Cash" />
                  <Checkbox
                    {...register("options.creditCard")}
                    label="Credit card"
                    defaultChecked={parkingMeter?.options?.creditCard}
                  />
                  <Checkbox
                    {...register("options.contactless")}
                    label="Contact less"
                    defaultChecked={parkingMeter?.options?.contactless}
                  />
                </FieldContainer>
              </div>
            )}
            <div
              className="flex gap-1 items-center"
              role="button"
              tabIndex={0}
              onKeyDown={noop}
              onClick={setToggleMoreOptions}
            >
              <span className="text-sm text-gray-900 cursor-pointer">
                {toggleMoreOptions ? "Hide" : "More options"}
              </span>
              <ArrowIcon
                className={clsx("transform", toggleMoreOptions ? "rotate-90" : "-rotate-90")}
                width={10}
                height={10}
              />
            </div>
            <ModalFooter>
              <Button disabled={isSubmitting} type="submit" variant="primary">
                Save
              </Button>
              <Button onClick={dismiss} variant="secondary">
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </div>
      </form>
    </Modal>
  );
};
