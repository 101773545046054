import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { RgbaColor, RgbaColorPicker, HexColorInput } from "react-colorful";
import clsx from "clsx";

import { convertHexToRgba, convertRgbaToHex, formatRGBAColorToString } from "helper/utils";
import { PresetColorByPolicy } from "modules/policies/policies.const";
import { Tooltip } from "./tooltip";

interface ColorPickerProps {
  color: RgbaColor;
  onChange: (color: RgbaColor) => void;
  className?: string;
  popoverClassName?: string;
  presetColors?: PresetColorByPolicy[];
}

export const ColorPicker = ({ color, onChange, className, popoverClassName, presetColors }: ColorPickerProps) => {
  const handleSelectColor = (value: RgbaColor) => {
    onChange(value);
  };
  const handleInputHexColor = (newColor: string) => {
    onChange(convertHexToRgba(newColor));
  };

  return (
    <Popover>
      <PopoverTrigger
        className={clsx("relative w-20 h-8 text-sm border border-primary rounded-sm", className)}
        style={{ backgroundColor: formatRGBAColorToString(color) }}
      >
        {!color}
      </PopoverTrigger>

      <PopoverContent className={clsx("absolute bg-default", popoverClassName)} side="right">
        <div className="relative flex flex-col shadow-sm pr-2 pb-4 rounded-sm">
          <div className="flex">
            <RgbaColorPicker color={color} onChange={handleSelectColor} />
            <div className="grid grid-cols-3 gap-2 w-20 py-2 pl-3">
              {presetColors?.map((preset) => (
                <Tooltip label={preset.title} offset={0} key={`color-picker-${preset.title}`}>
                  <button
                    className="w-4 h-4 rounded-sm border border-primary"
                    style={{ backgroundColor: formatRGBAColorToString(preset.rgba) }}
                    onClick={() => handleSelectColor(preset.rgba)}
                  />
                </Tooltip>
              ))}
            </div>
          </div>

          <div className="ml-5 mt-2">
            <HexColorInput
              className="text-center"
              alpha
              prefixed
              color={convertRgbaToHex(color)}
              onChange={handleInputHexColor}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
