import { IOffStreetParking } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDownIcon } from "components/icons";
import { Button } from "@/components/ui/button";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.

export const columns: ColumnDef<IOffStreetParking>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          className="text-[#8E8E96] ml-[-10px] rounded-md"
          variant="primary"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Nom
          <ArrowUpDownIcon />
        </Button>
      );
    },
  },
];
