import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "@/components/ui/menubar";

import { ImageLazyLoading } from "../image-lazy-loading";
import { UserIcon } from "../icons";
import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout } from "store/hooks";

export const UserView = () => {
  const { user } = useAuth0();
  const logout = useLogout();
  const client = useApolloClient();

  const handleLogout = async () => {
    await client.resetStore();
    await logout();
  };

  return (
    <div className="inline-flex p-13px">
      <Menubar className="bg-transparent border-none shadow-none">
        <MenubarMenu>
          <MenubarTrigger className="data-[state=open]:bg-transparent focus:bg-transparent">
            <UserIcon className="w-6 h-6" />
          </MenubarTrigger>
          <MenubarContent
            className="
           shadow-lg ring-1 ring-black/5 focus:outline-hidden z-20 w-60 bg-primary text-center rounded-b-2xl"
          >
            <div className="flex justify-center px-1 py-2">
              <ImageLazyLoading
                src={user?.picture}
                className="space-y-2 w-74 h-74 flex items-center rounded-full"
                alt="avatar"
              />
            </div>
            <MenubarSeparator />
            <div className="flex justify-center px-1 py-2">
              <span className="py-2 text-white text-xs">{user?.name}</span>
            </div>
            <MenubarItem className="flex justify-center px-1 py-2">
              <button className="text-white hover:text-red-700 text-medium" onClick={handleLogout}>
                Logout
              </button>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </div>
  );
};
