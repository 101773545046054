import { Feature, FeatureCollection, LineString } from "geojson";
import { getPlace } from "./format-data";
import { cloneDeep } from "lodash";
import { CurbZone } from "../types/curb-zone.type";
import { IconLayer } from "deck.gl";
import { MARKER_MAPPING } from "@/modules/offstreet-parking/off-street-parking.const";
import { isEmpty, isNil } from "./utils";
import { findObject } from "./array";

export const copyLineString = async (input: Feature<LineString, CurbZone>) => {
  const copiedCurbZone = cloneDeep(input);
  const newCoordinates = copiedCurbZone.geometry.coordinates.map((item) => [item[0] + 0.00002, item[1] - 0.00005]);
  copiedCurbZone.geometry = {
    ...copiedCurbZone.geometry,
    coordinates: newCoordinates,
  };
  copiedCurbZone.properties.geometry.coordinates = copiedCurbZone.properties.geometry.coordinates.map((item) => {
    return item.map((point) => [point[0] + 0.00002, point[1] - 0.00005]);
  });
  const convertCurbZone = await getPlace(copiedCurbZone);
  return {
    ...convertCurbZone,
    id: undefined,
    properties: {
      ...convertCurbZone.properties,
      id: undefined,
      curbZoneId: undefined,
    },
  };
};

export const createMarkerLayer = (point: { coordinates: [number, number] }) => {
  return new IconLayer({
    id: "icon-layer",
    data: [point],
    pickable: true,
    iconAtlas: "images/maker.png",
    iconMapping: MARKER_MAPPING,
    getIcon: () => "marker",
    sizeScale: 8,
    getPosition: (d: any) => d.coordinates,
    getSize: () => 3,
  });
};

export const filterFeatureIndex = <T extends Feature>(editionData: FeatureCollection, selectedFeature?: T | T[]) => {
  if (isEmpty(selectedFeature) || isNil(selectedFeature)) return [];
  const indexes: number[] = [];
  if (Array.isArray(selectedFeature)) {
    selectedFeature.forEach((item) => {
      if (!isNil(item) && item.id) {
        const fIndex = findObject(editionData.features, "id", item.id);
        if (fIndex > -1) {
          indexes.push(fIndex);
        }
      }
    });
  } else if (selectedFeature && selectedFeature.id) {
    const fIndex = findObject(editionData.features, "id", selectedFeature.id);
    if (fIndex > -1) {
      indexes.push(fIndex);
    }
  }
  return indexes;
};

export const filterLineStringFromPoint = (infos: any) => {
  if (infos && infos.length > 0) {
    const parkings = infos.reduce((filteredArray: any[], info: any) => {
      if (info && info.object.id && info.object.geometry.type === "LineString") {
        filteredArray.push(info.object);
      }
      return filteredArray;
    }, []);
    return parkings;
  }
  return [];
};
