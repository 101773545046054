import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components/modal";
import { CalendarSelector, FieldContainer, Textarea } from "components/form-control";
import { useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { HintText } from "components/hint-text";
import { FeatureCollection } from "geojson";
import { CurbArea } from "types/curb-area.type";
import { AppMode, AppModeType } from "types";
import { Input } from "@/components/ui/input";

interface ModifyCurbAreaPromptProps {
  appMode: AppMode;
  setOpenCurbAreaModal: VoidFunction;
  setCurbAreaEditionData: Dispatch<SetStateAction<FeatureCollection>>;
  curbAreaEditionData: FeatureCollection;
  curbAreaDetail?: CurbArea | null;
  saveCurbAreaProperties: (promptedProperties: CurbAreaProperties) => Promise<void>;
  cancelCurbAreaChange: VoidFunction;
}

export type CurbAreaProperties = {
  name: string;
  note: string;
  cityId: string;
  publishedDate?: string;
};

export const ModifyCurbAreaPrompt = ({
  appMode,
  curbAreaDetail,
  setOpenCurbAreaModal,
  curbAreaEditionData,
  setCurbAreaEditionData,
  cancelCurbAreaChange,
  saveCurbAreaProperties,
}: ModifyCurbAreaPromptProps) => {
  const [publishedDate, setPublishedDate] = useState<Date>(new Date());
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CurbAreaProperties>({
    mode: "onChange",
  });
  const submit = async (formValues: CurbAreaProperties) => {
    await saveCurbAreaProperties({ ...formValues, publishedDate: publishedDate.toISOString() });
    reset();
  };
  const handleClose = () => {
    if (appMode === AppModeType.AddCurbArea) {
      setCurbAreaEditionData({
        ...curbAreaEditionData,
        features: curbAreaEditionData.features.slice(0, -1),
      });
    }
    cancelCurbAreaChange();
    setOpenCurbAreaModal();
    reset();
  };

  useEffect(() => {
    if (appMode === AppModeType.EditCurbArea && curbAreaDetail && !!Object.keys(curbAreaDetail).length) {
      setValue("name", String(curbAreaDetail.name));
      setValue("note", String(curbAreaDetail.note));
      setPublishedDate(new Date(curbAreaDetail?.publishedDate || new Date()));
    }
  }, [appMode, curbAreaDetail, setValue]);

  return (
    <Modal isOpen>
      <ModalContent title={`${appMode === AppModeType.AddCurbArea ? "Edit" : "Add new"} a curb area`}>
        <form onSubmit={handleSubmit(submit)}>
          <FieldContainer className="mt-8">
            <Input
              {...register("name", {
                required: "This field is required",
              })}
              defaultValue={curbAreaDetail?.name}
              label="Name *"
              disabled={isSubmitting}
            />
            {errors.name && <HintText status="error">{errors.name.message}</HintText>}
          </FieldContainer>
          <FieldContainer className="mt-8">
            <Textarea disabled={isSubmitting} {...register("note")} label="Note" defaultValue={curbAreaDetail?.note} />
          </FieldContainer>
          <FieldContainer>
            <CalendarSelector
              label="Published date"
              selectedDate={publishedDate}
              disabled={isSubmitting}
              onChangeValue={(newDate) => setPublishedDate(newDate)}
            />
          </FieldContainer>
          <ModalFooter>
            <Button disabled={isSubmitting} type="submit" variant="primary">
              Save
            </Button>
            <Button disabled={isSubmitting} variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
