import { forwardRef, ComponentProps } from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center disabled:bg-gray-200 disabled:border-transparent disabled:text-white gap-2 whitespace-nowrap rounded-md text-sm font-bold disabled:opacity-50 transition ease-in-out duration-150",
  {
    variants: {
      variant: {
        primary: "border-transparent bg-primary text-white",
        outline:
          "text-black bg-default/0 hover:bg-default/25 active:bg-default/50 disabled:border disabled:border-black/10 disabled:bg-default disabled:text-disabled disabled:cursor-not-allowed",
        secondary: "border border-primary bg-default text-primary",
        error: "border-transparent bg-red-500 text-white hover:bg-red-800",
        default: "bg-default text-darkgrey shadow-sm hover:bg-gray-100",
      },
      size: {
        normal: "h-9 px-4 py-2 rounded-full text-xs leading-8",
        small: "h-8 rounded-sm gap-1.5 px-3 text-xs leading-3",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "normal",
    },
  },
);

const Button = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button"> &
    VariantProps<typeof buttonVariants> & {
      asChild?: boolean;
    }
>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";

  return <Comp ref={ref} data-slot="button" className={cn(buttonVariants({ variant, size, className }))} {...props} />;
});
Button.displayName = "Button";
export { Button, buttonVariants };
