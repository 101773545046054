import clsx from "clsx";
import * as React from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import { callAll, isEmpty, isNil } from "helper/utils";
import { hasClassName } from "helper/check-class";
import { borderStatus, FieldStatus, textStatus } from "./field.type";
import { Label } from "../label";
import { useEffect, useState } from "react";

export type TextareaProps = {
  label?: string;
  onChangeValue?: (value: string) => void;
  status?: FieldStatus;
  isRequired?: boolean;
} & Omit<TextareaAutosizeProps, "ref">;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(function TextareaImpl(
  { onChangeValue, minRows = 2, status = "primary", label, isRequired, ...props },
  forwardedRef,
) {
  const [value, setValue] = useState<any>();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { name, readOnly } = { ...props };
  const getLabelClass = clsx(
    "absolute transition duration-200",
    classByFocus(isFocused),
    readOnly && "text-disabled",
    textStatus[status],
  );

  useEffect(() => {
    setValue(props.defaultValue || props.value);
  }, [props.value, props.defaultValue]);

  useEffect(() => {
    if (!isNil(value) && !isEmpty(value)) {
      setIsFocused(true);
    }
  }, [value]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!value) {
      setIsFocused(false);
    }
  };

  return (
    <div className="relative">
      <Label htmlFor={name || ""} className={getLabelClass}>
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </Label>
      <TextareaAutosize
        minRows={minRows}
        {...props}
        className={clsx(
          !hasClassName(props.className, "w-") && "w-full",
          status ? borderStatus[status] : "border-primary",
          (readOnly || props.disabled) && !hasClassName(props.className, "bg-")
            ? "border-dotted text-disabled"
            : "border-solid",
          props.className,
          "block min-w-0 form-input focus-visible:outline-hidden",
        )}
        onChange={callAll(
          (ev) => setValue(ev.target.value),
          props.onChange,
          onChangeValue && ((ev) => onChangeValue(ev.target.value)),
        )}
        ref={forwardedRef as any}
        onFocus={callAll(props.onFocus, handleFocus)}
        onBlur={callAll(props.onBlur, handleBlur)}
      />
    </div>
  );
});

export const classByFocus = (isFocused: boolean): string => {
  if (isFocused) {
    return "transform -translate-y-3.5 text-sm";
  }
  return "transform translate-y-6";
};
