import clsx from "clsx";
import { useMemo, useState } from "react";
import { InputButtonProps } from "./input-button";
import { CalendarIcon, ChevronDownIcon, CloseIcon } from "../icons";
import { hasClassName } from "helper/check-class";
import { DateRangeType, formatDateRange, Ranges } from "helper/date-time";
import { DateRangePickerSelector } from "./DateRangePicker";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { Range } from "react-date-range";
import { isEmpty } from "lodash";
import { IconButton } from "@/components";

type VariantButton = "primary" | "outline" | "secondary" | "error";

export interface DateRangeInputProps extends InputButtonProps {
  from?: Date | null;
  to?: Date | null;
  onChangeValue: (newDateRange: Range, type?: DateRangeType) => void;
  placeholder?: string;
  selectedValue?: DateRangeType;
  variant?: VariantButton;
  minDate?: Date;
  maxDate?: Date;
}

export const DateRangeSelector = ({
  variant = "primary",
  selectedValue = "custom",
  from,
  to,
  onChangeValue,
  placeholder = "Période",
  className,
  minDate,
  maxDate,
  ...buttonProps
}: DateRangeInputProps) => {
  const [open, setOpen] = useState(false);
  const fromValue = from === null ? undefined : from;
  const toValue = to === null ? undefined : to;

  const displayValue = useMemo(() => {
    if (isEmpty(selectedValue) || selectedValue === "custom") {
      return formatDateRange(fromValue, toValue);
    }
    return Ranges.filter((item) => item.name === selectedValue)[0].display;
  }, [fromValue, toValue, selectedValue]);

  const handleChangeDate = (newDateRange: Range, type: DateRangeType) => {
    setOpen(false);
    onChangeValue(newDateRange, type);
  };

  return (
    <div className={clsx("flex items-center h-11 bg-default p-1 mb-2 rounded-md shadow-lg", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          className={clsx(
            "flex items-center min-w-0",
            "h-11 rounded-t-small",
            buttonProps.disabled
              ? "bg-gray-200 cursor-default"
              : [btnClassByVariant[variant], !hasClassName("active:") && "active:shadow-outline"],
            !hasClassName(className, "w-") && "w-60",
          )}
        >
          <CalendarIcon color={colorByVariant[variant]} />
          <span
            className={clsx("flex-1 truncate text-table-data text-center capitalize ml-1", textColorByVariant[variant])}
          >
            {displayValue || <span className="text-lightgrey">{placeholder}</span>}
          </span>
          <div className="flex w-6">
            <ChevronDownIcon color={colorByVariant[variant]} className="rotate-90" />
            <ChevronDownIcon className="-rotate-90" color={colorByVariant[variant]} />
          </div>
        </PopoverTrigger>
        <PopoverContent className="p-0 bg-transparent shadow-none border-none">
          <DateRangePickerSelector
            onSelectDate={handleChangeDate}
            selectedType={selectedValue}
            startDate={fromValue}
            endDate={toValue}
            minDate={minDate}
            maxDate={maxDate}
          />
        </PopoverContent>
      </Popover>
      <IconButton
        onClick={() => handleChangeDate({ startDate: undefined, endDate: undefined }, "custom")}
        className="flex w-6"
      >
        <CloseIcon className="text-black w-4 h-4" />
      </IconButton>
    </div>
  );
};

const btnClassByVariant: Record<VariantButton, string> = {
  primary: "bg-primary",
  secondary: "bg-transparent",
  outline: "bg-transparent",
  error: "bg-red-500",
};

const colorByVariant: Record<VariantButton, string> = {
  primary: "#F3F3F4",
  secondary: "#28292F",
  outline: "#28292F",
  error: "#28292F",
};

const textColorByVariant: Record<VariantButton, string> = {
  primary: "text-secondary",
  secondary: "text-primary",
  outline: "text-primary",
  error: "text-rose-500",
};
