import { Button } from "@/components/ui/button";
import { ModalContent, ModalFooter } from "components";
import { Dialog } from "components/ui/dialog";
import { DropdownSelect, FieldContainer } from "components/form-control";
import { useState } from "react";
import { Decree, UpdateDecreeInput } from "types/decree.type";
import { decreeCategoryType } from "./decree.const";
import { DecreeCategory } from "enums/decree.enum";
import { Primitive } from "helper/utils";
import { Input } from "@/components/ui/input";

type EditDecreeProps = {
  decreeData: Decree | null;
  closeModal: () => void;
  updateDecreeFile: (input: UpdateDecreeInput) => void;
};

export const EditDecreeModal = ({ decreeData, closeModal, updateDecreeFile }: EditDecreeProps) => {
  const [name, setName] = useState(decreeData?.name);
  const [selectedCategory, setSelectedCategory] = useState(decreeData?.category ?? DecreeCategory.temporary);

  const saveDecree = async () => {
    const newDecree = {
      id: decreeData?.id,
      name: name || "",
      done: decreeData?.done,
      category: selectedCategory,
    };
    await updateDecreeFile(newDecree);
    closeModal();
  };

  const handleUpdateByDecreeCategory = (value: Primitive) => {
    setSelectedCategory(value as DecreeCategory);
  };
  return (
    <Dialog open>
      <ModalContent className="pb-0" title="Edit decree" description="Decree information">
        <FieldContainer>
          <DropdownSelect
            defaultValue={selectedCategory}
            className="justify-between w-full"
            options={decreeCategoryType}
            onChangeValue={handleUpdateByDecreeCategory}
          />
        </FieldContainer>
        <FieldContainer>
          <Input label="Name" className="w-full" value={name} name="name" onChange={(e) => setName(e.target.value)} />
        </FieldContainer>
        <ModalFooter>
          <Button onClick={saveDecree} variant="primary">
            save
          </Button>
          <Button onClick={closeModal} variant="secondary">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Dialog>
  );
};
