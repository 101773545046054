import { Button } from "@/components/ui/button";
import { Confirm } from "components";
import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Checkbox } from "components/form-control";
import { useQueryWorkArea } from "modules/curb-zone/hooks/use-query-work-area";
import { Dispatch, SetStateAction, useState } from "react";
import { WorkArea } from "types/work-area.type";

interface WorkAreaDetailProps {
  workAreaDetail: WorkArea;
  setOpenWorkAreaModal: Dispatch<SetStateAction<boolean>>;
  setWorkAreaDetail: (params: WorkArea | undefined) => void;
}
export const WorkAreaDetail = ({ workAreaDetail, setOpenWorkAreaModal, setWorkAreaDetail }: WorkAreaDetailProps) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleEdit = () => setOpenWorkAreaModal(true);
  const { handleUpdateWorkArea, handleRemoveWorkArea } = useQueryWorkArea();
  const handleDelete = async () => {
    await handleRemoveWorkArea({ id: workAreaDetail.id });
    setOpenConfirm(false);
    setWorkAreaDetail(undefined);
  };

  const onChangeStatus = async (done: boolean) => {
    const updateWorkAreaParam = { workAreaInput: { ...workAreaDetail, done } };
    const response = await handleUpdateWorkArea(updateWorkAreaParam);
    if (response?.updateWorkArea) {
      setWorkAreaDetail(response.updateWorkArea);
    }
  };
  return (
    <>
      <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80 max-w-100">
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary pr-4">Name</TableCell>
              <TableCell className="text-table-data text-secondary pb-1 flex justify-end">
                {workAreaDetail.name}
              </TableCell>
            </TableRow>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Done</TableCell>
              <TableCell className="pt-4 flex justify-end">
                <Checkbox
                  variant="secondary"
                  checked={workAreaDetail.done}
                  onChange={(e) => onChangeStatus(e.target.checked)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </DataTable>
        <div className="flex justify-between mt-4">
          <Button variant="error" onClick={() => setOpenConfirm(true)}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleEdit}>
            Edit
          </Button>
        </div>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete work area"
          content="Are you sure that you want to delete this work area?"
          onCancel={() => setOpenConfirm(false)}
          onOk={handleDelete}
          butOkName="Delete"
          type="error"
        />
      )}
    </>
  );
};
