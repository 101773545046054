import { Badge, BadgeProps } from "../badge";
import { isDefined, isEmpty } from "helper/utils";
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ChevronDownIcon } from "@/assets";

export interface MultiInputWithSuggestionsProps
  extends Omit<React.ComponentProps<"input">, "onChange" | "onKeyDown" | "value" | "size"> {
  selectedValues: string[];
  onChangeValues: (values: string[]) => void;
  badgeColor?: BadgeProps["variant"];
  size?: BadgeProps["size"];
  validateBeforeAdd?: (value: string) => boolean;
  suggestions?: string[] | undefined;
  onInputValueChange: (value: string) => void;
  label?: string;
}

/**
 * Select with tags, transform input to tag (scroll the menu).
 * User can use: tab, enter, comma to add an item.
 * **/
export const MultiSelectSuggestions = ({
  size = "large",
  badgeColor = "grey",
  onChangeValues,
  validateBeforeAdd,
  className,
  suggestions,
  onInputValueChange,
  selectedValues,
  ...inputProps
}: MultiInputWithSuggestionsProps) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  // select an item
  const selectItem = (value: string) => {
    onChangeValues([...selectedValues, value]);
  };

  // Remove a single value
  const removeValue = (value: string) => {
    const newValues = selectedValues.filter((val) => val !== value);
    onChangeValues(newValues);
  };

  // Clear all selected values
  const clearValues = () => {
    onChangeValues([]);
  };

  const addItem = (value: string) => {
    const val = value.trim();
    if (val && (!validateBeforeAdd || validateBeforeAdd(val))) {
      onChangeValues(selectedValues.concat(value));
      setInputValue("");
      onInputValueChange("");
    }
  };
  //press the "," character to add new item
  const handleInputChange = (value: string | undefined) => {
    if (isDefined(value)) {
      if (!/^,+$/.test(value) && value.endsWith(",")) {
        addItem(value.slice(0, -1));
      } else {
        setInputValue(value);
        onInputValueChange(value);
      }
    }
  };
  //press the tab, enter character to add new item
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (isEmpty(inputValue)) {
      return;
    }
    if (["Tab", "Enter"].includes(ev.key)) {
      ev.preventDefault();
      addItem(inputValue);
    }
  };

  return (
    <div className="flex flex-col">
      {selectedValues.length > 0 && (
        <div className="flex flex-wrap gap-1 overflow-x-auto max-w-full">
          {selectedValues.map((value, idx) => {
            return (
              <Badge
                key={`selectedItem-${idx}`}
                size={size}
                variant={badgeColor}
                onRemove={(e) => {
                  e.stopPropagation();
                  removeValue(value);
                }}
                className="mr-1"
              >
                {value}
              </Badge>
            );
          })}
        </div>
      )}
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button variant="outline" aria-expanded={open} className={cn("w-full justify-between", className)}>
            {inputProps.placeholder}
            <ChevronDownIcon
              className={`${open && "rotate-180 transform"} transition duration-150 ease-in-out w-5`}
              aria-hidden="true"
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command>
            <Input
              placeholder={inputProps.placeholder}
              onChange={(e) => handleInputChange(e.target.value)}
              onKeyDown={handleKeyDown}
              value={inputValue}
              className="px-2"
            />
            <CommandEmpty>No record found.</CommandEmpty>
            <CommandGroup className="max-h-[120px] overflow-y-auto">
              {suggestions?.map((item, idx) => (
                <CommandItem
                  key={`suggestion-${item}-${idx}`}
                  onSelect={() => selectItem(item)}
                  className="cursor-pointer"
                >
                  <span>{item}</span>
                </CommandItem>
              ))}
            </CommandGroup>
            {selectedValues.length > 0 && (
              <div className="border-t p-2">
                <Button
                  variant="outline"
                  size="small"
                  className="w-full"
                  onClick={() => {
                    clearValues();
                    setOpen(false);
                  }}
                >
                  Clear selections
                </Button>
              </div>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
