import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";
import DeckGL from "@deck.gl/react";
import { mapboxStyle, ModeViewType } from "constants/map.const";
import { MAPBOX_ACCESS_TOKEN } from "constants/mapbox.const";
import { useAddControlMapbox } from "hooks/use-add-control-mapbox";
import { createMarkerLayer } from "helper/map-utils";
import { useMemo } from "react";
import { Map, ViewState } from "react-map-gl";
import { AppModeAttribute, AppModeType, ViewportPlaceType } from "types";
import { GetCursor, interactionStateType, MapType, mapUrlByType, TooltipType } from "types/map.type";
import { CurbZoneTooltip } from "../curb-zone-tooltip";
import { CurbZone } from "types/curb-zone.type";
import { MapController } from "deck.gl";
import { MjolnirEvent } from "mjolnir.js";

export interface MapBoxProps {
  markerPosition: ViewportPlaceType | null;
  mapType: MapType;
  viewport: ViewState;
  layers: any[];
  handleSelectLayer: (event: any) => void;
  handleDragEnd: (event: any) => void;
  handleZooming: (input: interactionStateType) => void;
  onViewStateChange: ({ viewState }: any) => void;
  hoverInfo?: TooltipType<CurbZone>;
  modeView: ModeViewType;
  mapRef: any;
  appMode: keyof AppModeAttribute<any>;
  isSpaceBarPressed?: boolean;
  getCursor: () => GetCursor;
}

export class EditableLayerMapController extends MapController {
  constructor(props: any) {
    super(props);
    // We cannot enable 'anyClick', because this seems to be not known to mjolnir.js anymore.
    this.events = ["click"];
  }
  handleEvent(event: MjolnirEvent) {
    if (event.type == "click") {
      // @ts-expect-error https://github.com/visgl/deck.gl-community/issues/201
      this.eventManager.manager.emit("anyclick", event);
    }
    return super.handleEvent(event);
  }
}

export const MapStyle = ({
  mapType,
  markerPosition,
  viewport,
  layers,
  handleSelectLayer,
  handleDragEnd,
  handleZooming,
  onViewStateChange,
  hoverInfo,
  modeView,
  mapRef,
  appMode,
  getCursor,
}: MapBoxProps) => {
  const mapStyle = mapboxStyle[modeView];
  const { mapRefCallback } = useAddControlMapbox();
  const markerLayer = useMemo(() => {
    if (markerPosition) {
      return createMarkerLayer({ coordinates: [markerPosition.longitude, markerPosition.latitude] });
    }
    return null;
  }, [markerPosition]);

  const mapLayers = useMemo(() => {
    if (mapType === MapType.MAPBOX) {
      if (markerLayer) {
        return [...layers, markerLayer];
      }
      return layers;
    }
    const tileLayer = new TileLayer({
      id: "sat-other-map",
      data: mapUrlByType[mapType],

      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,

      renderSubLayers: ({ tile, data, ...props }: any) => {
        const {
          bbox: { west, south, east, north },
        } = tile;

        return new BitmapLayer(props, {
          image: data,
          bounds: [west, south, east, north],
        });
      },
    });
    if (markerLayer) {
      return [tileLayer, ...layers, markerLayer];
    }
    return [tileLayer, ...layers];
  }, [layers, mapType, markerLayer]);

  return (
    <DeckGL
      initialViewState={{ ...viewport }}
      controller={{
        touchZoom: true,
        touchRotate: true,
        type: EditableLayerMapController,
      }}
      getCursor={getCursor()}
      layers={mapLayers}
      pickingRadius={12}
      onClick={handleSelectLayer}
      onDragEnd={handleDragEnd}
      onInteractionStateChange={handleZooming}
      ref={mapRef}
      onViewStateChange={onViewStateChange}
    >
      <Map
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={mapStyle}
        style={{ width: "100%", height: "100%" }}
        ref={mapRefCallback}
      />
      {appMode === AppModeType.View && hoverInfo?.properties && (
        <CurbZoneTooltip left={hoverInfo.x} top={hoverInfo.y} curbZone={hoverInfo.properties} />
      )}
    </DeckGL>
  );
};
