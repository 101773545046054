/**
 * Generic implementation of an attribute depending on the App Mode.
 *
 * ```ts
 * interface MyAttribute extends AppModeAttribute<number> {
 *   view: 0,
 *   add:  1,
 *   edit: 2,
 *   city: 3,
 * }
 * ```
 */
export enum DisabledModeType {
  AddDisabledCurbZone = "addDisabledCurbZone",
  EditDisabledCurbZone = "editDisabledCurbZone",
  AddDisabledStreet = "addDisabledStreet",
  EditDisabledStreet = "editDisabledStreet",
  MultiCreateDisabledCurbZone = "multiCreateDisabledCurbZone",
}

export type DisabledModeAttribute<T> = {
  [key in DisabledModeType]: T;
};

export enum AppModeType {
  View = "view",
  Add = "add",
  Edit = "edit",
  MultiEdit = "multiEdit",
  AddCurbArea = "addCurbArea",
  EditCurbArea = "editCurbArea",
  AddParkingLot = "addParkingLot",
  EditParkingLot = "editParkingLot",
  AddWorkArea = "addWorkArea",
  EditWorkArea = "editWorkArea",
}

export type AppModeAttribute<T> = {
  [key in AppModeType]: T;
} & Partial<DisabledModeAttribute<any>>;

/**
 * Allowed/registered App Modes
 */
export type AppMode = keyof AppModeAttribute<any>;
