import { useApolloClient, useMutation } from "@apollo/client";
import {
  QUERY_CURB_AREAS,
  QUERY_ADD_CURB_AREA,
  QUERY_UPDATE_CURB_AREA,
  QUERY_REMOVE_CURB_AREA,
} from "services/web/api-curb-area.const";
import { CurbArea } from "types/curb-area.type";
import {
  CreateCurbAreaInput,
  CreateCurbAreaResponse,
  RemoveCurbAreaInput,
  RemoveCurbAreaResponse,
  UpdateCurbAreaInput,
  UpdateCurbAreaResponse,
  CurbAreasResponse,
} from "services/web/api-curb-area.type";
import { useState } from "react";
import { findObject } from "helper/array";
import { useContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { showGlobalLoading, hideGlobalLoading } from "hooks/use-global-loading";
import { CityContext } from "@/contexts/city-context-provider";

interface CurbAreasCache {
  curbAreas: CurbArea[] | [];
}

export const useQueryCurbArea = () => {
  const client = useApolloClient();
  const [isResetData, setIsResetData] = useState<boolean>(false);

  const { currentCity } = useContext(CityContext);

  const variables = useMemo(() => {
    return {
      cityId: currentCity?.id,
    };
  }, [currentCity]);

  const { data, loading } = useQuery<CurbAreasResponse>(QUERY_CURB_AREAS, {
    variables,
    skip: !currentCity?.id,
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const curbAreas = useMemo(() => {
    return data?.curbAreas || [];
  }, [data]);

  const getCachedCurbArea = (): CurbArea[] => {
    const cachedCurbArea = client.cache.readQuery({ query: QUERY_CURB_AREAS, variables }) as CurbAreasCache;
    return cachedCurbArea.curbAreas;
  };

  const resetState = () => {
    setIsResetData(true);
  };

  const [createCurbArea] = useMutation<CreateCurbAreaResponse>(QUERY_ADD_CURB_AREA, {
    onCompleted: (response) => {
      const preState = getCachedCurbArea();
      const newState = [...preState, { ...response.createCurbArea }];
      client.cache.writeQuery({ query: QUERY_CURB_AREAS, variables, data: { curbAreas: newState } });
    },
    onError: resetState,
  });

  const [updateCurbArea] = useMutation<UpdateCurbAreaResponse>(QUERY_UPDATE_CURB_AREA, {
    onCompleted: (response) => {
      const preState = getCachedCurbArea();
      const findIndex = findObject(preState, "id", response.updateCurbArea?.id);
      if (findIndex > -1) {
        const newState = [...preState];
        newState[findIndex] = response.updateCurbArea;
        client.cache.writeQuery({ query: QUERY_CURB_AREAS, variables, data: { curbAreas: newState } });
      }
    },
    onError: resetState,
  });

  const [removeCurbArea] = useMutation<RemoveCurbAreaResponse>(QUERY_REMOVE_CURB_AREA, {
    onCompleted: (response) => {
      const preState = getCachedCurbArea();
      const newState = preState.filter((item) => item.id !== response.deleteCurbArea?.id);
      client.cache.writeQuery({ query: QUERY_CURB_AREAS, variables, data: { curbAreas: newState } });
    },
    onError: resetState,
  });

  const handleAddCurbArea = async (input: CreateCurbAreaInput) => {
    const response = await createCurbArea({ variables: input });
    return response.data;
  };

  const handleUpdateCurbArea = async (input: UpdateCurbAreaInput) => {
    const response = await updateCurbArea({ variables: input });
    return response.data;
  };

  const handleRemoveCurbArea = async (input: RemoveCurbAreaInput) => {
    const response = await removeCurbArea({ variables: input });
    return response.data;
  };
  return {
    curbAreas,
    loading,
    currentCity,
    handleAddCurbArea,
    handleUpdateCurbArea,
    handleRemoveCurbArea,
    isResetData,
    setIsResetData,
  };
};
