import * as React from "react";
import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Confirm } from "components";
import { formatDate } from "helper/date-time";
import { numberFormat } from "helper/format-data";
import { ParkingMeter } from "services/web/api-parking-meter.type";
import useToggle from "hooks/use-toggle";
import { ParkingMeterModalState } from "../parking-payment.const";
import { Button } from "@/components/ui/button";

type ParkingMeterDetailProps = {
  parkingMeter: ParkingMeter;
  setParkingModalState: React.Dispatch<React.SetStateAction<keyof typeof ParkingMeterModalState>>;
  handleRemoveParkingMeter: (parkingMeterId: string) => void;
};

export const ParkingMeterDetail = ({
  parkingMeter,
  setParkingModalState,
  handleRemoveParkingMeter,
}: ParkingMeterDetailProps) => {
  const [openConfirm, setOpenConfirm] = useToggle();

  const removeParkingMeter = () => {
    if (parkingMeter.id) {
      handleRemoveParkingMeter(parkingMeter.id);
    }
  };

  return (
    <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-5 min-w-80">
      <DataTable className="p-0 shadow-none rounded-none">
        <TableBody>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Name</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.name}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Address</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.address}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Status</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.status}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">External ID</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">
              {parkingMeter?.externalId}
            </TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Operator</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.operator}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Brand</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.brand}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Model</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.model}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Zone</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">{parkingMeter?.zone}</TableCell>
          </TableRow>
          <TableRow className="hover:none">
            <TableCell className="text-table-data text-secondary">Battery Level</TableCell>
            <TableCell className="text-table-data text-secondary flex justify-end">
              {numberFormat(parkingMeter?.batteryLevel)}
            </TableCell>
          </TableRow>
          {parkingMeter?.lastMaintenanceDate && (
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary">Last Maintenance Date</TableCell>
              <TableCell className="text-table-data text-secondary flex justify-end">
                {formatDate(parkingMeter?.lastMaintenanceDate)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </DataTable>
      <div className="flex justify-between mt-4">
        <Button onClick={setOpenConfirm} variant="error">
          Delete
        </Button>
        <Button onClick={() => setParkingModalState(ParkingMeterModalState.SHOW)} variant="secondary">
          Edit
        </Button>
      </div>
      {openConfirm && (
        <Confirm
          title="Delete a parking meter"
          content="Are you sure that you want to delete this parking meter?"
          onCancel={setOpenConfirm}
          onOk={removeParkingMeter}
          butOkName="Delete"
          type="error"
        />
      )}
    </div>
  );
};
