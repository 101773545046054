import { IconButton } from "components";
import { ModeViewType } from "constants/map.const";
import satelliteMap from "assets/satellite_map.png";
import normalMap from "assets/normal_view.png";
import { useMemo } from "react";

export type SwitchModeViewProps = {
  selectModeView: (mode: ModeViewType) => void;
  modeView: ModeViewType;
};
const classImageMap = "absolute p-0.5 bottom-2 left-6 rounded-md shadow-lg ring-black/50 w-20 h-20 ";
const classNameMap = "absolute capitalize text-xs bottom-2 ";
const colorText = {
  satellite: "text-gray-500",
  normal: "text-gray-50",
};
const bgColor = {
  satellite: "bg-default",
  normal: "bg-black",
};

export const SwitchModeView = ({ selectModeView, modeView }: SwitchModeViewProps) => {
  const imgSrc = useMemo(() => {
    return modeView === "satellite" ? normalMap : satelliteMap;
  }, [modeView]);
  const otherView = useMemo(() => {
    return modeView === "satellite" ? "normal" : "satellite";
  }, [modeView]);
  return (
    <div className={classImageMap + bgColor[modeView]}>
      <IconButton onClick={() => selectModeView(otherView)} className="p-0">
        <img src={imgSrc} alt="" className="rounded-md bg-linear-to-t from-gray-100 to-transparent" />
        <span className={classNameMap + colorText[modeView]}>{otherView}</span>
      </IconButton>
    </div>
  );
};
