import { useContext, useEffect, useMemo } from "react";
import { useDebounce } from "hooks/lib-ui";
import { OffStreetParkingsResponse } from "types";
import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_ADD_OFF_STREET_PARKING,
  QUERY_OFF_STREET_PARKING,
  QUERY_REMOVE_OFF_STREET_PARKING,
  QUERY_UPDATE_OFF_STREET_PARKING,
} from "services/web/api-cities.const";
import { CityContext } from "contexts/city-context-provider";
import { showGlobalLoading, hideGlobalLoading } from "hooks/use-global-loading";

export const useOffStreetParking = (bounding?: number[][] | undefined) => {
  const { currentCity } = useContext(CityContext);
  const nearBy = useDebounce(bounding, 500);

  const variables = useMemo(
    () => ({
      cityId: currentCity?.id ?? "",
      bounding: nearBy,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [currentCity?.id, nearBy],
  );

  const { data, loading, refetch } = useQuery<OffStreetParkingsResponse>(QUERY_OFF_STREET_PARKING, {
    variables,
    skip: !currentCity?.id || (bounding && !nearBy),
  });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const [createOffStreetParking] = useMutation(QUERY_ADD_OFF_STREET_PARKING, {
    onCompleted: () => {
      refetch();
    },
  });

  const [updateOffStreetParking] = useMutation(QUERY_UPDATE_OFF_STREET_PARKING, {
    onCompleted: () => {
      refetch();
    },
  });

  const [removeOffStreetParking] = useMutation(QUERY_REMOVE_OFF_STREET_PARKING, {
    onCompleted: () => {
      refetch();
    },
  });

  const OffStreetParking = useMemo(() => {
    return data?.privateParkingsByBounding || [];
  }, [data]);

  return {
    nearBy,
    OffStreetParking,
    loading,
    createOffStreetParking,
    updateOffStreetParking,
    removeOffStreetParking,
  };
};
