import clsx from "clsx";
import { typography } from "@/components";
import { hasClassName } from "helper/check-class";
import { forwardRef } from "react";
import { DialogContent, DialogHeader, DialogTitle, DialogDescription } from "components/ui/dialog";
import { cn } from "@/lib/utils";

export type DialogContentProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
} & React.ComponentProps<"div">;

export const ModalContent = forwardRef<HTMLDivElement, DialogContentProps>(
  ({ title, description, children, ...props }, ref) => {
    return (
      <DialogContent
        ref={ref}
        className={clsx(
          "bg-default text-left px-4 py-6 font-sans md:px-6 lg:px-8 overflow-y-auto max-h-[calc(100vh-5em)]",
          !hasClassName(props.className, "rounded-") && "rounded-2xl",
          !hasClassName(props.className, "w-") && "w-full",
          !hasClassName(props.className, "max-w-") && "max-w-sm md:max-w-md lg:max-w-2xl",
          props.className,
        )}
      >
        <DialogHeader className={clsx("mb-4", typography.h2)}>
          <DialogTitle className="text-lg leading-6 font-medium text-gray-900">{title}</DialogTitle>
          <DialogDescription className={cn("mt-1 max-w-2xl text-sm text-gray-500 hidden", description && "flex")}>
            {description}
          </DialogDescription>
        </DialogHeader>
        {children && <div className="mt-2">{children}</div>}
      </DialogContent>
    );
  },
);

ModalContent.displayName = "ModalContent";
