import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Modal, ModalContent, ModalFooter } from "components";
import { CurbZoneMultipleInput, CurbZoneMultiplePromptProps } from "./curb-zone.type";
import { PolicyView } from "./policy-view";
import { CurbZone } from "types/curb-zone.type";
import { DropdownSelect, FieldContainer } from "components/form-control";
import { ParkingAngle } from "./curb-zone.const";
import { Input } from "@/components/ui/input";
export const CurbZoneMultiplePrompt = ({
  numberOfParking,
  cancel,
  handleEditMultipleCurbZone,
}: CurbZoneMultiplePromptProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CurbZone>({
    mode: "all",
  });
  const [curbPolicies, setCurbPolicies] = useState<string[]>([]);

  const saveParking = (input: CurbZoneMultipleInput) => {
    handleEditMultipleCurbZone({ ...input, curbPolicyIds: curbPolicies });
  };

  return (
    <Modal isOpen className="my-0 h-full max-w-lg flex items-center content-center">
      <ModalContent title="Edit Curb Zone" description={`Currently selecting ${numberOfParking} Curb Zones`}>
        <form onSubmit={handleSubmit(saveParking)}>
          <PolicyView curbPolicies={curbPolicies} setCurbPolicies={setCurbPolicies} />
          <FieldContainer className="mt-8" helpText={errors.parkingAngle ? "This is required" : undefined}>
            <DropdownSelect
              label="Forme"
              options={Object.values(ParkingAngle)}
              {...register("parkingAngle", { required: true })}
              isRequired
              defaultValue={ParkingAngle.parallel}
            />
          </FieldContainer>
          <FieldContainer
            helpText={errors.width ? "This is required" : undefined}
            status={errors?.width ? "error" : "primary"}
          >
            <Input
              label="Largeur"
              {...register("width", { valueAsNumber: true, required: true })}
              isRequired
              status={errors.width ? "error" : "primary"}
            />
          </FieldContainer>

          <ModalFooter>
            <Button type="submit" variant="primary">
              Save
            </Button>
            <Button onClick={cancel} variant="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
