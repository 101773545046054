import { SVGProps } from "react";

export const ArrowUpDownIcon = ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={width}
      height={height}
      strokeWidth="2"
      {...props}
    >
      <path d="M7 3l0 18"></path>
      <path d="M10 6l-3 -3l-3 3"></path>
      <path d="M20 18l-3 3l-3 -3"></path>
      <path d="M17 21l0 -18"></path>
    </svg>
  );
};
