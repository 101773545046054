/**
 * @see: https://ui.shadcn.com/docs/components/combobox
 **/
import { cn } from "@/lib/utils";
import { LoadingIcon } from "assets";
import { useMemo, forwardRef, useState, useEffect, ComponentPropsWithoutRef } from "react";
import { isNil, isPrimitive, Primitive } from "helper/utils";
import { borderStatus, FieldStatus } from "./field.type";
import { hasClassName } from "helper/check-class";
import { ChevronDownIcon, BoldSearchIcon } from "../icons";
import { Label } from "../label";
import { twMerge } from "tailwind-merge";
import { Input } from "components/ui/input";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";

export type OptionType<T extends Primitive = string> = {
  value: T;
  label: string;
  description?: string;
};

export type Size = "small" | "normal";
export type ElementPosition = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export type DropdownAutoCompleteSelectProps<T extends Primitive = string> = ComponentPropsWithoutRef<
  "select" | "button"
> & {
  status?: FieldStatus;
  value: T | undefined | null;
  onChangeValue?: (value: string, label: string) => void;
  options: T extends Primitive ? T[] | OptionType<T>[] : OptionType<T>[];
  className?: string;
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  handleSearch?: (value: string) => void;
  searchString?: string;
  size?: Size;
  styleList?: ElementPosition;
  isLoading?: boolean;
  panelClassName?: string;
  inputClassName?: string;
  displayValue?: T;
};

export const DropdownAutoCompleteSelect = forwardRef<HTMLDivElement, DropdownAutoCompleteSelectProps>(
  function DropdownSelect(
    {
      status = "primary",
      placeholder,
      onChangeValue,
      value = "",
      options = [],
      className,
      label,
      handleSearch,
      searchString = "",
      size = "normal",
      styleList,
      isRequired,
      panelClassName,
      inputClassName,
      isLoading,
      displayValue,
      ...selectProps
    },
    ref,
  ) {
    const [open, setOpen] = useState(false);
    const { name } = { ...selectProps };
    const [selectedItem, setSelectedItem] = useState<OptionType | null>(null);
    const { disabled } = selectProps;

    const optionData = useMemo(
      () =>
        (options as any[]).map(
          (opt) =>
            (isPrimitive(opt)
              ? {
                  value: opt,
                  label: String(opt),
                }
              : opt) as OptionType,
        ),
      [options],
    );

    const getLabelClass = cn(disabled && "text-disabled", classByStatus[status]);

    useEffect(() => {
      if (isNil(value)) {
        setSelectedItem(null);
      } else {
        const newSelected = optionData.find((opt) => opt.value === value);
        if (newSelected) {
          setSelectedItem(newSelected);
        }
      }
    }, [value, optionData]);

    const placeHolderText = useMemo(() => {
      return placeholder ?? label;
    }, [label, placeholder]);

    const displayText = useMemo(() => {
      if (displayValue) {
        return displayValue;
      }
      return selectedItem ? selectedItem.label : placeHolderText;
    }, [selectedItem, displayValue, placeHolderText]);

    return (
      <div className="relative" ref={ref}>
        {label && selectedItem && (
          <Label htmlFor={name ?? ""} className="form-label-focus">
            {label}
            {isRequired && <span className="text-red-500">*</span>}
          </Label>
        )}
        <Popover open={open} onOpenChange={setOpen} modal={true}>
          <PopoverTrigger asChild>
            <Button
              className={cn(
                !hasClassName(className, "border-") && status && borderStatus[status],
                "border-b border-solid rounded-none shadow-none",
                "flex items-center text-left transition ease-in-out duration-150 w-full appearance-none pt-2.5 pb-2 z-1 text-medium focus-visible:outline-hidden",
                className,
                getLabelClass,
              )}
            >
              <div className="flex justify-between w-full">
                <span className={cn("block truncate pr-3", classLabelBySize[size])}>{displayText}</span>
                <span className="flex items-center pointer-events-none">
                  <ChevronDownIcon />
                </span>
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent className={cn("w-[200px] p-0", panelClassName)} {...styleList}>
            <Command>
              {handleSearch && (
                <div className="px-3 flex items-end">
                  <Input
                    onChangeValue={handleSearch}
                    value={searchString}
                    placeholder={placeholder ?? "Search"}
                    iconStart={<BoldSearchIcon />}
                    className={inputClassName}
                  />
                </div>
              )}
              <CommandList>
                {isLoading && (
                  <div className="text-center p-3">
                    <LoadingIcon className="w-5 h-5 inline-block" />
                  </div>
                )}
                <CommandEmpty>No record found.</CommandEmpty>
                <CommandGroup>
                  {optionData.map((item, itemIdx) => {
                    const selected = item.value === value;
                    return (
                      <CommandItem
                        key={`${item.value}-${itemIdx}`}
                        value={item.value as string}
                        onSelect={() => {
                          if (onChangeValue) {
                            onChangeValue(item.value as string, item.label);
                          }
                          setOpen(false);
                        }}
                        className={cn(
                          "cursor-pointer select-none relative hover:bg-secondary",
                          selected && "bg-secondary",
                        )}
                      >
                        <div>
                          <span className={cn("block", classItemBySize[size])}>{item.label}</span>
                          {item?.description && (
                            <span className={twMerge("text-primary-top", classDescriptionItemBySize[size])}>
                              {item.description}
                            </span>
                          )}
                        </div>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
    );
  },
);

type DropdownSelectStatus = NonNullable<DropdownAutoCompleteSelectProps["status"]>;
const classByStatus: Record<DropdownSelectStatus, string> = {
  primary: "text-primary",
  default: "text-primary",
  error: "text-red",
  warning: "text-yellow",
  success: "text-green",
};

const classLabelBySize: Record<Size, string> = {
  small: "text-sm leading-3.6",
  normal: "text-medium",
};

const classItemBySize: Record<Size, string> = {
  small: "font-medium text-13px leading-5 py-1 px-3",
  normal: "font-normal text-medium py-2 pl-6 pr-4",
};

const classDescriptionItemBySize: Record<Size, string> = {
  small: "font-medium text-sm leading-5 py-1 px-3",
  normal: "font-normal text-xs py-2 pl-6 pr-4",
};
