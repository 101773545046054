import * as React from "react";
import { FILES_ICON } from "configs/files";
import { BackModalIcon, DownloadIcon } from "components/icons";
import { Decree } from "types/decree.type";
import { Dialog } from "components/ui/dialog";

export type DescriptionModalProps = {
  decree: Decree;
  closeModal: () => void;
};
export const DecreeModal = ({ decree, closeModal }: DescriptionModalProps) => {
  const height = window.innerHeight - 110;
  return (
    <Dialog open>
      <div className="fixed inset-0 flex flex-col items-center bg-title/90 z-50">
        {decree.publicUrl ? (
          <>
            <div className="w-full z-20">
              <div className="flex items-center justify-between px-20 h-21">
                <div className="flex items-center hover:cursor-pointer" onClick={closeModal} role="none">
                  <BackModalIcon className="mr-7" />
                  <div className="flex items-end">
                    <img src={FILES_ICON["pdf"]} alt="Numéro AM" className="mr-1 h-4" />
                    <span className="truncate text-subtitle text-white">{decree.name}</span>
                  </div>
                </div>
                <a
                  download={decree.publicUrl}
                  href={decree.publicUrl}
                  className="focus:outline-hidden flex items-center hover:cursor-pointer"
                >
                  <DownloadIcon className="hover:cursor-pointer w-4 h-4" />
                </a>
              </div>
            </div>

            <div className="max-w-4xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all object-contain">
              <embed src={decree.publicUrl} width="700" height={height} />
            </div>
          </>
        ) : (
          <div>Fichier n'existe pas</div>
        )}
      </div>
    </Dialog>
  );
};
