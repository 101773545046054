import { CurbZoneHover, DisabledHover } from "../modes/disable-parts/disabled-part.type";
import { AppMode, AppModeType, DisabledModeType, MapType, Restriction, ViewportPlaceType } from "types";
import { EditableGeoJsonLayer, FeatureOf, SelectionLayer, ViewMode } from "@deck.gl-community/editable-layers";
import { Feature, FeatureCollection, LineString, Polygon, Position } from "geojson";
import {
  disabledCurbZoneMode,
  disabledStreetMode,
  editableGeoJsonLayerModes,
  getPolygonColor,
  ModeViewType,
  toLineStringFeature,
} from "constants/map.const";
import { filterLineStringFromPoint, filterFeatureIndex } from "helper/map-utils";
import { convertDateToUTC, parseISOString } from "helper/date-time";
import { calculateArea, convertLineToPolygon, getPlace } from "helper/format-data";
import { uniqBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { CityContext } from "contexts/city-context-provider";
import { Decree } from "types/decree.type";
import { DisabledPartProps } from "../disabled-part.type";
import { GeoJsonLayer } from "@deck.gl/layers";
import WebMercatorViewport from "viewport-mercator-project";
import { bbox } from "@turf/turf";
import { findObject } from "helper/array";
import { getQueryParams, isNil } from "helper/utils";
import { useInteractMap } from "hooks/use-interact-map";
import { usePressKeyboard } from "hooks/use-press-keyboard";
import useToggle from "hooks/use-toggle";
import { useQueryRestriction } from "./use-query-restriction";
import { RemoveRestrictionParam } from "services/web/api-restriction.type";
import { isTrafficRestriction } from "../restriction";
import { useQueryDecreesListByCity } from "./use-query-all-decree";

type ChangeType = "create" | "update" | "delete" | "multiCreate" | "bulkUpdate" | "bulkDelete";

export const useRestriction = ({ appMode, selectedDate, refetchDecree }: DisabledPartProps) => {
  const { view } = getQueryParams();
  const { currentCity, viewport, setViewport } = useContext(CityContext);
  const [markerPosition, setMarkerPosition] = useState<ViewportPlaceType | null>(null);
  const [disabledCurbZoneEditionData, setDisabledCurbZoneEditionData] = useState<
    FeatureCollection<LineString, Restriction>
  >({
    type: "FeatureCollection",
    features: [],
  });
  const [multiDisabledEditionData, setMultiDisabledEditionData] = useState<FeatureCollection<LineString, Restriction>>({
    type: "FeatureCollection",
    features: [],
  });
  const [currentLineParkingList, setCurrentLineParkingList] = useState<Feature<Polygon>[]>([]);
  const [currentLineRestriction, setCurrentLineRestriction] = useState<Feature<LineString, Restriction>[]>([]);
  const [curbZonePolygonData, setCurbZonePolygonData] = useState<Feature<Polygon>[]>([]);

  const [curbZoneDetail, setCurbZoneDetail] = useState<Feature<Polygon>[]>([]);
  const [openRestrictionModal, setOpenRestrictionModal] = useToggle(false);
  const [openDisabledCurbZoneMultipleModal, setOpenDisabledCurbZoneMultipleModal] = useState(false);

  const [mapType, setMapType] = useState<MapType>(MapType.MAPBOX);

  const currentPosition = useRef<Position>([]);
  const mapRef = useRef<any>();
  const wrapMapRef = useRef<any>();
  const preZooming = useRef<any>(null);
  const previousAppMode = useRef<AppMode>(appMode);

  const [modeView, setModeView] = useState<ModeViewType>("normal");

  const preCurbZoneEdited = useRef<Feature<Polygon>>();
  const [selectedDecree, setSelectedDecree] = useState<Decree | null>(null);
  const [disabledHover, setDisabledHover] = useState<DisabledHover | null>(null);
  const [curbZoneHover, setCurbZoneHover] = useState<CurbZoneHover | null>(null);

  const [disabledStreetEditionData, setDisabledStreetEditionData] = useState<
    FeatureCollection<LineString, Restriction>
  >({
    type: "FeatureCollection",
    features: [],
  });

  const { decreeList } = useQueryDecreesListByCity(currentCity?.id);

  const [restrictionDetail, setRestrictionDetail] = useState<Feature<Polygon, Restriction>[]>([]);

  const [isShowDisabledCurbZone, setIsShowDisabledCurbZone] = useToggle(true);

  const currentRestrictionIndexes = useMemo(() => {
    if (isTrafficRestriction(restrictionDetail[0]?.properties?.type) && disabledStreetMode.includes(appMode)) {
      /**
       * type is a traffic restriction
       * */
      return filterFeatureIndex(disabledStreetEditionData, restrictionDetail);
    } else if (disabledCurbZoneMode.includes(appMode)) {
      return filterFeatureIndex(disabledCurbZoneEditionData, restrictionDetail);
    }
    return [];
  }, [disabledStreetEditionData, disabledCurbZoneEditionData, restrictionDetail, appMode]);

  const editableGeoJsonLayerMode = useMemo(() => {
    if (disabledStreetMode.includes(appMode)) {
      return editableGeoJsonLayerModes[appMode];
    }
    return ViewMode;
  }, [appMode]);

  const {
    loading,
    setIsResetData,
    setBounding,
    data,
    createRestriction,
    updateRestriction,
    updateMultiRestriction,
    removeRestriction,
    removeMultiRestriction,
  } = useQueryRestriction({
    selectedDate,
    refetchDecree,
  });

  const { curbZones, disabledCurbZonesByBounding: restrictionList } = data;

  const { isSpaceBarPressed, isShiftPressed, isAltPressed } = usePressKeyboard({ appMode });

  const disabledToolTipData = useMemo(() => {
    if (!disabledHover && !curbZoneHover) {
      return null;
    }

    let left = 0;
    let top = 0;
    if (disabledHover) {
      left = disabledHover.x;
      top = disabledHover.y;
    } else if (curbZoneHover) {
      left = curbZoneHover.x;
      top = curbZoneHover.y;
    }

    return {
      disabledHover: disabledHover,
      curbZoneHover: curbZoneHover,
      x: left,
      y: top,
    };
  }, [disabledHover, curbZoneHover]);

  const { onViewStateChange, handleZooming, getCurbZones } = useInteractMap({
    setBounding,
    viewport,
    preZooming,
  });

  useEffect(() => {
    if (!currentCity) {
      return;
    }
    const newViewport = {
      ...viewport,
      height: window.innerHeight,
      width: window.innerWidth,
    };
    getCurbZones(newViewport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport, currentCity]);

  const multiDisabledPartsPolygonData = useMemo(() => {
    return multiDisabledEditionData.features.map((item) => ({
      type: "Feature",
      geometry: item.properties?.geometry,
      properties: item.properties,
    })) as Feature<Polygon, Restriction>[];
  }, [multiDisabledEditionData]);

  useEffect(() => {
    if (isNil(currentCity)) return;
    if (currentCity?.position) {
      currentPosition.current = currentCity?.position;
    }

    resetRestrictionDataOfEditState();
    setSelectedDecree(null);
  }, [currentCity]);

  useEffect(() => {
    if (!currentCity || loading) {
      return;
    }

    const newDisabledStreets: Feature<LineString, Restriction>[] = [];
    const newDisabledParts: Feature<LineString, Restriction>[] = [];
    restrictionList.forEach((disabledCurbZone) => {
      const timeSpans = disabledCurbZone.timeSpans?.map((item) => {
        const startDate = item.startDate ? parseISOString(item.startDate) : null;
        const endDate = item.endDate ? parseISOString(item.endDate) : null;
        return {
          ...item,
          startDate,
          endDate,
        };
      });
      const newDisabledPart = { ...disabledCurbZone, timeSpans };
      if (isTrafficRestriction(disabledCurbZone.type)) {
        /**
         * type is a traffic restriction
         * */
        newDisabledStreets.push(toLineStringFeature(newDisabledPart));
      } else {
        newDisabledParts.push(toLineStringFeature(newDisabledPart));
      }
    });
    setDisabledCurbZoneEditionData({
      type: "FeatureCollection",
      features: newDisabledParts,
    });
    setDisabledStreetEditionData({
      type: "FeatureCollection",
      features: newDisabledStreets,
    });
    setIsResetData(false);
  }, [restrictionList, loading, currentCity, setIsResetData]);

  const cachedDisabledCurbZone = useMemo((): FeatureCollection<LineString, Restriction> => {
    if (isShowDisabledCurbZone) {
      return disabledCurbZoneEditionData;
    }
    return {
      type: "FeatureCollection",
      features: [],
    };
  }, [isShowDisabledCurbZone, disabledCurbZoneEditionData]);

  const disabledCurbZonesPolygonData = useMemo(
    () =>
      cachedDisabledCurbZone.features.map((item) => ({
        type: "Feature",
        geometry: item.properties?.geometry,
        properties: item.properties,
        id: item.id,
      })) as Feature<Polygon, Restriction>[],
    [cachedDisabledCurbZone],
  );

  const cachedDisabledStreet = useMemo((): FeatureCollection<LineString, Restriction> => {
    if (isShowDisabledCurbZone) {
      return disabledStreetEditionData;
    }
    return {
      type: "FeatureCollection",
      features: [],
    };
  }, [isShowDisabledCurbZone, disabledStreetEditionData]);

  const disabledStreetsPolygonData = useMemo(
    () =>
      cachedDisabledStreet.features.map((item) => ({
        type: "Feature",
        geometry: item.properties?.geometry,
        properties: item.properties,
        id: item.id,
      })) as Feature<Polygon, Restriction>[],
    [cachedDisabledStreet],
  );

  useEffect(() => {
    if (!currentCity || loading) {
      return;
    }

    const polygonData = curbZones.map((curbZone) => {
      return {
        id: curbZone.id,
        curbZoneId: curbZone.curbZoneId,
        geometry: curbZone.geometry,
        type: "Feature",
        properties: { ...curbZone },
      } as Feature<Polygon>;
    });
    setCurbZonePolygonData(polygonData);
  }, [curbZones, loading, currentCity]);

  const moveMapToDisabledCurbZones = (listDisabledCurbZones: Restriction[] | undefined) => {
    if (listDisabledCurbZones && listDisabledCurbZones?.length > 0) {
      /**
       * move map to selected disabled curb zones
       * **/
      const [minLng, minLat, maxLng, maxLat] = bbox({
        type: "FeatureCollection",
        features: listDisabledCurbZones.map((item) => ({ type: "Feature", geometry: item.geometry, properties: item })),
      });
      const newViewport = new WebMercatorViewport({
        ...viewport,
        height: wrapMapRef.current?.clientHeight,
        width: wrapMapRef.current?.clientWidth,
      });
      const { longitude, latitude, zoom } = newViewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 2 },
      );
      setViewport({
        ...viewport,
        latitude,
        longitude,
        zoom: Math.min(zoom, 20),
        padding: {
          top: 2,
          right: 2,
          bottom: 2,
          left: 2,
        },
      });
    } else if (view) {
      const currentView = view?.split(",");
      setViewport((prevState) => ({
        ...prevState,
        latitude: Number(currentView[0]),
        longitude: Number(currentView[1]),
        zoom: Number(currentView[2]),
      }));
    }
  };

  useEffect(() => {
    /***
     * Don't reset data when appMode switches between three modes: view mode, editDisabledStreet mode, and editDisabledCurbZone mode.
     * **/
    const shouldResetData = !(
      (appMode === "view" && ["editDisabledStreet", "editDisabledCurbZone"].includes(previousAppMode.current)) ||
      (["editDisabledStreet", "editDisabledCurbZone"].includes(appMode) &&
        (previousAppMode.current === "view" ||
          ["editDisabledStreet", "editDisabledCurbZone"].includes(previousAppMode.current)))
    );

    if (shouldResetData) {
      resetRestrictionDataOfEditState();
      cancelCreateMultiple();
    }
    previousAppMode.current = appMode;
    /**
     * Press Esc key to select multi object
     * **/
    const handleKeyDownEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        cancelCreateMultiple();
      }
    };

    if (!["multiCreateDisabledCurbZone", "multiEdit"].includes(appMode)) {
      window.removeEventListener("keydown", (evt) => handleKeyDownEsc(evt));
      return;
    }
    window.addEventListener("keydown", (evt) => handleKeyDownEsc(evt));
    return () => {
      window.removeEventListener("keydown", (evt) => handleKeyDownEsc(evt));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMode]);

  const updateRestrictionEditionData = (input: Restriction[], isType: ChangeType) => {
    let newFeatures: Feature<LineString, Restriction>[];
    if (disabledCurbZoneMode.includes(appMode)) {
      newFeatures = disabledCurbZoneEditionData.features;
    } else {
      newFeatures = disabledStreetEditionData.features;
    }
    if (isType === "create") {
      newFeatures[newFeatures.length - 1] = toLineStringFeature(input[0]);
    } else if (isType === "multiCreate") {
      for (const item of input) {
        const newRestriction = toLineStringFeature(item);
        newFeatures.push(newRestriction);
      }
    } else if (["update", "bulkUpdate"].includes(isType)) {
      for (const item of input) {
        const newRestriction = toLineStringFeature(item);
        const fRestriction = findObject(newFeatures, "id", newRestriction.id);
        newFeatures[fRestriction] = newRestriction;
      }
    } else {
      for (const item of input) {
        const fRestriction = findObject(newFeatures, "id", item.id);
        newFeatures.splice(fRestriction, 1);
      }
    }

    if (disabledCurbZoneMode.includes(appMode)) {
      setDisabledCurbZoneEditionData({ type: "FeatureCollection", features: newFeatures });
    } else {
      setDisabledStreetEditionData({ type: "FeatureCollection", features: newFeatures });
    }
  };

  const onEditDisableCurbZoneLayer = async ({ updatedData, editType }: any) => {
    if (appMode === AppModeType.MultiEdit) {
      return;
    }
    setDisabledCurbZoneEditionData(updatedData);
    let disabledCurbZoneIndex = currentRestrictionIndexes[0];
    if (appMode === DisabledModeType.EditDisabledCurbZone) {
      /** Re-calculate the polygon when changing the shape of curb-zone */
      const newPolygon = convertLineToPolygon(
        updatedData.features[disabledCurbZoneIndex],
        updatedData.features[disabledCurbZoneIndex].properties.width,
      );
      updatedData.features[disabledCurbZoneIndex].properties.geometry = newPolygon.geometry;
    } else {
      setDisabledCurbZoneEditionData(updatedData);
    }
    if (editType === "finishMovePosition" || editType === "addFeature") {
      if (appMode === DisabledModeType.AddDisabledCurbZone) {
        disabledCurbZoneIndex = updatedData.features.length - 1;
        if (!curbZoneDetail[0]?.id) {
          return;
        }
      }

      const newLineParking = await getPlace<Restriction>(updatedData.features[disabledCurbZoneIndex]);
      const currentDisabledPartDetail =
        appMode === DisabledModeType.AddDisabledCurbZone
          ? updatedData.features[disabledCurbZoneIndex]
          : restrictionDetail[0].properties;
      const newLineDisabledPart = {
        geometry: newLineParking.geometry,
        properties: {
          editLine: newLineParking?.properties?.editLine,
          geoPoint2d: newLineParking?.properties?.geoPoint2d,
          timeSpans: currentDisabledPartDetail?.timeSpans,
          description: currentDisabledPartDetail?.description,
          curbZoneId: currentDisabledPartDetail.curbZoneId ?? curbZoneDetail[0]?.properties?.curbZoneId,
          geometry: newLineParking?.properties?.geometry,
          cityId: currentCity?.id,
          decreeIds: currentDisabledPartDetail.decreeIds,
          id: currentDisabledPartDetail.id,
          width: currentDisabledPartDetail.width,
          calculatedSpaces: newLineParking.properties.calculatedSpaces,
          type: newLineParking?.properties?.type,
          length: Number(newLineParking?.properties?.length),
          streetName: newLineParking.properties?.streetName,
          streetNumberEnd: newLineParking.properties?.streetNumberEnd,
          streetNumberStart: newLineParking.properties?.streetNumberStart,
        },
        type: "Feature",
        id: currentDisabledPartDetail.id,
      } as Feature<LineString, Restriction>;
      setCurrentLineRestriction([newLineDisabledPart]);
      if (appMode === DisabledModeType.AddDisabledCurbZone) {
        setOpenRestrictionModal();
      } else {
        await handleSaveRestriction(newLineDisabledPart.properties, newLineDisabledPart);
      }
    }
  };

  const ableToEditDisabledCurbZone = useMemo(() => {
    if (appMode === DisabledModeType.MultiCreateDisabledCurbZone) {
      return ViewMode;
    }
    if (disabledCurbZoneMode.includes(appMode)) {
      return editableGeoJsonLayerModes[appMode];
    }
    return ViewMode;
  }, [appMode]);

  const handleHoverCurbZone = useCallback(
    (info: any) => {
      if (info.object && appMode === AppModeType.View) {
        setCurbZoneHover({
          x: info.x,
          y: info.y,
          properties: info.object.properties,
        });
      } else {
        setCurbZoneHover(null);
      }
    },
    [appMode],
  );

  const handleHoverDisabled = useCallback(
    (info: any) => {
      if (info.object && appMode === AppModeType.View) {
        setDisabledHover({
          x: info.x,
          y: info.y,
          properties: info.object.properties,
        });
      } else {
        setDisabledHover(null);
      }
    },
    [appMode],
  );

  const curbZonePolygonLayer = new GeoJsonLayer({
    id: "curb-zone-polygon",
    data: curbZonePolygonData,
    stroked: true,
    pickable: !["editDisabledCurbZone", "multiEdit"].includes(appMode),
    getFillColor: (feature: any) => getPolygonColor(feature, "fillColor"),
    getLineColor: (feature: any) => getPolygonColor(feature, "lineColor"),
    lineWidthMaxPixels: 1,
    onHover: handleHoverCurbZone,
    updateTriggers: {
      /**
       * Update appMode values in onHover function
       * **/
      onHover: handleHoverCurbZone,
    },
    opacity: 0.2,
  });

  const disabledLayer = new EditableGeoJsonLayer({
    id: "disabled-parking",
    data: {
      type: cachedDisabledCurbZone.type,
      features: cachedDisabledCurbZone.features as FeatureOf<LineString>[],
    },
    selectedFeatureIndexes: currentRestrictionIndexes,
    mode: ableToEditDisabledCurbZone,
    onEdit: onEditDisableCurbZoneLayer,
    visible: disabledCurbZoneMode.includes(appMode),
  });

  const multiDisabledPartPolygonLayer = new GeoJsonLayer({
    id: "multi-disable-polygon-layer",
    data: multiDisabledPartsPolygonData,
    stroked: true,
    pickable: false,
    getFillColor: [185, 28, 28, 56],
    getLineColor: [185, 28, 28, 100],
    lineWidthMaxPixels: 1,
    visible: appMode === DisabledModeType.MultiCreateDisabledCurbZone,
  });

  const disabledPartPolygonLayer = new GeoJsonLayer({
    id: "disable-polygon-layer",
    data: disabledCurbZonesPolygonData,
    stroked: true,
    pickable: ["view", "multiEdit"].includes(appMode),
    getFillColor: [185, 28, 28, 56],
    getLineColor: function (feature: any) {
      if (
        ["view", "editDisabledCurbZone", "editDisabledStreet"].includes(appMode) &&
        restrictionDetail[0] &&
        feature.id === restrictionDetail[0].id
      ) {
        return [255, 255, 28, 100];
      }
      return [185, 28, 28, 100];
    },
    lineWidthMaxPixels: 3,
    onHover: (info: any) => {
      handleHoverDisabled(info);
    },
    opacity: ["multiCreateDisabledCurbZone", "multiEdit"].includes(appMode) ? 0.1 : 1,
    updateTriggers: {
      getLineColor: restrictionDetail,
    },
    visible: isShowDisabledCurbZone,
  });

  const multipleDisabledLayer = new EditableGeoJsonLayer({
    id: "multi-disabled-parking",
    data: {
      type: multiDisabledEditionData.type,
      features: multiDisabledEditionData.features as FeatureOf<LineString>[],
    },
    selectedFeatureIndexes: currentRestrictionIndexes,
    mode: ViewMode,
    visible: disabledCurbZoneMode.includes(appMode),
  });

  const currentCityLayer = new GeoJsonLayer({
    id: "current-city",
    data: currentCity?.boundaries,
    filled: false,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 4,
  });

  const selectMultipleDisabledCurbZone = ({ pickingInfos }: any) => {
    if (pickingInfos.length > 0) {
      const restrictionData = filterLineStringFromPoint(pickingInfos);
      const newDisabledParts = [...restrictionDetail, ...restrictionData];
      const uniqArray = uniqBy(newDisabledParts, "id");
      setRestrictionDetail(uniqArray);
    }
  };

  const selectMultipleCurbZone = (pickingInfos: any) => {
    if (pickingInfos.pickingInfos.length > 0) {
      mergedDisabledCurbZones(pickingInfos.pickingInfos);
    }
  };

  const mergedDisabledCurbZones = (pickingInfos: any[]) => {
    /**
     * Delete duplicate data when press shift
     * **/
    const selectedCurbZones = convertCurbZoneToDisabledCurbZone(pickingInfos);

    const newDisabledParts = [...multiDisabledEditionData.features, ...selectedCurbZones];
    let uniqArray = uniqBy(newDisabledParts, "id");

    if (isShiftPressed) {
      const fCurbZone = findObject(multiDisabledEditionData.features, "id", pickingInfos[0]?.object?.id);
      if (fCurbZone > -1) {
        uniqArray = uniqArray.filter((item) => item.id !== pickingInfos[0]?.object?.id);
      }
    }
    setMultiDisabledEditionData({
      type: "FeatureCollection",
      features: uniqArray,
    });
    setRestrictionDetail(uniqArray);
  };

  const convertCurbZoneToDisabledCurbZone = (infos: any) => {
    if (infos && infos.length > 0) {
      return infos.reduce((filteredArray: any[], info: any) => {
        /**
         * Only create new disabled curb zone if the selected curb zone hasn't any disabled curb zone
         * **/
        if (info && info?.object?.id) {
          return [
            ...filteredArray,
            {
              geometry: info.object?.properties?.editLine.geometry,
              properties: {
                editLine: info.object?.properties?.editLine,
                geoPoint2d: info.object?.properties?.geoPoint2d,
                calculatedSpaces: info.object?.properties?.calculatedSpaces,
                length: info.object?.properties?.length,
                startDate: null,
                endDate: null,
                description: null,
                curbZoneId: info.object.curbZoneId,
                geometry: info.object?.properties?.geometry,
                cityId: currentCity?.id,
                decreeIds: [],
                width: info.object.properties.width,
                streetName: info.object.properties.streetName,
                streetNumberStart: info.object.properties.streetNumberStart,
                streetNumberEnd: info.object.properties.streetNumberEnd,
              },
              id: info.object.id,
              type: "Feature",
              curbZoneId: info.object.curbZoneId,
            },
          ];
        }
        return filteredArray;
      }, []);
    }
    return [];
  };

  const selectionCurbZoneType = useMemo(() => {
    if (isShiftPressed || appMode !== DisabledModeType.MultiCreateDisabledCurbZone) {
      return "none";
    }
    return isAltPressed ? "rectangle" : "polygon";
  }, [isAltPressed, isShiftPressed, appMode]);

  const selectionDisabledCurbZoneType = useMemo(() => {
    if (isShiftPressed || appMode !== AppModeType.MultiEdit) {
      return "none";
    }
    return isAltPressed ? "rectangle" : "polygon";
  }, [isAltPressed, isShiftPressed, appMode]);

  const selectionMultipleCurbZoneLayer = new SelectionLayer({
    id: "selection-rectangle",
    selectionType: selectionCurbZoneType,
    layerIds: ["curb-zone-polygon"],
    onSelect: selectMultipleCurbZone,
    lineWidthMinPixels: 1,
    visible: appMode === DisabledModeType.MultiCreateDisabledCurbZone && !isSpaceBarPressed && !isShiftPressed,
  });

  const selectionMultipleDisabledCurbZoneLayer = new SelectionLayer({
    id: "selection-rectangle-disabled-curb-zone",
    selectionType: selectionDisabledCurbZoneType,
    layerIds: ["disabled-parking"],
    onSelect: selectMultipleDisabledCurbZone,
    lineWidthMinPixels: 1,
    visible: appMode === AppModeType.MultiEdit && !isSpaceBarPressed && !isShiftPressed,
  });

  const showRestrictionDetail = useMemo(() => {
    if (isTrafficRestriction(restrictionDetail[0]?.properties?.type)) {
      /**
       * type is a traffic restriction
       * **/
      return appMode === DisabledModeType.EditDisabledStreet && restrictionDetail && restrictionDetail.length === 1;
    } else {
      return appMode === DisabledModeType.EditDisabledCurbZone && restrictionDetail && restrictionDetail.length === 1;
    }
  }, [appMode, restrictionDetail]);

  const restrictionData = useMemo(() => {
    if (
      !restrictionDetail[0] &&
      (["multiCreateDisabledCurbZone", "multiEdit"].includes(appMode) || !currentLineRestriction[0])
    ) {
      return undefined;
    }

    return currentLineRestriction[0]?.properties ?? restrictionDetail[0].properties ?? undefined;
  }, [currentLineRestriction, restrictionDetail, appMode]);

  const showCurbZoneMultiDetail = useMemo(
    () => appMode === DisabledModeType.MultiCreateDisabledCurbZone && restrictionDetail && restrictionDetail.length > 0,
    [appMode, restrictionDetail],
  );

  const showDisabledCurbZoneMultiDetail = useMemo(
    () => appMode === AppModeType.MultiEdit && restrictionDetail && restrictionDetail.length > 0,
    [appMode, restrictionDetail],
  );

  const resetRestrictionDataOfEditState = () => {
    setCurrentLineRestriction([]);
    setCurbZoneDetail([]);
    setRestrictionDetail([]);
  };

  const getDisabledCurbZoneLayers = () => {
    /**
     * Move curb zone layer up to disabled part layer if app mode is not multi edit
     * **/
    if (appMode === DisabledModeType.MultiCreateDisabledCurbZone) {
      return [
        currentCityLayer,
        multipleDisabledLayer,
        disabledPartPolygonLayer,
        multiDisabledPartPolygonLayer,
        disabledLayer,
        curbZonePolygonLayer,
        selectionMultipleCurbZoneLayer,
        selectionMultipleDisabledCurbZoneLayer,
      ];
    }
    if (appMode === AppModeType.MultiEdit) {
      return [
        currentCityLayer,
        multipleDisabledLayer,
        curbZonePolygonLayer,
        disabledLayer,
        disabledPartPolygonLayer,
        multiDisabledPartPolygonLayer,
        selectionMultipleCurbZoneLayer,
        selectionMultipleDisabledCurbZoneLayer,
      ];
    }
    if (appMode === DisabledModeType.AddDisabledCurbZone) {
      return [
        currentCityLayer,
        multipleDisabledLayer,
        disabledPartPolygonLayer,
        multiDisabledPartPolygonLayer,
        disabledLayer,
        curbZonePolygonLayer,
        selectionMultipleCurbZoneLayer,
        selectionMultipleDisabledCurbZoneLayer,
      ];
    }
    return [
      currentCityLayer,
      multipleDisabledLayer,
      curbZonePolygonLayer,
      disabledPartPolygonLayer,
      multiDisabledPartPolygonLayer,
      disabledLayer,
      selectionMultipleCurbZoneLayer,
      selectionMultipleDisabledCurbZoneLayer,
    ];
  };

  const getCursor = () => {
    if (appMode === DisabledModeType.EditDisabledCurbZone || appMode === DisabledModeType.AddDisabledCurbZone) {
      if (currentLineParkingList.length === 0) {
        return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
      }
      return disabledLayer.getCursor.bind(disabledLayer);
    }
    return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
  };
  const selectAddress = (viewport: ViewportPlaceType | null) => {
    setMarkerPosition(viewport);
    if (viewport) {
      setViewport((prevState) => ({ ...prevState, ...viewport, zoom: 16 }));
    }
  };

  const handleSelectDisabledCurbZone = (info: any) => {
    if (appMode === AppModeType.View || !info.object) {
      return;
    }
    if (appMode === DisabledModeType.MultiCreateDisabledCurbZone && isShiftPressed) {
      if (info.object) {
        mergedDisabledCurbZones([info]);
      } else {
        return;
      }
    } else if (appMode === AppModeType.MultiEdit && isShiftPressed) {
      const fDisabledCurbZone = findObject(restrictionDetail, "id", info.object.id);
      if (fDisabledCurbZone > -1) {
        const newDisabledCurbZones = restrictionDetail.filter(
          (item) => item.id !== restrictionDetail[fDisabledCurbZone].id,
        );
        setRestrictionDetail(newDisabledCurbZones);
      } else {
        setRestrictionDetail([...restrictionDetail, info.object]);
        return;
      }
    } else if (appMode === DisabledModeType.EditDisabledCurbZone) {
      if (!info.object?.properties?.curbZoneId) {
        return;
      }
      setRestrictionDetail([info.object]);
      setCurrentLineRestriction([info.object]);
    } else if (curbZoneDetail.length === 0) {
      const fCurbZone = findObject(curbZonePolygonData, "id", info.object?.id);
      setCurbZoneDetail([curbZonePolygonData[fCurbZone]]);
      preCurbZoneEdited.current = curbZonePolygonData[fCurbZone];
      setCurrentLineParkingList([preCurbZoneEdited.current]);
    }
  };

  const handleDragEnd = (event: any) => {
    if (["multiCreateDisabledCurbZone", "multiEdit"].includes(appMode) && !isSpaceBarPressed) return;
    const { viewport } = event;
    if (viewport) {
      const newPosition = [viewport?.longitude, viewport?.latitude];
      currentPosition.current = [...newPosition];
      setViewport((prevState) => ({
        ...prevState,
        pitch: viewport.pitch,
        bearing: viewport.bearing,
        longitude: viewport.longitude,
        latitude: viewport.latitude,
        zoom: viewport.zoom,
      }));
    }
  };

  const handleRemoveRestriction = async (input: RemoveRestrictionParam) => {
    const res = await removeRestriction({ variables: { id: input.id } });
    if (res.data?.deleteDisabledCurbZone) {
      updateRestrictionEditionData([res.data.deleteDisabledCurbZone as Restriction], "delete");
    }
    resetRestrictionDataOfEditState();
  };

  const handleRemoveMultipleDisabledCurbZones = async (input: RemoveRestrictionParam[]) => {
    const listIds = input.map((item) => item.id);
    const res = await removeMultiRestriction({ variables: { ids: listIds } });
    if (res.data?.bulkDeleteRestrictions) {
      updateRestrictionEditionData(input as Restriction[], "bulkDelete");
    }
    resetRestrictionDataOfEditState();
  };

  const cancelEditDisabledPart = (isEdit = false) => {
    if (!isEdit) {
      const newParkingEditions = [...disabledCurbZoneEditionData.features];
      newParkingEditions.pop();
      setDisabledCurbZoneEditionData({ type: "FeatureCollection", features: newParkingEditions });
    }
    setRestrictionDetail([]);
    setCurrentLineParkingList([]);
    if (openRestrictionModal) setOpenRestrictionModal();
    setCurrentLineRestriction([]);
  };

  const handleSelectDecree = (input: Decree) => {
    if (input.id === selectedDecree?.id) {
      setSelectedDecree(null);
    } else {
      moveMapToDisabledCurbZones(input.disabledCurbZones);
      setSelectedDecree({ ...input });
    }
    resetRestrictionDataOfEditState();
  };

  const cancelCreateMultiple = () => {
    if (openRestrictionModal) {
      setOpenRestrictionModal();
    }
    setMultiDisabledEditionData({
      type: "FeatureCollection",
      features: [],
    });
    setCurrentLineParkingList([]);
    setRestrictionDetail([]);
  };

  const cancelEditMultiple = () => {
    if (openDisabledCurbZoneMultipleModal) {
      setOpenDisabledCurbZoneMultipleModal(false);
    }
    setMultiDisabledEditionData({
      type: "FeatureCollection",
      features: [],
    });
    setCurrentLineParkingList([]);
    setRestrictionDetail([]);
  };

  const handleSaveMultiDisabledCurbZone = async (newDisabledParam: Restriction[]) => {
    if (!multiDisabledPartsPolygonData.length) {
      return;
    }
    const newRestrictions: Restriction[] = [];

    for (const item of newDisabledParam) {
      delete item.id;
      const res = await createRestriction({ variables: { createDisabledCurbZoneInput: item } });
      if (res.data?.createDisabledCurbZone) {
        newRestrictions.push(res.data.createDisabledCurbZone);
      }
    }

    if (newRestrictions.length > 0) {
      updateRestrictionEditionData(newRestrictions, "multiCreate");
    }

    resetRestrictionDataOfEditState();
  };
  const handleUpdateMultiDisabledCurbZone = async (newDisabledParam: Restriction[]) => {
    const res = await updateMultiRestriction({ variables: { updateDisabledCurbZoneInput: newDisabledParam } });
    if (res.data?.bulkUpdateRestrictions) {
      const restrictionsWithDecree = res.data.bulkUpdateRestrictions.map((restriction) => {
        const decrees = restriction?.decreeIds?.reduce((preValue: Pick<Decree, "id" | "name">[], id) => {
          const fDecree = decreeList.find((item) => item.id === id);
          return fDecree ? [...preValue, { id: fDecree.id, name: fDecree.name }] : preValue;
        }, []);
        return {
          ...restriction,
          decrees,
        };
      });
      updateRestrictionEditionData(restrictionsWithDecree, "bulkUpdate");
    }
    resetRestrictionDataOfEditState();
  };

  const changeMultipleDisabledCurbZone = async (disableProperties: Restriction) => {
    if (openDisabledCurbZoneMultipleModal) {
      setOpenDisabledCurbZoneMultipleModal(false);
    }
    const newDisabledCurbZones: Restriction[] = [];

    const timeSpans = disableProperties?.timeSpans?.map((item) => {
      /**
       * convert date object to UTC time
       * */
      const startDate = item.startDate ? convertDateToUTC(item.startDate) : null;
      const endDate = item.endDate ? convertDateToUTC(item.endDate) : null;
      return {
        ...item,
        startDate,
        endDate,
      };
    });

    for (const item of restrictionDetail) {
      const width = disableProperties.width ?? 2;
      /** Re-calculate the polygon when changing the shape of restrictions
       * Don't change the: streetNumberStart, streetNumberEnd, calculatedSpaces
       * */
      const newPolygon = convertLineToPolygon({ ...item.properties.editLine, properties: item.properties }, width);
      const newDisabledParam: Restriction = {
        geometry: newPolygon.geometry,
        timeSpans,
        description: disableProperties.description,
        decreeIds: disableProperties.decreeIds,
        geoPoint2d: item.properties.geoPoint2d,
        editLine: item.properties.editLine,
        curbZoneId: item.properties.curbZoneId,
        cityId: currentCity?.id ?? "",
        type: disableProperties.type,
        id: item?.id as string,
        streetName: disableProperties?.streetName,
        streetNumberStart: item.properties?.streetNumberStart,
        streetNumberEnd: item.properties?.streetNumberEnd,
        length: item.properties?.length,
        width,
        calculatedSpaces: item.properties.calculatedSpaces,
      };
      if (width !== item.properties.width) {
        /**
         * If changed width, re-calculate the spaces
         **/
        const curbZoneFeature = {
          type: "Feature",
          geometry: newDisabledParam.editLine.geometry,
          properties: { ...newDisabledParam, width },
        } as Feature<LineString, Restriction>;

        const { calculatedSpaces } = calculateArea(curbZoneFeature);
        newDisabledParam.calculatedSpaces = calculatedSpaces;
      }
      newDisabledCurbZones.push(newDisabledParam);
    }
    if (appMode === DisabledModeType.MultiCreateDisabledCurbZone) {
      await handleSaveMultiDisabledCurbZone(newDisabledCurbZones);
    } else {
      await handleUpdateMultiDisabledCurbZone(newDisabledCurbZones);
    }
  };

  const clickDescriptionRestriction = (restriction: any) => {
    const fRestriction = restriction.id === restrictionDetail[0]?.id;
    if (fRestriction) {
      resetRestrictionDataOfEditState();
    } else {
      setRestrictionDetail([restriction]);
      setCurrentLineRestriction([restriction]);
      moveMapToDisabledCurbZones([restriction.properties]);
    }
  };

  const onEditDisableStreetLayer = async ({ updatedData, editType }: any) => {
    setDisabledStreetEditionData(updatedData);
    let disabledCurbZoneIndex = currentRestrictionIndexes[0];
    if (appMode === DisabledModeType.EditDisabledStreet) {
      /** Re-calculate the polygon when changing the shape of curb-zone */
      const newPolygon = convertLineToPolygon(
        updatedData.features[disabledCurbZoneIndex],
        updatedData.features[disabledCurbZoneIndex].properties.width,
      );
      updatedData.features[disabledCurbZoneIndex].properties.geometry = newPolygon.geometry;
      setDisabledStreetEditionData(updatedData);
    } else {
      setDisabledStreetEditionData(updatedData);
    }
    if (editType === "finishMovePosition" || editType === "addFeature") {
      if (appMode === DisabledModeType.AddDisabledStreet) {
        disabledCurbZoneIndex = updatedData.features.length - 1;
      }

      const newLineParking = await getPlace<Restriction>(updatedData.features[disabledCurbZoneIndex]);
      const currentRestrictionDetail =
        appMode === DisabledModeType.AddDisabledStreet
          ? updatedData.features[disabledCurbZoneIndex]
          : restrictionDetail[0].properties;

      const newLineDisabledStreet = {
        geometry: newLineParking.geometry,
        properties: {
          editLine: newLineParking?.properties?.editLine,
          geoPoint2d: newLineParking?.properties?.geoPoint2d,
          timeSpans: currentRestrictionDetail?.timeSpans,
          description: currentRestrictionDetail?.description,
          geometry: newLineParking?.properties?.geometry,
          cityId: currentCity?.id,
          decreeIds: currentRestrictionDetail.decreeIds,
          id: currentRestrictionDetail.id,
          width: currentRestrictionDetail.width,
          calculatedSpaces: newLineParking.properties.calculatedSpaces,
          type: newLineParking?.properties?.type,
          length: Number(newLineParking.properties.length),
          streetName: newLineParking.properties?.streetName,
          streetNumberEnd: newLineParking.properties?.streetNumberEnd,
          streetNumberStart: newLineParking.properties?.streetNumberStart,
        },
        type: "Feature",
        id: currentRestrictionDetail.id,
      } as Feature<LineString, Restriction>;
      setCurrentLineRestriction([newLineDisabledStreet]);
      if (appMode === DisabledModeType.AddDisabledStreet) {
        setOpenRestrictionModal();
      } else {
        await handleSaveRestriction(newLineDisabledStreet.properties, newLineDisabledStreet);
      }
    }
  };

  const disabledEditionLayer = new EditableGeoJsonLayer({
    id: "disabled-street-edit",
    data: {
      type: cachedDisabledStreet.type,
      features: cachedDisabledStreet.features as FeatureOf<LineString>[],
    },
    selectedFeatureIndexes: currentRestrictionIndexes,
    mode: editableGeoJsonLayerMode,
    onEdit: onEditDisableStreetLayer,
    visible: disabledStreetMode.includes(appMode),
  });

  const disabledStreetPolygonLayer = new GeoJsonLayer({
    id: "disable-street-polygon",
    data: disabledStreetsPolygonData,
    stroked: true,
    pickable: appMode === AppModeType.View,
    getFillColor: [185, 28, 28, 56],
    getLineColor: function (feature: any) {
      if (
        ["view", "editDisabledCurbZone", "editDisabledStreet"].includes(appMode) &&
        restrictionDetail[0] &&
        feature.id === restrictionDetail[0].id
      ) {
        return [255, 255, 28, 100];
      }
      return [185, 28, 28, 100];
    },
    lineWidthMaxPixels: 3,
    onHover: (info: any) => {
      handleHoverDisabled(info);
    },
  });

  const disabledStreetLayers = [disabledStreetPolygonLayer, disabledEditionLayer];

  const handleSelectDisabledStreet = (info: any) => {
    if (["view", "addDisabledStreet"].includes(appMode)) {
      return;
    }
    if (!info.object || !isTrafficRestriction(info.object.properties.type)) {
      return;
    }

    setRestrictionDetail([info.object]);
    setCurrentLineRestriction([info.object]);
  };

  const handleSaveRestrictionError = () => {
    if (appMode === "addDisabledCurbZone") {
      setDisabledCurbZoneEditionData({
        type: "FeatureCollection",
        features: [...disabledCurbZoneEditionData.features.toSpliced(-1, 1)],
      });
    } else {
      setDisabledStreetEditionData({
        type: "FeatureCollection",
        features: [...disabledStreetEditionData.features.toSpliced(-1, 1)],
      });
    }
  };
  const handleSaveRestriction = async (
    restrictionProperties: Restriction,
    lineRestriction?: Feature<LineString, Restriction>,
  ) => {
    if (!currentLineRestriction.length || !currentLineRestriction[0].properties) {
      return;
    }
    const width = restrictionProperties?.width ?? 2;
    const newRestriction = convertLineToPolygon(lineRestriction ?? currentLineRestriction[0], width);

    const timeSpans = restrictionProperties?.timeSpans?.map((item) => {
      /**
       * convert date object to UTC time
       * */
      const startDate = item.startDate ? convertDateToUTC(item.startDate) : null;
      const endDate = item.endDate ? convertDateToUTC(item.endDate) : null;
      return {
        ...item,
        startDate,
        endDate,
      };
    });

    const newDisabledParam: Restriction = {
      geometry: newRestriction.geometry,
      timeSpans,
      description: restrictionProperties.description,
      decreeIds: restrictionProperties.decreeIds,
      geoPoint2d: newRestriction.properties.geoPoint2d,
      editLine: newRestriction.properties.editLine,
      curbZoneId: restrictionProperties.curbZoneId,
      cityId: currentCity?.id ?? "",
      type: restrictionProperties.type,
      length: restrictionProperties.length,
      width,
      calculatedSpaces: restrictionProperties.calculatedSpaces,
      streetName: restrictionProperties.streetName,
      streetNumberStart: restrictionProperties.streetNumberStart,
      streetNumberEnd: restrictionProperties.streetNumberEnd,
    };
    if (restrictionProperties.id) {
      const res = await updateRestriction({
        variables: {
          updateDisabledCurbZoneInput: { ...newDisabledParam, id: restrictionProperties.id },
        },
      });
      if (res.data?.updateDisabledCurbZone) {
        updateRestrictionEditionData([res.data.updateDisabledCurbZone], "update");
      }
    } else {
      const res = await createRestriction({ variables: { createDisabledCurbZoneInput: newDisabledParam } });
      if (res.data?.createDisabledCurbZone) {
        if (!isShowDisabledCurbZone) {
          /**
           * Hidden the new disabled curb-zone
           * **/
          setDisabledCurbZoneEditionData({
            type: "FeatureCollection",
            features: [],
          });
        } else {
          updateRestrictionEditionData([res.data.createDisabledCurbZone], "create");
        }
      } else {
        handleSaveRestrictionError();
      }
    }
    if (openRestrictionModal) {
      setOpenRestrictionModal();
      resetRestrictionDataOfEditState();
    }
  };

  const cancelEditDisabledStreet = (isEdit = false) => {
    if (!isEdit) {
      const newParkingEditions = [...disabledStreetEditionData.features];
      newParkingEditions.pop();
      setDisabledStreetEditionData({ type: "FeatureCollection", features: newParkingEditions });
    }
    setRestrictionDetail([]);
    if (openRestrictionModal) {
      setOpenRestrictionModal();
    }
    setCurrentLineRestriction([]);
  };

  const mapLayers = [...getDisabledCurbZoneLayers(), disabledStreetLayers];

  return {
    markerPosition,
    setMarkerPosition,
    selectAddress,
    viewport,
    mapType,
    setMapType,
    modeView,
    getCursor,
    getDisabledCurbZoneLayers,
    handleDragEnd,
    handleSelectDisabledCurbZone,
    handleZooming,
    mapRef,
    onViewStateChange,
    setModeView,
    appMode,
    currentCity,
    currentLineRestriction,
    handleRemoveRestriction,
    setOpenRestrictionModal,
    setOpenDisabledCurbZoneMultipleModal,
    openRestrictionModal,
    openDisabledCurbZoneMultipleModal,
    cancelEditDisabledPart,
    handleSaveRestriction,
    restrictionData,
    preZooming,
    selectedDate,
    selectedDecree,
    handleSelectDecree,
    wrapMapRef,
    disabledToolTipData,
    isSpaceBarPressed,
    showCurbZoneMultiDetail,
    showDisabledCurbZoneMultiDetail,
    cancelCreateMultiple,
    cancelEditMultiple,
    handleRemoveMultipleDisabledCurbZones,
    clickDescriptionRestriction,
    resetRestrictionDataOfEditState,
    moveMapToDisabledCurbZones,
    cancelEditDisabledStreet,
    handleSelectDisabledStreet,
    showRestrictionDetail,
    disabledStreetLayers,
    restrictionDetail,
    mapLayers,
    changeMultipleDisabledCurbZone,
    isShowDisabledCurbZone,
    setIsShowDisabledCurbZone,
  };
};
