import { useMutation, useQuery } from "@apollo/client";
import {
  QUERY_CREATE_RESTRICTION,
  QUERY_REMOVE_RESTRICTION,
  QUERY_UPDATE_RESTRICTION,
  QUERY_UPDATE_RESTRICTIONS,
  QUERY_REMOVE_RESTRICTIONS,
  QUERY_RESTRICTION_AND_CURB_ZONES,
} from "services/web/api-restriction.const";
import { DisabledCurbZoneParams } from "../disabled-part.type";
import {
  CreateRestrictionResponse,
  DeleteMultiRestrictionResponse,
  DeleteRestrictionResponse,
  RestrictionAndCurbZoneResponse,
  UpdateMultiRestrictionResponse,
  UpdateRestrictionResponse,
} from "services/web/api-restriction.type";
import { useEffect } from "react";
import { useMemo } from "react";
import { useSearchFilterCurbZone } from "@/hooks/use-search-filter-curb-zone";
import { useContext } from "react";
import { CityContext } from "@/contexts/city-context-provider";
import { useState } from "react";
import { hideGlobalLoading } from "@/hooks/use-global-loading";
import { useDebounce } from "@/hooks/lib-ui";
import { convertDateToUTC } from "@/helper/date-time";
import { showGlobalLoading } from "@/hooks/use-global-loading";

export const useQueryRestriction = ({ selectedDate, refetchDecree }: DisabledCurbZoneParams) => {
  const { currentCity } = useContext(CityContext);
  const [isResetData, setIsResetData] = useState<boolean>(false);
  const [bounding, setBounding] = useState<number[][]>();
  const nearBy = useDebounce(bounding, 500);
  const { filter } = useSearchFilterCurbZone();

  const variables = useMemo(() => {
    /**
     * Format datetime by city's iana
     * */
    const startDate = selectedDate?.startDate ? convertDateToUTC(selectedDate.startDate) : undefined;
    const endDate = selectedDate?.endDate ? convertDateToUTC(selectedDate.endDate) : undefined;

    return {
      filterInputCurbZone: {
        cityId: currentCity?.id ?? "",
        boundingbox: nearBy,
        policies: filter.selectedPolicies.length > 0 ? filter.selectedPolicies : undefined,
      },
      filterInputRestriction: {
        cityId: currentCity?.id ?? "",
        boundingbox: nearBy,
        policies: filter.selectedPolicies.length > 0 ? filter.selectedPolicies : undefined,
        startDate,
        endDate,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearBy, filter.selectedPolicies, selectedDate]);

  const { data = { disabledCurbZonesByBounding: [], curbZones: [] }, loading } =
    useQuery<RestrictionAndCurbZoneResponse>(QUERY_RESTRICTION_AND_CURB_ZONES, {
      variables,
      skip: !currentCity?.id || !nearBy,
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (loading) {
      showGlobalLoading();
    } else {
      hideGlobalLoading();
    }
  }, [loading]);

  const resetState = () => {
    setIsResetData(true);
  };

  const [createRestriction] = useMutation<CreateRestrictionResponse>(QUERY_CREATE_RESTRICTION, {
    onError: resetState,
    onCompleted: () => {
      refetchDecree();
    },
  });
  const [updateRestriction] = useMutation<UpdateRestrictionResponse>(QUERY_UPDATE_RESTRICTION, {
    onError: resetState,
    onCompleted: () => {
      refetchDecree();
    },
  });

  const [removeRestriction] = useMutation<DeleteRestrictionResponse>(QUERY_REMOVE_RESTRICTION, {
    onCompleted: () => {
      refetchDecree();
    },
  });

  const [removeMultiRestriction] = useMutation<DeleteMultiRestrictionResponse>(QUERY_REMOVE_RESTRICTIONS, {
    onCompleted: () => {
      refetchDecree();
    },
    onError: resetState,
  });

  const [updateMultiRestriction] = useMutation<UpdateMultiRestrictionResponse>(QUERY_UPDATE_RESTRICTIONS, {
    onCompleted: () => {
      refetchDecree();
    },
    onError: resetState,
  });

  return {
    bounding,
    setBounding,
    data,
    loading,
    createRestriction,
    updateRestriction,
    updateMultiRestriction,
    removeMultiRestriction,
    isResetData,
    setIsResetData,
    removeRestriction,
    nearBy,
  };
};
