import { SetStateAction, Dispatch } from "react";
import { DataTable, TableBody, TableCell, TableRow } from "components/data-table";
import { Button } from "@/components/ui/button";
import { Feature, Polygon } from "geojson";
import { CloseIcon } from "components/icons";

type SelectedMultipleCurbZoneDetailProps = {
  curbZones: Feature<Polygon>[];
  resetState: () => void;
  openCurbZoneMultiModal: Dispatch<SetStateAction<boolean>>;
};

export const SelectedMultipleCurbZoneDetail = ({
  curbZones,
  resetState,
  openCurbZoneMultiModal,
}: SelectedMultipleCurbZoneDetailProps) => {
  return (
    <>
      <div className="transition-opacity absolute h-auto top-36 bg-primary p-3 right-6 mt-2 mb-3 rounded-md shadow-lg z-10 w-64">
        <div className="flex justify-end h-6 cursor-pointer">
          <CloseIcon className="text-white w-4 h-4" onClick={resetState} />
        </div>
        <DataTable className="p-0 shadow-none rounded-none">
          <TableBody>
            <TableRow className="hover:none">
              <TableCell className="text-table-data text-secondary pb-4">Currently selecting</TableCell>
              <TableCell className="text-table-data text-secondary pb-4 flex justify-end">
                {curbZones?.length} Curb Zones
              </TableCell>
            </TableRow>
          </TableBody>
        </DataTable>
        <div className="flex justify-between">
          <Button onClick={() => openCurbZoneMultiModal(true)} variant="secondary">
            Create
          </Button>
        </div>
      </div>
    </>
  );
};
